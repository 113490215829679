// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CHECK_SERVER = 'CHECK_SERVER';

// Ethereum
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_NETWORK = 'SET_NETWORK';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_TOKEN_BALANCE = 'SET_TOKEN_BALANCE';
export const SET_TOKEN_BALANCES = 'SET_TOKEN_BALANCES';
export const SET_WALLET = 'SET_WALLET';
export const SET_WEB3 = 'SET_WEB3';
export const SET_BLOCKEXPLORER_URL = 'SET_BLOCKEXPLORER_URL';
export const SET_CURRENT_BLOCK = 'SET_CURRENT_BLOCK';
export const SET_TRANSACTION_LOADING = 'SET_TRANSACTION_LOADING';
export const SET_ALLOWANCE = 'SET_ALLOWANCE';

// Summary
export const SUMMARY_SUCCESS = 'SUMMARY_SUCCESS';
export const SET_ACCRUED_REWARDS = 'SET_ACCRUED_REWARDS';
export const SET_STAKABLE_ASSETS = 'SET_STAKABLE_ASSETS';
export const SET_SLICE_STATS = 'SET_SLICE_STATS';
export const SET_TVL = 'SET_TVL';

// Data
export const SET_EXCHANGE_RATES = 'SET_EXCHANGE_RATES';
export const LOANS_IS_LOADING = 'LOANS_IS_LOADING';
export const LOANS_SUCCESS = 'LOANS_SUCCESS';
export const LOANS_COUNT = 'LOANS_COUNT';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const CHANGE_OWN_ALL_FILTER = 'CHANGE_OWN_ALL_FILTER';
export const CHANGE_SORTING = 'CHANGE_SORTING';
export const PAGINATION_SKIP = 'PAGINATION_SKIP';
export const PAGINATION_CURRENT = 'PAGINATION_CURRENT';
export const SET_TX_MODAL_OPEN = 'SET_TX_MODAL_OPEN';
export const SET_TX_MODAL_TYPE = 'SET_TX_MODAL_TYPE';
export const SET_TX_MODAL_DATA = 'SET_TX_MODAL_DATA';
export const SET_TX_MODAL = 'SET_TX_MODAL';
export const SET_TX_MODAL_LOADING = 'SET_TX_MODAL_LOADING';
export const SET_TX_ONGOING_DATA = 'SET_TX_ONGOING_DATA';
export const SET_TX_LINK = 'SET_TX_LINK';
export const SET_MIGRATE_STEP = 'SET_MIGRATE_STEP';
export const SET_MIGRATE_LOADING = 'SET_MIGRATE_LOADING';
export const SET_MIGRATED = 'SET_MIGRATED';

// Tranches
export const TRANCHES_IS_LOADING = 'TRANCHES_IS_LOADING';
export const TRANCHES_SUCCESS = 'TRANCHES_SUCCESS';
export const TRANCHES_COUNT = 'TRANCHES_COUNT';
export const TRANCHE_CARD_TOGGLE = 'TRANCHE_CARD_TOGGLE';
export const TRANCHE_MARKETS = 'TRANCHE_MARKETS';
export const SELECT_MARKET = 'SELECT_MARKET';

// Staking
export const STAKING_IS_LOADING = 'STAKING_IS_LOADING';
export const LPLIST_SUCCESS = 'LPLIST_SUCCESS';
export const STAKING_SUCCESS = 'STAKING_SUCCESS';
export const STAKING_COUNT = 'STAKING_COUNT';

// Earn
export const OWN_ALL_TOGGLE = 'OWN_ALL_TOGGLE';

// Path
export const CHANGE_PATH = 'CHANGE_PATH';

//theme
export const CHANGE_THEME = 'CHANGE_THEME';

//Notify
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const SIR_REWARDS = 'SIR_REWARDS'

// USER STAKING LIST
export const USER_STAKING_LIST_SUCCESS = 'USER_STAKING_LIST_SUCCESS';
export const USER_STAKING_LIST_IS_LOADING = 'USER_STAKING_LIST_IS_LOADING';

// SLICE GOVERNANCE
export const PROPOSAL_LIST_SUCCESS = 'PROPOSAL_LIST_SUCCESS';
export const PROPOSAL_LIST_COUNT = 'PROPOSAL_LIST_COUNT';
export const SET_DELEGATEE = 'SET_DELEGATEE';
export const SET_VOTES = 'SET_VOTES';
