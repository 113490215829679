import styled from 'styled-components';

const TableWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 24px auto;
  ${({ mobile }) => mobile && `
    display: none;
    @media (max-width: 992px){
      display: block;
    }
  `}
  ${({ desktop }) => desktop && `
    display: none;
    @media (min-width: 992px){
      display: block;
    }
  `}
`;
const TableContentMobile = styled.div`
  background: ${props => props.TableColor};
  border-radius: 16px;
  border: 1px solid ${props => props.border};
`;


const TableContentCard = styled.div`
  display: flex;
  align-items: center;
  min-height: 110px;
  justify-content: space-between;
  cursor: pointer;
  ${({ pointer }) => !pointer && `
    cursor: default; 
  `}
  ${({ border, color }) => border && `
    // border-bottom: 2px solid ${color} !important;
  `}
`;

const CallToActionTradeWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const CallToActionTradeBtns = styled.div`
  margin-bottom: 41px;
`;

const CallToActionTradeBtn = styled.button`
  width: 148px;
  height: 27px;
  cursor: pointer;
  margin: 2px 12px;
  background: ${props => props.type === "loans" ? "#9B6BFF" : "#2ECC71"}; 
  box-shadow: 0px 1px 1px #E5E5E5;
  border-radius: 2px;
  top: 716px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  border: none;
  outline: none;
  color: #FFFFFF;
  span{
    font-weight: bold;
  }
`;

const CallToActionTradetext = styled.div`
  margin: -12px auto 29px auto;
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #838186;
  }
`;

const LoadingContent = styled.div`
  animation: gradient-slide 2.2s ease infinite;
  background: 0 0/300% 300% -webkit-gradient(linear, left top, right top, color-stop(40%, ${props => props.colorOne}), color-stop(50%, ${props => props.colorTwo}), color-stop(60%, ${props => props.colorOne}));
  background: 0 0/300% 300% linear-gradient(90deg, ${props => props.colorOne} 40%, ${props => props.colorTwo} 50%, ${props => props.colorOne} 60%);
`;

const TableContentCardWrapper = styled.div`
  min-height: 110px;
  position: relative;
  // background: ${props => props.color};
  // border: 1px solid ${props => props.borderColor};
  box-sizing: border-box;
  box-shadow: ${props => props.shadow};
  border-bottom: ${props => props.noBorder ? "" :  "1px solid " + props.border};
  padding-bottom: ${props => props.cardToggle ? "32px" : props.noBorder ? "0" : "15px"};
  border-radius: 0;  
  margin: 12px 0;
  transition: 300ms;
  // ${({ tranche, cardShadow }) => tranche && `
  //   :hover{
  //     box-shadow: ${cardShadow};   
  //   }
  // `}
`;



const StatusTextWrapper = styled.h2`
  color: ${props => props.color ? props.color : ""} !important; 
  background: ${props => props.backgroundColor ? props.backgroundColor : ""} !important; 
  text-transform: uppercase;
  font-size: 12px;
  width: auto;
  padding: 12px 0px;
  position: relative;
  ${({ table, color }) => table === 'tranche' && `
    background: transparent;
    border: ${color ? "1px solid " + color : ""};
  `}
  ${({ table, docsLockupBackground, docsLockupText }) => table === 'stake' && `
    font-weight: 600;
    font-size: 14px !important;
    border-radius: 10px  !important;
    color: ${docsLockupText} !important; 
    background: ${docsLockupBackground} !important; 
  `}
`;

const TableCardTag = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 24px 0 0;
  ${({ color }) => color && `
    border-color: ${color} transparent transparent transparent;
  `}
  img{
    position: absolute;
    left: ${({ color }) => color === '#1ebb1b' ? '1px' : '3px'};
    top: ${({ color }) => color === '#1ebb1b' ? '-22px' : '-20px'};
  }
`;

const TableCardImg = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  img{
    border-radius: 50%;
    height: 37px;
    @media (max-width: 992px){
      height: 24px;
      margin-right: 4px;
    }
  }
  img:nth-child(1){
    z-index: 1;
  }
  img:nth-child(2){
    margin-left: -10px;
  }
  span{
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -9px;
    bottom: 0;
    @media (max-width: 992px){
      left: 29px;
    }
    img{
      height: 36px;
    }
  }
  ${({ stakeMobile }) => stakeMobile && `
    margin-right: 12px;
  `}
  ${({ tranche, color, type }) => tranche && type === "A" && `
    ::after{
      content: "A";
      position: absolute;
      right: -5px;
      bottom: 0;
      color: #FFFFFF;
      font-size: 8px;
      width: 15px;
      height: 15px;
      background: ${color};
      display: flex;
      justify-content: center;
      align-items: center;
    border-radius: 4px;
    }
  `}
  ${({ tranche, color, type }) => tranche && type === "B" && `
    ::after{
      content: "B";
      position: absolute;
      right: -5px;
      bottom: 0;
      color: #FFFFFF;
      font-size: 8px;
      width: 15px;
      height: 15px;
      background: ${color};
      display: flex;
      justify-content: center;
      align-items: center;
    border-radius: 4px;
    }
  `}
  ${({ stake }) => stake && `
    @media (max-width: 767px){
      flex-direction: column;
      img:nth-child(2){
        margin-left: 0;
        margin-top: -12px;
      }
    }
  `}
`;


const TableCol = styled.div`
  position: relative;
  width: ${props => props.width} !important;
  display: ${props => props.display};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
`
const TableColContent = styled.div`
  display: ${props => props.display};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  margin: ${props => props.margin};
  width: ${props => props.width};
`
const TableColText = styled.h2`
  position: relative;
  font-size: ${props => props.fontSize} !important;
  font-family: 'Inter', sans-serif;
  display: ${props => props.display};
  color: ${props => props.color} !important;
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign} !important;
  letter-spacing: ${props => props.letterSpacing};
  text-transform: ${props => props.textTransform};
  line-height: ${props => props.lineHeight};
  margin: ${props => props.margin};
  display: ${props => props.display};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.JustifyContent};
  flex-direction: ${props => props.flexDirection};
  a img{
    height: ${props => props.imgHeight};
    margin: ${props => props.imgMargin}
  }
`



const TableContentCardWrapperMobile = styled.div`
  width: 100%;
  height: 56px;
  // background: ${props => props.color};
  // border: 1px solid ${props => props.borderColor};  
  box-sizing: border-box;
  // box-shadow: 0px 4px 4px rgb(189 189 189 / 7%);
  border-radius: 5px;
  margin: 12px 0;
  ${({ tranche, noBorder, cardToggle, firstItem, lastItem, CardOpen, CardClosed, border }) => tranche && `
    min-height: 120px;
    height: auto;
    @media (max-width: 992px){
      height: auto;
      min-height: 56px;
      border-bottom: ${noBorder ? "" : "1px solid " + border};
      background: ${cardToggle ? CardOpen : CardClosed};
      border-radius: ${firstItem ? "16px 16px 0 0" : lastItem ? "0 0 16px 16px" : "0"};
      margin: 0;
    }
  `}
  ${({ stakeMobile }) => stakeMobile && `
    @media (max-width: 992px){
      height: 128px;
    }
  `}
  ${({ toggleCard }) => toggleCard && `
    @media (max-width: 992px){
      height: auto;
    }
  `}
`;

const TableContentCardMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  @media (max-width: 992px){
    padding-left: 0;
  }
  span{
    // height: 100%;
    // width: 4px;
    // background: ${props => props.color ? props.color : ""};
    // position: absolute;
    // left: 0;
  }
  ${({ trancheMobile }) => trancheMobile && `
  
  `}
  border-bottom: none !important;
`;



const StakeBtn = styled.button`
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-bottom: 2px;  
  margin: 0 2px;
  outline: none;
  color: #FFFFFF;
  background: ${props => props.background};
  ${({ disabled }) => disabled && `
    background: rgba(204, 204, 205, 0.15);
    pointer-events: none;
    cursor: default;
  `}
  transition: 300ms;
  :hover{
    opacity: 0.7;
  }
  @media (max-width: 992px){
    width: 25px;
    height: 25px;
    font-size: 12px;
  } 
`;

const StakeBtns = styled.div`
  display: flex;
`;

const TableMobileContent = styled.div`
  width: 100%;
  height: 105px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ trancheMobile }) => trancheMobile && `
    justify-content: center !important;
    height: auto;
  `}
  ${({ stakeMobile }) => stakeMobile && `
    width: 100%;
    padding: 10px 12px;
  `}
`;

const TableMobileContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${({ trancheMobile }) => trancheMobile && `
    display: flex;
    align-items: center;
    height: auto !important;
  `}
`;

const TableMobileContentCol = styled.div`
  // width: 33.3333333%;
  ${({ stake }) => stake && `
    width: 25%;
    text-align: center;
  `}
  ${({ titleColor }) => titleColor && `
    h2:first-child{
      color: ${titleColor};
      opacity: 0.6;
    }
  `}
  titleColor
  :nth-child(1){
    h2{
      display: flex;
      align-items: center;
      img{
        height: 12px;    
        :nth-child(1){
          margin: 0 5px 0 0;
        }
        :nth-child(2){
          margin: 0 0 0 2px;
        }
      }
    }
  }
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10.42px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.color};
    margin: 3px 0 0 0;
    span{
      font-weight: normal;
      height: auto;
      width: auto;
      background: none;
      position: relative;
    }
  }
  h2:first-child{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 5px;
    line-height: 7px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.color};
    @media (max-width: 992px){
      font-size:  6px;
    }
  }
  h2:nth-child(3){
    font-size: 9px;
    color: #CCCCCD;
  }
  ${({ trancheMobileRows, width, color, titleColor }) => trancheMobileRows && `
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    h2:first-child{
      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;
      color: ${titleColor};
      margin-right: 5px;
    }
    h2:last-child{
      font-weight: bold;
      font-size: 13px;
      color: ${color};
      justify-content: start;
    }    
  `}
  
  titleColor
  ${({ stakeMobile, color, width }) => stakeMobile && `
    width: ${width}%;
    text-align: left;
    h2:first-child{
      font-family: 'Inter', sans-serif;
      font-weight: normal;
      text-transform: capitalize;
      font-size: 10px;
      letter-spacing: 0.03em;
      color: ${color};
      opacity: 0.4;
    }
    h2:last-child{
      font-family: 'Inter', sans-serif;
      font-weight: bold;
      font-size: 13px;
      color: ${color};
      margin-top: 8px;
    }
  `}
`;

const StakeBtnSlice = styled.button`
  outline: none;
  border: none;
  width: 96.32px;
  height: 34.19px;
  background: #4441CF;
  border-radius: 50px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
  ${({ withdraw }) => withdraw && `
    background: #6E41CF;    
    font-size: 12px;
  `}
  ${({ disabled, disabledBtnColor }) => disabled && `
    background: ${disabledBtnColor};
    pointer-events: none;
    font-size: 12px;
  `}
  @media (max-width: 767px){
    font-size: 10px;
    width: 73.32px;
    height: 25.19px;
  } 
  transition: 300ms;
  :hover{
    opacity: 0.7;
  }
  ${({ stakeMobile }) => stakeMobile && `
    @media (max-width: 992px){
      width: 83px;
      height: 32px;
      border-radius: 4px;
      font-size: 12px;
    }
  `}
`;



const TableHeadWrapper = styled.div`
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableHeadTitle = styled.div`
  position: relative;
  display: flex;
  align-items: ${props => props.alignItems} !important;
  justify-content: ${props => props.justifyContent} !important;
  width: ${props => props.width} !important;
  text-align: ${props => props.textAlign} !important;
  
  & > h2{
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.05em;
    border-bottom: 2px dashed ${props => props.color};
    text-transform: uppercase;
    color: ${props => props.color};      
    @media (max-width: 1200px){
    }
    :hover{
      color: ${props => props.theme === "light" ? "#242732" : "#FFFFFF"};
      border-color: ${props => props.theme === "light" ? "#242732" : "#FFFFFF"};
    }
  }
  & > h2:nth-child(2){
    margin-left: 42px;
  }
  & > div {
    position: relative;
    cursor: pointer;
    display: flex;
    text-align: center;
    width: 100%;
    & > h2{
      text-align: center;
      border-bottom: 2px dashed ${props => props.color};
      width: 100%;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: rgba(56, 56, 56, 0.3);
    }
      & > h2:nth-child(2){
        margin-left: 73px;
      }
  }
  @media (max-width: 992px){
    display: none !important;
  }
  ${({ stakingPool }) => stakingPool && `
      width: 100%;
      text-align: left;
      div{
      h2{
        text-align: left !important;
        }
      }
  `}
  ${({ defaultCursor }) => defaultCursor && `
    & > div {
        cursor: default;
    }
  `}
  ${({ platform }) => platform && `
    width: 30%;
  `}
  ${({ apy }) => apy && `
    width: 14%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ totalValue }) => totalValue && `
    width: 15%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ subscription }) => subscription && `
    width: 15%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ status }) => status && `
    width: 17%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}    
  ${({ trancheTableBtns }) => trancheTableBtns && `
    width: 4%;
    text-align: center;
  `}
  ${({ stakingPoolStake }) => stakingPoolStake && `
    width: 19%;
  `}
  ${({ statusStake }) => statusStake&& `
    width: 18%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ statusStake, sliceStaking }) => (statusStake && sliceStaking) && `
    width: 10%;
  `}
  ${({ reward }) => reward && `
    width: 16%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ APYStake }) => APYStake && `
    width: 10%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ StakeBtnsProvider }) => StakeBtnsProvider && `
    width: 12%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}    
  ${({ staked }) => staked && `
    width: 15%;
    h2{
      text-align: center;
      margin: 0 auto;      
    }
  `}
  ${({ stakeCol }) => stakeCol && `
    width: 12%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ btnsStake }) => btnsStake && `
    width: 12%;
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  ${({ btnsStake, sliceStaking }) => (btnsStake && sliceStaking) && `
    width: 15%;
  `}    
  ${({ tranche, color }) => tranche && `
    & > h2{
      border-bottom: 2px dashed ${color};
    }
  `}
  ${({ alignCenter }) => alignCenter && `
    h2{
      text-align: center;
      margin: 0 auto;
    }
  `}
  
`;

const TooltipWrapper = styled.div`
  position: absolute !important;
  bottom: calc(100% + 13px);
  transition: 300ms;
  ${({ row }) => row && `
    bottom: calc(95%);
  `}
  ${({ platform, language }) => platform && `
    left: ${language === "en" ? "calc(100% - 255px)" : "calc(100% - 273px)"};
    z-index: -1;
  `}
  ${({ apy, language }) => apy && `
    left: ${language === "en" ? "calc(100% - 30px)" : language === "kr" ? "calc(100% + 9px)" : "calc(100% - 39px)"};
    z-index: -1;
  `}
  ${({ totalValue, language }) => totalValue && `
    left: ${language === "en" ? "calc(100% - 30px)" : language === "kr" ? "calc(100% - 50px)" : "calc(100% - 55px)"};
    z-index: -1;
  `}
  ${({ deposit, language }) => deposit && `
    left: ${language === "en" ? "calc(100% - 15px)" : language === "kr" ? "calc(100% - 50px)" : "calc(100% - 45px)"}; 
    z-index: -1;
  `}
  ${({ available, language }) => available && `
    left: 45px;
    bottom: 105px !important;
    top: unset !important;
    z-index: -1;
    div{
      :after{
        content: '';
        top: 93% !important;
        left: 0 !important;
        transform: rotate(-180deg) !important;
      }
    }
  `}
  ${({ stakingPool, language }) => stakingPool && `
    top: calc(100% - 23px) ;
    bottom: unset;
    left: ${language === "en" ? "calc(100% - 90px)" : language === "kr" ? "calc(100% - 115px)" : "calc(100% - 140px)"};;
  `}
  ${({ status, language }) => status && `
    top: calc(100% - 23px);
    bottom: unset;
    left: ${language === "en" ? "calc(100% - 20px)" : language === "kr" ? "calc(100% - 40px)" : "calc(100% - 40px)"};
  `}
  ${({ staked, language }) => staked && `
    top: calc(100% - 23px);
    bottom: unset;
    left: ${language === "en" ? "calc(100% - 15px)" : language === "kr" ? "calc(100% - 45px)" : "calc(100% - 45px)"};
  `}
  ${({ reward, language }) => reward && `
    top: calc(100% - 23px);
    bottom: unset;
    left: ${language === "en" ? "calc(100% + 5px)" : language === "kr" ? "calc(100% - 40px)" : "calc(100% - 30px)"};
  `}
  ${({ APY, language }) => APY && `
    top: calc(100% - 23px);
    bottom: unset;
    left: ${language === "en" ? "calc(100% - 25px)" : language === "kr" ? "calc(100% - 40px)" : "calc(100% - 45px)"};
  `}
  ${({ yourStake, language }) => yourStake && `
    top: calc(100% - 23px);
    bottom: unset;
    left: ${language === "en" ? "calc(100% - 12px)" : language === "kr" ? "calc(100% - 25px)" : "calc(100% - 25px)"};
  `}
  ${({ manageStake, language, sliceStaking }) => manageStake && `
    left: 15px;
    bottom: 30px !important;
    top: unset !important;
    z-index: -1;
    div{
      :after{
        content: '';
        top: 92% !important;
        left: 0 !important;
        transform: rotate(-180deg) !important;
      }
    }
  `}
  ${({ summary }) => summary && `
    transform: translateX(25px);
    z-index: -1;
  `}
  ${({ tooltip }) => tooltip && `
    z-index: 1 !important;
  `}
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    width: 100%;
    max-height: 140px;    
    padding: 12px 10px;  
    ${({ summary }) => summary && `
      z-index: -1;
    `}
    background: ${props => props.color};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    top: 50%;
    left: -7px;
    transition: 300ms;
    z-index: 2000;
    visibility: hidden;
    opacity: 0;
    ${({ tooltip }) => tooltip && `
      opacity: 1;
      visibility: visible;
      z-index: 1 !important;
    `}
    h2{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #FFFFFF;
      text-align: left !important;
    }
    :after{
      content: '';
      position: absolute;
      top: 10px;
      left: -14px;
      transform: rotate(-90deg);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 14px 10px 12px;
      border-color: transparent transparent ${props => props.color} transparent;
    }
    ${({ platform }) => platform && `
      transform: translateY(110%);
    `}
    ${({ apy }) => apy && `
      transform: translateY(110%);
    `}
    ${({ deposit }) => deposit && `
      transform: translateY(110%);
    `}
    ${({ totalValue }) => totalValue && `
      transform: translateY(110%);
    `}
    ${({ available }) => available && `
      transform: translateY(110%);
    `}
    ${({ summary }) => summary && `
      transform: translateY(-15%);
      h2{
        color: #FFFFFF;
      }
      :after{
        position: absolute;
        top: unset;
        bottom: -8px !important;
        right: 10px !important;
        left: unset !important;
        transform: rotate(180deg);
      }
    `}
    ${({ row }) => row && `
      padding: 7px 5px;
      bottom: calc(95%);
      h2{
        text-align: center !important;
      }
      :after{
        top: unset;
        bottom: -8px !important;
        right: unset !important;
        left: 50% !important;
        transform: rotate(180deg) translateX(50%);
      }
    `}
  }
`;

const TableContainerHeader = styled.div`
  min-height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableHeaderTitles = styled.div`
  position: relative;
`;

const TableTitle = styled.div`
  z-index: 1;
  ${({ withHowto }) => withHowto && `
    display: flex;
    align-items: center;
  `}
  & > h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;        
    color: ${props => props.color};
  }
  margin: ${props => props.summary ? "33px 0 29px 0" : ""};
  ${({ stake }) => stake && `
    margin: 40px 0;
  `}
  ${({ stakeTitle }) => stakeTitle && `
    margin: 25px 0 20px 0;
  `}
`;

const TableSubTitle = styled.div`
  & > h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12.5px;
    line-height: 15px;
    color: ${props => props.color};
    ${({ sorting }) => sorting && `
      text-align: center;
      letter-spacing: 2px;
    `}
  }
`;

const CreateLoanBtn = styled.div`
  & > button{
    background-color: transparent;
    border: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    cursor: pointer;
    color: #39295A;
    transition: 300ms;
    &:focus{
        outline: none;
    }
    & > img{
        margin-bottom: -1px;
        pointer-events: none;
    }
    :active{
      transform: scale(0.9);
    }
  }
`;

const TableMarketsSortingDropdown = styled.div`
  position: absolute;
  width: 150px;
  height: 55px;
  margin-top: 7px;
  overflow: hidden;
  right: ${({path}) => path === "tranche" ? "20px" : " -26px"};
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.19);
  border-radius: 6px;
  z-index: 1000;
  ${({ sorting }) => sorting && `
    right: 33px;  
  `}
`;

const TableMarketsSortingDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TableMarketSortingBtn = styled.button`
  height: 50%;  
  cursor: pointer;
  background: transparent;
  border: none;
  color: rgba(56,56,56,0.7);
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1.09px;
  :hover{
    background: #FAFAFA;
  }
  :focus{
    outline: none;
  }
`;

const AdustBtnWrapper = styled.div`
  button{
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    width: 50px;
    height: 27px;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    transition: 300ms;
    ${({ status }) => status && `
      transform: rotate(180deg);
    `}
    :hover{
      filter: brightness(97%);
    }
    img{
      pointer-events: none;
    }
    :disabled{
      background-color: #F1F1F1;
      color: #666666;
      cursor: default
      img{
        filter: grayscale(1);
        opacity: 0.3;
      }
    }
  }
  ${({ chevron }) => chevron && `
    button{
      background: transparent  !important;
    }
  `}
  ${({ trancheMobile }) => trancheMobile && `
    button{
      position: absolute !important;
      top: 8px !important;
      right: 0 !important;
      img{
        width: 12px;
      }
    }
  `}  
`;

const TableCardMore = styled.div`
  @media (max-width: 992px){
    padding: 0 !important;  
    // border-top: 2px solid #F9F9FB;
  }
  // border-bottom: 2px solid ${props => props.color} !important;
  ${({ border, color }) => border && `
    // border-bottom: 1px solid ${color};  
  `}
  @media (max-width: 992px){
  }
`;

const TableCardMoreContent = styled.div`
  position: relative;   
`;

const TableCardImgWrapper = styled.div`
  height: 92px;
  width: 17%;
  display: flex;
  align-items: center;
`;

const TrancheRateType = styled.h2`
  background: ${props => props.TrancheRateColor};;
  width: 130px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;  
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.TrancheRateTextColor};
  @media (max-width: 1200px) {
    // left: 180px;
    // width: 80px;
    font-size: 12px;
  }
  ${({ trancheMobile, TrancheRateColor }) => trancheMobile && `
    border-radius: 5px !important;
    width: 80px !important;
    height: 25px !important;
    position: relative;
    left: -15px !important;
    font-size: 11px !important;
    color: ${TrancheRateColor} !important;
  `}
  ${({protocol}) => protocol && `
    left: 0;
    position: relative;
  `}
  ${({mobile, color}) => mobile && `
    background: transparent;
    position: relative;
    left: 0 !important;
    opacity: 0.7;
    color: ${color} !important;
    text-transform: initial !important;
    font-weight: normal !important;
    font-size: 12px !important;
    letter-spacing: 0.03em !important;
    margin: 0 0 0 4px !important;

  `}

  display: flex;
  align-items: center;
  justify-content: center;
  img{
    margin-right: 8px;
  }

  
`;

const TableAPYTooltipWrapper = styled.span`
  img{
    margin-left: 5px;
    cursor: default;
  }
`;

const TableAPYTooltip = styled.span`
  width: auto;
  height: 102px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.tooltipColor};
  border-radius: 12px;
  position: absolute;
  left: calc(100% + 20px);
  z-index: 2000;
  top: 50%;
  transform: translateY(-50%);
  filter: ${props => props.theme === 'light' ? "drop-shadow(0px 4px 20px rgba(183, 183, 183, 0.15))" : ""};
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 6px;
    text-align: left !important;
  }
  :before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid ${props => props.tooltipColor};
  }
  img{
    z-index: 3000;
  }
  span{
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${props => props.titleColor};
    min-width: 40px;
  }
`;

const TableAPYTooltipCol = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-width: 67px;
  img{
    width: 24px;
    margin: 0 0 4px 0 !important;
  }
  h2{
    font-family: 'Inter', sans-serif;
    color: ${props => props.titleColor};
    text-align: center;
    margin: 0 0 1px 0 !important;
  }
  h2:nth-child(2){
    font-weight: normal;
    font-size: 12px !important;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  h2:nth-child(3){
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 18px;
    text-transform: uppercase;
  }
  h2:nth-child(4){
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.03em;
    opacity: 0.5;
    text-transform: capitalize;
    font-size: 10px !important;
  }
`;

const CheckboxContent = styled.div`
  input{
    opacity: 0;
    position: absolute;
  }
  input + label{
    position: relative;
    display: inline-block;
    user-select: none;
    cursor: pointer;
    -moz-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-tap-highlight-color: transparent;
    height: 19.99px;
    width: 33.99px;
    background: #CCCCCD;
    border-radius: 60px;
    :before{
      content: "";
      position: absolute;
      display: block;
      -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      height: 19.99px;
      width: 33.99px;
      top: 0;
      left: 0;
      border-radius: 30px;
    }
    :after{
      content: "";
      position: absolute;
      display: block;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13), 0 3px 3px hsla(0, 0%, 0%, 0.05);
      -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      background: #FAFAFA;
      height: 11.34px;
      width: 11.34px;
      top: 50%;
      transform: translateY(-50%);
      left: 4px;
      border-radius: 60px;
    }
  }
  input:checked + label{
    :before{
      background: #4441CF;
      -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
      -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
      -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
      transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    }
    :after{
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
  ${({ themeToggle }) => themeToggle && `
    display: flex;
    input + label{
      background: #9496B6;
    }
  `}
`;

const CheckboxWrapper = styled.div`
  display: ${({ hidden }) => hidden ? 'none' : 'flex'};
  align-items: center;
  & > h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0 7px 4px 0;
    color: #7C859B;
  }
  ${({ themeToggle }) => themeToggle && `
    margin-left: 40px;
    h2{
      font-size: 11.91px;
      color: #9496B6;
      margin: 0 12px;
    }
  `}
`;

const FormContent = styled.div` 
  position: relative;
  button{
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.color} !important;
  }
  input{
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${props => props.border ? props.border : "#CFCFE5"};
    background: transparent;
    outline: none;
    padding: 2px 55px 2px 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.color} !important;
    @media (max-width: 992px){
      font-size: 20px;
      height: 48px;
      border-radius: 8px;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number]{
    -moz-appearance: textfield;
  }
  h2{
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.color} !important;
    margin: 0;
  }
  & > h2{
    transition: 200ms;
    :hover{
      opacity: 0.7;
    }
  }
  ${({ TrancheWFTM, FTMIcon, color }) => TrancheWFTM && `
    width: 100%;
    input{
      height: 40px;
      @media (max-width: 992px){
        height: 48px;
        border-radius: 8px;
      }
      border: 1.5px solid #CFCFE5;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px 67px 16px 50px;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: rgba(255, 255, 255, 0.4);
      background-image: url(${FTMIcon}) !important;
      background-repeat: no-repeat !important;
      background-position: 16px 50% !important;
      ::-webkit-input-placeholder{
        font-family: 'Inter', sans-serif;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: ${color};
        opacity: 0.4;
      }      
      :-ms-input-placeholder{
        font-family: 'Inter', sans-serif;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: ${color};
        opacity: 0.4;
      }      
      ::placeholder{
        font-family: 'Inter', sans-serif;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: ${color};
        opacity: 0.4;      }
    }
    h2{
      font-size: 16px;
      margin-right: 16px;
      color: ${color};
    }
    h3{
      color: ${color};
    }
  `}  
`;

const FormContentWrapper = styled.div` 
  width: 100%;
  h3{
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: ${props => props.textColor ? props.textColor : "#FFFFFF"};
    margin: 10px 0;
  }
`;

const MobileMoreFormBtn = styled.button`
  ${({ current }) => current && `
    border-bottom: 2px solid #4441CF!important;
    opacity: 1 !important;
  `}
`;

const MobileMoreFormBtns = styled.div`
  button{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background: transparent;
    border: none;
    padding: 12px 0;
    outline: none;
    opacity: 0.5;
    margin-right: 32px;
    color: ${props => props.color};
  }
`;

const TableColMobile = styled.div`
  width: 19%;
  ${({ address }) => address && `
    width: 34%;
  `}
  ${({ btn }) => btn && `
    width: 9%;
  `}
`;

const TableHeadTitleMobile = styled.div`
  width: 100%;
  width: 100%;
  text-align: center;
  h2{
    font-style: normal;
    font-weight: 500;
    text-align: center;
    font-size: 8px;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.color};
    width: 100%;
    text-align: center;
    ${({ address }) => address && `
      text-align: left;
    `}
  }
  display: flex;
  h2:nth-child(2){
    margin-left: 73px;
  }  
`;

const TableHeadWrapperMobile = styled.div`
  min-height: 22px;
  background: transparent;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableMoreLeftBottomSection = styled.div`
  // height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 992px){
    height: auto;
    // min-height: 150px;
    display: none;
  }
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    color: ${props => props.titleColor};
  }
  p{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.05em;
    margin: 13px auto;
    color: ${props => props.value};
  }
`;
const TableMoreLeftBottomSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


const TableMoreLeftSection = styled.div`
  display: flex;
  justify-content: center;
  width: 25%;
  height: 100px;
  // ${({ color }) => color && `
  //   border-right: 2px solid ${color};
  // `}
  ${({ last }) => last && `
    border: none;
  `}
`;

const TableMoreLeftSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    margin: 5px auto;
  }
  & > h2:nth-of-type(1){
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.titleColor};
    @media (max-width: 992px){
      font-size: 8px;
    }
  }
  & > h2:nth-of-type(2){
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    color: ${props => props.value};
    text-align: center;
    @media (max-width: 992px){
      font-size: 12px;
    }
  }
  ${({ dividend }) => dividend && `
    & > h2:nth-of-type(1){
      text-transform: initial;
    }
  `}
`;

const TableMoreLeftTopSection = styled.div`
  display: flex;
  // border-bottom: 2px solid ${props => props.color};
`;


const BalanceLabel = styled.h2`
  color: ${props => props.textColor};
  opacity: 0.6;
  @media (max-width: 992px){
    font-family: 'Inter', sans-serif;
    font-weight: bold !important;
    font-size: 12px !important;
    text-transform: uppercase;
    margin: 20px 0;
  }
`

const MaxLabel = styled.h2`
  color: ${props => props.textColor};
  @media (max-width: 992px){
    font-weight: bold !important;
    font-size: 16px !important;
    letter-spacing: 0.03em !important;
  }
`


const TableMoreRightSection = styled.div` 
  width: 50%;
  min-height: 220px;
  padding: 32px;
  border-left: 2px solid ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.05em;
    color: ;
    // margin: 12px 0;
    span{
      text-transform: initial;
      margin-left: 3px;
    }
  }
  h2:nth-child(1){
    color: ${props => props.textColor};
  }
  h2:nth-child(2){
   
  }
  form{
    display: flex;
    flex-direction: column;
    & > button{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      @media (max-width: 992px){
        height: 48px;
        border-radius: 8px;
        font-weight: bold !important;
        font-size: 14px !important;
        text-transform: uppercase;
        img{
          display: none;
        }
      }
      background: #4441CF;
      border: none;
      border-radius: 4px;
      font-family: 'Inter', sans-serif;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #FFFFFF;
      margin: 16px 0 0 0;
      outline: none;
      cursor: pointer;
      & > h2{
        font-family: 'Inter', sans-serif;
        font-weight: bold !important;
        font-size: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF !important;
        margin: 0;
        line-height: 85%;
      }
      :disabled{
        background: ${props => props.disabledColor} !important;  
        color: ${props => props.disabledTextColor} !important;  
        opacity: 1 !important;
        pointer-events: none;
        
      }
      img{
        margin-right: 5px;
      }
    }
    h2 {
      margin: 0;
    }
  }
  ${({ withdraw }) => withdraw && `
    form{
      button{
        img{
          transform: rotate(180deg);
        }
      }
    }
  `}
  ${({ loading }) => loading && `
    opacity: 0.2;
    pointer-events: none;
    position: relative;
    & > div:nth-of-type(1){
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `}
  ${({ disabled, disabledBackground, btn, disabledColor, disabledTextColor }) => disabled && `
    form{
      div{
        button{
          opacity: 0.5;
          pointer-events: none !important;
        }
        input{
          background: ${disabledBackground} !important;  
          pointer-events: none !important;
          border-color: ${btn} !important;
        }
      }
      /* & > button{
        pointer-events: none !important;
        background: ${btn} !important;
      } */
    }
    
  `}
  @media (max-width: 992px){
    width: auto;
    border: none;
    padding: 0;
    margin: 0 20px 0 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${props => props.border};
  }
  form{
    button{
      transition: 200ms;
      :hover{
        opacity: 0.7;
      }
    }
  }
`;

const TableMoreRowContent = styled.div` 
  display: flex;
  min-height: 220px;  
  width: 100%;
  background: ${props => props.TableMoreColor};
  border: 1px solid ${props => props.border};
  border-radius: 24px;
  @media (max-width: 992px){
    flex-direction: column;
    border-radius: ${props => props.lastItem ? "0 0 16px 16px" : "0"};
    border: none;
    background: ${props => props.CardOpen};
  }
`;

const TableMoreRowContentLeft = styled.div` 
  width: 50%;
  min-height: 220px;
  padding: 32px 20px;
  @media (max-width: 992px){
    width: 100%;
    min-height: unset;
    padding: 0;
  }
`;

const TableMoreRowContentRight = styled.div` 
  display: flex;
  width: 50%;
  min-height: 220px;
  @media (max-width: 992px){
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const TableMoreRowWrapper = styled.div`
  position: relative;   
  @media (max-width: 992px){
    padding: 0 !important;  
  }
`;


const TableMoreTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h2:nth-child(1){
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.titleColor};
    min-width: 40px;
  }
`;
const WrapBtnForm = styled.button`
  width: 114px;
  height: 32px;
  background: ${props => props.theme === 'light' ? "transparent" : "#292661"};
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border: ${props => props.theme === 'light' ? "1px solid #292661" : "none"};;
  color: ${props => props.theme === 'light' ? "#292661" : "#A09EFF"};
  outline: none;
  cursor: pointer;
  @media (max-width: 992px){
    border: none;
    font-size: 10.5px;
    width: auto;
    height: auto;
    background: transparent;
  }
`

const TableMoreTitle = styled.div`
  h2:nth-child(1){
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  h2:nth-child(2){
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    margin: 12px 0 20px 0;
    span{
      text-transform: initial;
      margin-left: 3px;
    }
  }

  ${({ textwithBtn, theme }) => textwithBtn && `
    width: 100%;
    h2:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button{
        width: 114px;
        height: 32px;
        background: ${theme === 'light' ? "transparent" : "#292661"};
        border-radius: 8px;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        border: ${theme === 'light' ? "1px solid #292661" : "none"};;
        color: ${theme === 'light' ? "#292661" : "#A09EFF"};
        outline: none;
        cursor: pointer;
        @media (max-width: 992px){
          border: none;
          font-size: 10.5px;
          width: auto;
          height: auto;
          background: transparent;
        }
      }
    }
  `}

`;

const TableMoreBtn = styled.button`
  border-radius: ${props => props.borderRadius ? props.borderRadius : "50px"} ;
  width: ${props => props.width ? props.width : "auto"};
  height: 40px;
  width: ${props => props.width ? props.width : "auto"};
  padding: ${props => props.padding ? props.padding : "0 15px"};;
  cursor: pointer;
  background: ${props => props.btnColor};
  color: ${props => props.textColor};
  border: none;
  margin: ${props => props.margin};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
  text-transform: ${props => props.textTransform};
  img,svg{
    margin-right: 8px;
    @media (max-width: 992px){
      margin-right: 0;
    }
  }
  :hover{
    color: ${props => props.textColor};
  }
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: ${props => props.textColor};
    margin: 0;
    text-transform: none;
  }
  @media (max-width: 992px){
    font-size: 9px;
  }

`


const TableMoreMobileIcons = styled.div`
  a img, button img{
    height: 18px;
    margin-left: 10px;
  }
  button{
    padding: 0;
    background: transparent;
    border: none;
  }
`;

const TableMoreBtns = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.padding ? props.padding : "0"};
  margin: ${props => props.margin ? props.margin : "20px 0 0 0"};
  @media (max-width: 992px){
    margin: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
`
const TableContentWrapper = styled.div`
  background: ${props => props.TableColor};
  padding: 32px;
  border-radius: 16px;
  @media (max-width: 992px){
    padding: 15px 12px;
  }
`
const APYMobileWrapper = styled.div`
  padding: 7px 0 17px 0;
  margin: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.border};
  h2{
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: ${props => props.color};
    margin-right: 5px;
    img{
      width: 16px;
      margin-right: 5px;
    }
    span{
      font-weight: normal;
      font-size: 10px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.03em;
      color: ${props => props.color};
      opacity: 0.5;
      margin: 0 0 0 5px;
    }
  }
`


export {
  TableWrapper,
  TableContentMobile,
  TableContentCard,
  CallToActionTradeWrapper,
  CallToActionTradeBtns,
  CallToActionTradeBtn,
  CallToActionTradetext,
  LoadingContent,
  TableContentCardWrapper,
  StatusTextWrapper,
  TableCardTag,
  TableCardImg,
  TableContentCardWrapperMobile,
  TableContentCardMobile,
  StakeBtn,
  StakeBtns,
  TableMobileContent,
  TableMobileContentRow,
  TableMobileContentCol,
  StakeBtnSlice,
  TableHeadWrapper,
  TableHeadTitle,
  TooltipWrapper,
  TableContainerHeader,
  TableHeaderTitles,
  TableTitle,
  TableSubTitle,
  CreateLoanBtn,
  TableMarketsSortingDropdown,
  TableMarketsSortingDropdownContent,
  TableMarketSortingBtn,
  AdustBtnWrapper,
  TableCardMore,
  TableCardMoreContent,
  TableCardImgWrapper,
  TrancheRateType,
  TableAPYTooltipWrapper,
  TableAPYTooltip,
  TableAPYTooltipCol,
  CheckboxContent,
  CheckboxWrapper,
  FormContent,
  FormContentWrapper,
  MobileMoreFormBtn,
  MobileMoreFormBtns,
  TableColMobile,
  TableHeadTitleMobile,
  TableHeadWrapperMobile,
  TableMoreLeftBottomSection,
  TableMoreLeftBottomSectionHeader,
  TableMoreMobileIcons,
  TableMoreLeftSection,
  TableMoreLeftSectionContent,
  TableMoreLeftTopSection,
  TableMoreRightSection,
  TableMoreRowContent,
  TableMoreRowContentLeft,
  TableMoreRowContentRight,
  TableMoreRowWrapper,
  TableMoreTitleWrapper,
  TableMoreTitle,
  TableMoreBtns,
  TableMoreBtn,
  TableContentWrapper,
  BalanceLabel,
  MaxLabel,
  APYMobileWrapper,
  WrapBtnForm,
  TableCol,
  TableColContent,
  TableColText
};