import styled from "styled-components";

const NavbarLinks = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 159px;
  border: 1px solid ${props => props.color};
  ${({ tabs }) =>
  tabs &&
  `
    width: auto;
  `}
  & > a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.theme.navlinkTab};
    padding: 17px 37px;
    border-radius: 159px;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
  }
  @media (max-width: 992px) {
    margin: 12px 0;
    a{
      font-size: 12px;
      padding: 9px 25px;
    }
  }
`;

const NavbarWrapper = styled.div`
  height: 112px;
  position: relative;
  border-bottom: 2px solid rgba(233, 233, 252, 0.1);
  @media (max-width: 992px) {
    height: auto;
    padding: 15px 0;
    border-bottom: 2px solid rgba(233, 233, 252, 0.1);;  
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

const NavBarMobile = styled.div`
  width: 100%;
  background: #F9F9FE;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition-property: height, opacity, padding;
  transition-duration: 300ms, 100ms, 300ms;
  transition-timing-function: ease;
  ${({ rates }) => rates && `
    left: -100px;
    transition: 300ms;
    background: #F1F1F1;
    z-index: 2000;
  `}
`;

const NavBarMobileContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  h2:nth-child(2){
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #686565;
    margin: 12px auto;
  }
  ${({ first }) => first && `
    div{
      margin: 25px auto;
      display: flex;
      flex-direction: column;
      a{
        font-style: normal;
        font-weight: normal;
        margin: 14px auto;
        font-size: 28px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #282828;
        opacity: 0.5;
      }
    }
  `}
  button{
    border: none;
    outline: none;
    position: absolute;
    left: 20px;
    top: 20px;
  }
`;

const MobileNavbarIconWrapper = styled.div`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

const NavbarIconWrapper = styled.div`
  margin-left: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30px;
  height: 30px;
  background: rgba(68, 65, 207, 0.1);
  border-radius: 50%;
`;

const NavbarIconContent = styled.div`
`;

const RatesWrapper = styled.div`
  position: relative;
`;

const RatesBoxWrapper = styled.div`
  position: absolute;
  top: -102px;
  right: -220px;
  width: 298px;
  height: auto;
  background: #F9F9FE;
  border: 1px solid #efefef;
  box-sizing: border-box;
  @media (max-width: 992px) {
    left: -29px;
    transform: translateX(-50%);
  }
  ${({ mobile }) =>mobile && ` 
    left: 50% !important;
    top: 0;
  `}
`;

const NavbarSpan = styled.span`
  background: #4441CF !important;
`;

const WalletBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 155px;
  height: 40px;
  background: ${(props) => props.background} !important;
  border-radius: 30px;
  padding: 0 14px;
  border: none;
  cursor: pointer;
  ${({ icon }) =>
    !icon &&
    `
        justify-content: center;
    `}
  &:focus {
    outline: none;
  }
  @media (max-width: 992px) {
    margin: 5px 0;
    width: 129px;
    height: 33px;
  }
`;

const WalletBtnIcon = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    img{
      width: 17px;
    }
  }
`;

const WalletBtnText = styled.div`
  & > h2 {
    font-family: "Inter", sans-serif;;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;    
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 12px;
    @media (max-width: 992px) {
      font-size: 10px;
    }
  }
`;

const NavBarRightWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const StakeHeaderWrapper = styled.div`
`;

const StakeSummaryCard = styled.div`
  min-height: 159px;
  background: ${props => props.color};
  display: flex;
  align-items: center;
  border: 2px solid ${props => props.color};
  box-sizing: border-box;
  box-shadow: 0px 1.71507px 7.71781px rgba(68, 65, 207, 0.2);
  margin: 15px 0;
  padding: 20px 25px 20px 35px;
  border-radius: 12px;
  width: 49%;  
  @media (max-width: 767px){
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }
  ${({ claim }) => claim && `
    justify-content: space-between;
    padding: 20px;
    button{
      width: 302px;
      height: 46px;
      background: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 10.3798px;
      font-style: normal;
      font-weight: bold;
      cursor: pointer;
      font-size: 13.4938px;
      font-family: 'Inter', sans-serif;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  `}
  @media (max-width: 1199px){
    width: 100%;
  }
`;

const WithdrawStakeCard = styled.div`
  min-height: 159px;
  border: 2px solid #8639E8;
  box-sizing: border-box;
  box-shadow: 0px 1.71507px 7.71781px rgba(68, 65, 207, 0.2);
  border-radius: 12px;  
  margin: 15px 0;
  padding: 29px 35px;
  background: #8639E8;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px){
    padding: 20px;
    flex-direction: column;
  }
`;

const StackSummaryCol = styled.div`
  width: ${props => props.stake ? "49" : props.claim ? "46" : "54"}%;  
  min-height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2{
    font-family: 'Inter', sans-serif;
  }
  & > h2:nth-child(1){
    font-weight: 600;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);
  }
  & > h2:nth-child(2){
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF; 
    margin: 5px 0;
  }
  & > h2:nth-child(3), & > h2:nth-child(4){
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 9px 0;
  }
  & > h2:nth-child(5){
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.8); 
    margin: 9px 0 0 0;
  }
  & > span{
    width: 100%;
    max-width: 213px;
    height: 2px;
    background: rgba(255, 255, 255, 0.4);
  }
  ${({ claimBtn }) => claimBtn && `
    align-items: center;
    & > h2:nth-child(1){
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
    }
    button{
      transition: 300ms;
      outline: none;
      :hover{
        opacity: 0.7;
      }
    }
  `}
  @media (max-width: 767px){
    width: 100% !important;
    margin: 15px 0;
  }
`;

const WithdrawStakeCardText = styled.div`
  width: 80%; 
  h2, p{
    font-family: 'Inter', sans-serif;
  }
  h2{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    
  }
  p{
    margin: 10px 0 0 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.8);
  }
  @media (max-width: 767px){
    width: 100%;
  }
`;

const WithdrawStakeCardBtns = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button{
    width: 154px;
    height: 57px;
    margin: 0 10px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    padding: 0 20px;    
    outline: none;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #8639E8;
    background: #FFFFFF;
    border: none;
    border-radius: 10px;
    transition: 300ms;
    :hover{
      opacity: 0.7;
    }
  }
  @media (max-width: 767px){
    width: 100%;
    margin: 15px 0 0 0;
  }
`;

const StakeSummaryCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1199px){
    flex-direction: column;
  }
`;

const TableTitle = styled.div`
  z-index: 1;
  ${({ withHowto }) => withHowto && `
    display: flex;
    align-items: center;
  `}
  & > h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;        
    color: ${props => props.color};
  }
  margin: ${props => props.summary ? "33px 0 29px 0" : ""};
  ${({ stake }) => stake && `
    margin: 40px 0;
  `}
  ${({ stakeTitle }) => stakeTitle && `
    margin: 25px 0 20px 0;
  `}
`;

const HowToLink = styled.a`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 10.9635px;
  text-align: center;
  letter-spacing: 0.05em;
  border: ${props => props.border};
  box-shadow: ${props => props.shadow};
  text-transform: uppercase;
  color: ${props => props.color};
  background: ${props => props.background};
  border-radius: 110.967px;
  padding: 11px 22px;
  margin-left: 20px;
  transition: 200ms;
  :hover{
    color: ${props => props.color}; 
    opacity: 0.7;
  }
  @media (max-width: 992px){
    padding: 6px 20px;
    font-size: 8px;
  }
`;

const Countdown = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  h2{
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    span{
      font-weight: 500;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 3px;
    }
  }
  ${({ modal, textColor }) => modal && `
    width: 50%;
    @media (max-width: 663px){
      width: 100%;
    }
    h2{
      color: ${textColor};
      span{
        color: #898FA4;
      }
    }
  `}
`;

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px auto;
  & > img{
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  & > h2{
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    color: ${props => props.color};
    margin: 61px auto 41px auto;
  }
  & > a{
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 14.9246px;
    text-align: center;
    color: #FFFFFF;
    width: 173px;
    height: 48px;
    background: #4441CF;
    border-radius: 45.922px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    :hover{
      opacity: 0.7;
    }
  }
`;

export {
  NavbarLinks,
  NavbarWrapper,
  NavbarContainer,
  NavBarMobile,
  NavBarMobileContent,
  MobileNavbarIconWrapper,
  NavbarIconWrapper,
  NavbarIconContent,
  RatesWrapper,
  RatesBoxWrapper,
  NavbarSpan,
  WalletBtn,
  WalletBtnIcon,
  WalletBtnText,
  NavBarRightWrapper,
  StakeHeaderWrapper,
  StakeSummaryCard,
  WithdrawStakeCard,
  StackSummaryCol,
  WithdrawStakeCardText,
  WithdrawStakeCardBtns,
  StakeSummaryCardWrapper,
  TableTitle,
  HowToLink,
  Countdown,
  NotFoundWrapper
};
