import { Key, Agree, DaiLogo, ETH as ETHicon, TrancheClaim, DaiClaim, EthClaim, AAVE, CompoundLogo, USDCCard, USDTCard, DAICARD, MaticTable, TrancheStake, 
  ETHCARD, Chainlink, WBTC, YearnIcon, FantomIcon, MIMIcon, YFIIcon, TAAVAX, TBAVAX, TABTC, TBBTC, TAVAX, TWETH, TBTC, TAWETH, TBWETH, TADAI, TBDAI, TAUSDT, TBUSDT, TAUSDC, TBUSDC, TAAAVE, TBAAVE, TAUSDTCompound, TBUSDTCompound, TAFTMFantom, TBFTMFantom, TAUSDCFantom, TBUSDCFantom, TADAIFantom, TBDAIFantom, TAMIMFantom, TAYFIFantom, TBYFIFantom, TBMATIC, TADAIPolygon, TBDAIIPolygon, TAUSDCIPolygon, TBUSDCIPolygon, TBDAIPolygon, TAUSDCPolygon, TBUSDCPolygon, TAMATIC, TADAICompound, TBDAICompound, TAUSDCCompound, TBUSDCCompound, TABTCCompound, TBBTCCompound, TALINKCompound, TBLINKCompound, TBMIMFantom, BenqiTokenIcon, TADAIBenqi, TBDAIBenqi, TABTCBenqi, TBBTCBenqi, TAAVAXBenqi, TBAVAXBenqi, TAUSDTBenqi, TBUSDTBenqi, TAUSDCBenqi, TBUSDCBenqi, TALINKBenqi, TABENQIBenqi, TBLINKBenqi, TBBENQIBenqi, TAWETHBenqi, TBWETHBenqi, BenqiIcon, CompoundIconTable, AAVEIconTable, YearnIconTable, MarketsCompound, PolygonLogo, FANTOMLOGOLIGHT, ETHnetwork, AVAX, DefaultIcon, TABUSD, TBBUSD, TASUSD, TBSUSD, SUSD, BUSD, TAWETHAAVE, TBWETHAAVE, TADAIAAVE, TBDAIAAVE, TBUSDCAAVE, TAUSDTAAVE, TAUSDCAAVE, TBUSDTAAVE, TALINKAAVE, TBLINKAAVE, TAWBTCAAVE, TBWBTCAAVE, AAVEIconTableLight, BenqiIconLight, CompoundIconTableLight, YearnIconTableLight, FEI, TAGUSD, TBGUSD, GUSD, CRV, TACRV, TBCREAM, TBCRV, TAFEI, TBFEI, BAL, TABAL, TBBAL, TUSD, TATUSD, TBTUSD } from 'assets';
import { DAISetup } from 'utils/contractConstructor';
import { abi as JCompound } from 'build/contracts/JCompound.json';
import { abi as CToken } from 'build/contracts/CToken.json';

// exporting .env variables
export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const landingUrl = process.env.REACT_APP_LANDING_URL;

// Network Id
export const networkId = parseInt(process.env.REACT_APP_NETWORK_ID);
export const maticNetworkId = 137;
export const fantomNetworkId = 250;
export const avalancheNetworkId = +process.env.REACT_APP_AVALANCHE_CHAIN_ID;

// Block Explorer Url 
export const etherScanUrl = process.env.REACT_APP_ETHERSCAN_URL;
export const maticBlockExplorerUrl = process.env.REACT_APP_MATIC_BLOCK_EXPLORER_URL;
export const fantomBlockExplorerUrl = process.env.REACT_APP_FANTOM_BLOCK_EXPLORER_URL;
export const avalancheBlockExplorerUrl = process.env.REACT_APP_AVALANCHE_BLOCK_EXPLORER_URL;

export const blocknativeKey = process.env.REACT_APP_BLOCKNATIVE_KEY;
export const infuraKey = process.env.REACT_APP_INFURA_KEY;

// HTTP URL
export const alchemyHttpUrl = process.env.REACT_APP_ALCHEMY_HTTP_URL;
export const maticHttpUrl = process.env.REACT_APP_MATIC_HTTP_URL;
export const fantomHttpUrl = process.env.REACT_APP_FANTOM_HTTP_URL;
export const avalancheHttpUrl = process.env.REACT_APP_AVALANCHE_HTTP_URL;

export const avalancheRpcUrl = process.env.REACT_APP_AVALANCHE_RPC_URL

// Sockets Url
export const alchemyWebSocketsUrl = process.env.REACT_APP_ALCHEMY_WEBSOCKETS_URL;
export const maticWebSocketsUrl = process.env.REACT_APP_MATIC_WEBSOCKETS_URL;
export const fantomWebSocketsUrl = process.env.REACT_APP_FANTOM_WEBSOCKETS_URL;
export const infuraWebSocketsUrl = process.env.REACT_APP_INFURA_WEBSOCKETS_URL;
export const avalancheWebSocketsUrl = process.env.REACT_APP_AVALANCHE_WEBSOCKETS_URL;

export const PriceOracleAddress = process.env.REACT_APP_PRICE_ORACLE;
export const LoanContractAddress = process.env.REACT_APP_LOAN_ADDRESS;
// Ethereum Compound Tranches
export const JCompoundAddress = process.env.REACT_APP_COMPOUND_TRANCHE_ADDRESS.toLowerCase();
export const TrancheBuyerCoinAddresses = process.env.REACT_APP_BUYER_COIN_ADDRESS.split(',');
export const CompTrancheTokens = process.env.REACT_APP_COMP_TRANCHE_TOKENS.split(',');

// Ethereum Aave Tranches
export const JEthAaveAddress = process.env.REACT_APP_ETH_AAVE_TRANCHE_ADDRESS.toLowerCase();
export const EthAaveBuyerCoinAddresses = process.env.REACT_APP_ETH_AAVE_BUYER_COIN_ADDRESS.split(',');
export const EthAaveTrancheTokens = process.env.REACT_APP_ETH_AAVE_TRANCHE_TOKENS.split(',');

// Polygon Tranches
export const JAaveAddress = process.env.REACT_APP_AAVE_TRANCHE_ADDRESS.toLowerCase();
export const PolygonBuyerCoinAddresses = process.env.REACT_APP_MATIC_BUYER_COIN_ADDRESS.split(',');
export const AaveTrancheTokens = process.env.REACT_APP_AAVE_TRANCHE_TOKENS.split(',');
// Fantom Tranches
export const JYearnAddress = process.env.REACT_APP_YEARN_TRANCHE_ADDRESS.toLowerCase();
export const FantomBuyerCoinAddresses = process.env.REACT_APP_FANTOM_BUYER_COIN_ADDRESS.split(',');
export const YearnTrancheTokens = process.env.REACT_APP_YEARN_TRANCHE_TOKENS.split(',');

// Aave Avalanche Tranches
export const JAvalancheAddress = process.env.REACT_APP_AVALANCHE_TRANCHE_ADDRESS.toLowerCase();
export const AvalancheBuyerCoinAddresses = process.env.REACT_APP_AVALANCHE_BUYER_COIN_ADDRESS.split(',');
export const AvalancheTrancheTokens = process.env.REACT_APP_AVALANCHE_TRANCHE_TOKENS.split(',');


// Benqi Avalanche Tranches
export const JBenqiAddress = process.env.REACT_APP_BENQI_TRANCHE_ADDRESS.toLowerCase();
export const BenqiBuyerCoinAddresses = process.env.REACT_APP_BENQI_BUYER_COIN_ADDRESS.split(',');
export const BenqiTrancheTokens = process.env.REACT_APP_BENQI_TRANCHE_TOKENS.split(',');

// Staking
export const StakingAddresses = process.env.REACT_APP_STAKING_ADDRESS.split(',');
export const LockupAddress = process.env.REACT_APP_STAKING_LOCKUP_ADDRESS;
export const YieldAddresses = process.env.REACT_APP_STAKING_YIELD_ADDRESS.split(',');
export const lockupDurations = process.env.REACT_APP_STAKING_LOCKUP_DURATION.split(',');
export const lockupStakingOccurrences = process.env.REACT_APP_STAKING_LOCKUP_OCCURRENCES.split(',').map(o => +o);
export const epochDuration = process.env.REACT_APP_EPOCH_DURATION;
// Governance
export const SliceGovernorAddress = process.env.REACT_APP_SLICE_GOVERNOR_ADDRESS.toLowerCase();
//Google Analytics
export const GoogleAnalyticsTrackingID = 'UA-197572899-1';
export const GTMID = 'GTM-NFDMGZN';
// Token Addresses
export const zeroAddress = '0x0000000000000000000000000000000000000000';
export const maticAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const WFTMAddress = process.env.REACT_APP_FANTOM_WFTM_ADDRESS;
export const ERC20Tokens = process.env.REACT_APP_ERC20_TOKENS.split(',');
export const DAIAddress = process.env.REACT_APP_DAI_ADDRESS.toLowerCase();
export const SLICEAddress = process.env.REACT_APP_SLICE_ADDRESS.toLowerCase();
export const USDCAddress = process.env.REACT_APP_USDC_ADDRESS.toLowerCase();
export const LP1TokenAddress = process.env.REACT_APP_SLICE_LP1_ADDRESS.toLowerCase();
export const LP2TokenAddress = process.env.REACT_APP_SLICE_LP2_ADDRESS.toLowerCase();
export const RewardDistributionAddress = process.env.REACT_APP_SIR_ADDRESS.toLowerCase();
export const ApproveBigNumber = '100000000000000';
export const tokenDecimals = [
  { key: 'USDC', decimals: 6 },
  { key: 'USDT', decimals: 6 },
  { key: 'WBTC', decimals: 8 }
];
export const ETHorMaticCheck = ['ETH', 'MATIC', 'AVAX'];
export const gweiVariants = ['Gwei', 'nSLICE', 'nDAI', 'nUSDC'];
export const TRIPContracts = [
  { key: 'JCompound', abi: JCompound },
  { key: 'CToken', abi: CToken }
]

// Site Banner Data (imported in Header component)
export const PagesData = {
  home: {
    color: '#2c2cdf',
    title: 'Welcome to Jibrel',
    description: 'PLACEHOLDER SLOGAN'
  },
  borrow: {
    pageType: 'borrow',
    color: '#5411e2',
    secondaryColor: '#CEB7FF',
    btnColor: '#EEE5FF',
    cardColor: '#DFD2FB',
    title: 'Borrower Markets',
    description: 'APPLY FOR A COLLATERALIZED LOAN USING YOUR CRYPTOCURRENCY',
    userTag: {
      color: '#5411e2',
      img: Key
    }
  },
  lend: {
    pageType: 'lend',
    color: '#1ebb1b',
    secondaryColor: '#D7FFB7',
    btnColor: '#D7FCD6',
    cardColor: '#D7FFB7',
    title: 'Lending Markets',
    description: 'LEND STABLE COINS TO RECEIVE CRYPTOCURRENCY RETURNS',
    userTag: {
      color: '#1ebb1b',
      img: Agree
    }
  },
  tranche: {
    pageType: 'tranche',
    color: 'rgba(0, 0, 0, 0.8)',
    secondaryColor: '#ffffff',
    btnColor: '#4441CF',
    cardColor: 'rgba(0, 0, 0, 0.8)',
    title: 'Earning Markets',
    description: 'DEPOSIT STABLE COINS FOR DIFFERENT RETURNS'
  },
  stake: {
    pageType: 'stake',
    color: '#0071F5',
    secondaryColor: '#ffffff',
    btnColor: '#BCD9FB',
    cardColor: '#6EAEFA',
    title: 'Staking Pools',
    description: 'stake cryptocurrency for slice rewards'
  },
  privacy: {
    pageType: 'privacy',
    color: '#006173',
    secondaryColor: '#7EABC8',
    btnColor: '#EEE5FF',
    cardColor: '#DFD2FB',
    title: 'Privacy Policy',
    description: ''
  },
  terms: {
    pageType: 'terms-and-conditions',
    color: '#006173',
    secondaryColor: '#7EABC8',
    btnColor: '#EEE5FF',
    cardColor: '#DFD2FB',
    title: 'Terms and Conditions',
    description: ''
  }
};

export const trancheIcons = {
  acDAI: { protocolIcon: CompoundLogo, assetIcon: DAICARD, tokenIcon: TADAICompound, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound' },
  bcDAI: { protocolIcon: CompoundLogo, assetIcon: DAICARD, tokenIcon: TBDAICompound, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound' },
  acTUSD: { protocolIcon: CompoundLogo, assetIcon: TUSD, tokenIcon: TATUSD, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound' },
  bcTUSD: { protocolIcon: CompoundLogo, assetIcon: TUSD, tokenIcon: TBTUSD, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound' },
  acUSDC: { protocolIcon: CompoundLogo, assetIcon: USDCCard, tokenIcon: TAUSDCCompound, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound' },
  bcUSDC: { protocolIcon: CompoundLogo, assetIcon: USDCCard, tokenIcon: TBUSDCCompound, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound' },
  acUSDT: { protocolIcon: CompoundLogo, assetIcon: USDTCard, tokenIcon: TAUSDTCompound, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound'  },
  bcUSDT: { protocolIcon: CompoundLogo, assetIcon: USDTCard, tokenIcon: TBUSDTCompound, protocolIcon: CompoundIconTable, protocolIconLight: CompoundIconTableLight, protocolName: 'Compound' },
  acWBTC: { protocolIcon: CompoundLogo, assetIcon: WBTC, tokenIcon: TABTCCompound, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE'  },
  bcWBTC: { protocolIcon: CompoundLogo, assetIcon: WBTC, tokenIcon: TBBTCCompound, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  acLINK: { protocolIcon: CompoundLogo, assetIcon: Chainlink, tokenIcon: TALINKCompound, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bcLINK: { protocolIcon: CompoundLogo, assetIcon: Chainlink, tokenIcon: TBLINKCompound, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aamMATIC: { protocolIcon: AAVE, assetIcon: MaticTable, tokenIcon: TAMATIC, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bamMATIC: { protocolIcon: AAVE, assetIcon: MaticTable, tokenIcon: TBMATIC, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aamDAI: { protocolIcon: AAVE, assetIcon: DAICARD, tokenIcon: TADAIPolygon, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bamDAI: { protocolIcon: AAVE, assetIcon: DAICARD, tokenIcon: TBDAIPolygon, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aamUSDC: { protocolIcon: AAVE, assetIcon: USDCCard, tokenIcon: TAUSDCPolygon, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bamUSDC: { protocolIcon: AAVE, assetIcon: USDCCard, tokenIcon: TBUSDCPolygon, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  ayfWFTM: { protocolIcon: YearnIcon, assetIcon: FantomIcon, tokenIcon: TAFTMFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  byfWFTM: { protocolIcon: YearnIcon, assetIcon: FantomIcon, tokenIcon: TBFTMFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  ayfUSDC: { protocolIcon: YearnIcon, assetIcon: USDCCard, tokenIcon: TAUSDCFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  byfUSDC: { protocolIcon: YearnIcon, assetIcon: USDCCard, tokenIcon: TBUSDCFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  ayvWFTM: { protocolIcon: YearnIcon, assetIcon: FantomIcon, tokenIcon: TAFTMFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  byvWFTM: { protocolIcon: YearnIcon, assetIcon: FantomIcon, tokenIcon: TBFTMFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  ayvUSDC: { protocolIcon: YearnIcon, assetIcon: USDCCard, tokenIcon: TAUSDCFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  byvUSDC: { protocolIcon: YearnIcon, assetIcon: USDCCard, tokenIcon: TBUSDCFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn'},
  ayvDAI: { protocolIcon: YearnIcon, assetIcon: DAICARD, tokenIcon: TADAIFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  byvDAI: { protocolIcon: YearnIcon, assetIcon: DAICARD, tokenIcon: TBDAIFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  ayvMIM: { protocolIcon: YearnIcon, assetIcon: MIMIcon, tokenIcon: TAMIMFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  byvMIM: { protocolIcon: YearnIcon, assetIcon: MIMIcon, tokenIcon: TBMIMFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  ayvYFI: { protocolIcon: YearnIcon, assetIcon: YFIIcon, tokenIcon: TAYFIFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  byvYFI: { protocolIcon: YearnIcon, assetIcon: YFIIcon, tokenIcon: TBYFIFantom, protocolIcon: YearnIconTable, protocolIconLight: YearnIconTableLight, protocolName: 'Yearn' },
  aavWAVAX: { protocolIcon: AAVE, assetIcon: TAVAX, tokenIcon: TAAVAX, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bavWAVAX: { protocolIcon: AAVE, assetIcon: TAVAX, tokenIcon: TBAVAX, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aavWETH: { protocolIcon: AAVE, assetIcon: TWETH, tokenIcon: TAWETH, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bavWETH: { protocolIcon: AAVE, assetIcon: TWETH, tokenIcon: TAWETH, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aavWBTC: { protocolIcon: AAVE, assetIcon: TBTC, tokenIcon: TABTC, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bavWBTC: { protocolIcon: AAVE, assetIcon: TBTC, tokenIcon: TBBTC, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aavDAI: { protocolIcon: AAVE, assetIcon: DAICARD, tokenIcon: TADAI, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bavDAI: { protocolIcon: AAVE, assetIcon: DAICARD, tokenIcon: TBDAI, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aavUSDT: { protocolIcon: AAVE, assetIcon: USDTCard, tokenIcon: TAUSDT, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bavUSDT: { protocolIcon: AAVE, assetIcon: USDTCard, tokenIcon: TBUSDT, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aavUSDC: { protocolIcon: AAVE, assetIcon: USDCCard, tokenIcon: TAUSDC, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bavUSDC: { protocolIcon: AAVE, assetIcon: USDCCard, tokenIcon: TBUSDC, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aavAAVE: { protocolIcon: AAVE, assetIcon: AAVE , tokenIcon: TAAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  bavAAVE:{ protocolIcon: AAVE, assetIcon: AAVE, tokenIcon: TBAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aqiDAI:{ protocolIcon: BenqiTokenIcon, assetIcon: DAICARD, tokenIcon: TADAIBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiDAI:{ protocolIcon: BenqiTokenIcon, assetIcon: DAICARD, tokenIcon: TBDAIBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aqiWBTC:{ protocolIcon: BenqiTokenIcon, assetIcon: TBTC, tokenIcon: TABTCBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiWBTC:{ protocolIcon: BenqiTokenIcon, assetIcon: TBTC, tokenIcon: TBBTCBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aqiAVAX:{ protocolIcon: BenqiTokenIcon, assetIcon: TAVAX, tokenIcon: TAAVAXBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiAVAX:{ protocolIcon: BenqiTokenIcon, assetIcon: TAVAX, tokenIcon: TBAVAXBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aqiWETH:{ protocolIcon: BenqiTokenIcon, assetIcon: TWETH, tokenIcon: TAWETHBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiWETH:{ protocolIcon: BenqiTokenIcon, assetIcon: TWETH, tokenIcon: TBWETHBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aqiUSDT:{ protocolIcon: BenqiTokenIcon, assetIcon: USDTCard, tokenIcon: TAUSDTBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiUSDT:{ protocolIcon: BenqiTokenIcon, assetIcon: USDTCard, tokenIcon: TBUSDTBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aqiUSDC:{ protocolIcon: BenqiTokenIcon, assetIcon: USDCCard, tokenIcon: TAUSDCBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiUSDC:{ protocolIcon: BenqiTokenIcon, assetIcon: USDCCard, tokenIcon: TBUSDCBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aqiLINK:{ protocolIcon: BenqiTokenIcon, assetIcon: Chainlink, tokenIcon: TALINKBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiLINK:{ protocolIcon: BenqiTokenIcon, assetIcon: Chainlink, tokenIcon: TBLINKBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aqiQI:{ protocolIcon: BenqiTokenIcon, assetIcon: BenqiTokenIcon, tokenIcon: TABENQIBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  bqiQI:{ protocolIcon: BenqiTokenIcon, assetIcon: BenqiTokenIcon, tokenIcon: TBBENQIBenqi, protocolIcon: BenqiIcon, protocolIconLight: BenqiIconLight, protocolName: 'Benqi' },
  aaWETH: { protocolIcon: AAVE, assetIcon: TWETH, tokenIcon: TAWETHAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baWETH: { protocolIcon: AAVE, assetIcon: TWETH, tokenIcon: TBWETHAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaDAI: { protocolIcon: AAVE, assetIcon: DAICARD, tokenIcon: TADAIAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baDAI: { protocolIcon: AAVE, assetIcon: DAICARD, tokenIcon: TBDAIAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaSUSD: { protocolIcon: AAVE, assetIcon: SUSD, tokenIcon: TASUSD, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baSUSD: { protocolIcon: AAVE, assetIcon: SUSD, tokenIcon: TBSUSD, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaUSDC: { protocolIcon: AAVE, assetIcon: USDCCard, tokenIcon: TAUSDCAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baUSDC: { protocolIcon: AAVE, assetIcon: USDCCard, tokenIcon: TBUSDCAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaUSDT: { protocolIcon: AAVE, assetIcon: USDTCard, tokenIcon: TAUSDTAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baUSDT: { protocolIcon: AAVE, assetIcon: USDTCard, tokenIcon: TBUSDTAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaLINK: { protocolIcon: AAVE, assetIcon: Chainlink, tokenIcon: TALINKAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baLINK: { protocolIcon: AAVE, assetIcon: Chainlink, tokenIcon: TBLINKAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaWBTC: { protocolIcon: AAVE, assetIcon: TBTC, tokenIcon: TAWBTCAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baWBTC: { protocolIcon: AAVE, assetIcon: TBTC, tokenIcon: TBWBTCAAVE, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaBUSD: { protocolIcon: AAVE, assetIcon: BUSD, tokenIcon: TABUSD, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baBUSD: { protocolIcon: AAVE, assetIcon: BUSD, tokenIcon: TBBUSD, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaFEI: { protocolIcon: AAVE, assetIcon: FEI, tokenIcon: TAFEI, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baFEI: { protocolIcon: AAVE, assetIcon: FEI, tokenIcon: TBFEI, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaGUSD: { protocolIcon: AAVE, assetIcon: GUSD, tokenIcon: TAGUSD, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baGUSD: { protocolIcon: AAVE, assetIcon: GUSD, tokenIcon: TBGUSD, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaCRV: { protocolIcon: AAVE, assetIcon: CRV, tokenIcon: TACRV, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baCRV: { protocolIcon: AAVE, assetIcon: CRV, tokenIcon: TBCRV, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  aaBAL: { protocolIcon: AAVE, assetIcon: BAL, tokenIcon: TABAL, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
  baBAL: { protocolIcon: AAVE, assetIcon: BAL, tokenIcon: TBBAL, protocolIcon: AAVEIconTable, protocolIconLight: AAVEIconTableLight, protocolName: 'AAVE' },
};
export const LiquidityIcons = {
  "SLICE": TrancheStake,
  "SLICE/ETH LP": ETHCARD,
  "SLICE/DAI LP": DAICARD
};

// pairData[0] is the default option in the loan creation process (value = pairId)
export const pairData = [
  {
    key: 'DAI',
    text: 'DAI',
    value: 0,
    collateral: 'ETH',
    img: DaiLogo,
    colIcon: ETHicon,
    lendTokenSetup: DAISetup
  }
];

export const apiUri = {
  exchangeRates: 'common/exchange-rates',
  priceFeed: 'pairs',
  loanList: 'loans',
  transaction: 'loans/transaction',
  summaryLoan: 'summary/loan',
  summaryRatio: 'summary/ratio',
  summaryCollateral: 'summary/collateral',
  stakingList: 'staking',
  stakingSummaryDetail: 'staking/detail/',
  stakingSummary: 'staking/summary/',
  sliceSummary: 'slice/summary',
  tranchesList: 'earn',
  totalValueLocked: 'earn/summary/total',
  graphUri: 'earn/graph/apy?',
  userStakingList: 'staking/list',
  proposalListing: 'governance/proposals',
};


// export const MAINNET_PARAMS = {
//   chainId: '0x1',
//   chainName: 'Ethereum Mainnet',
//   nativeCurrency: {
//       name: 'Ether',
//       symbol: 'ETH',
//       decimals: 18
//   },
//   // rpcUrls: ['https://polygon-rpc.com/'],
//   blockExplorerUrls: ['https://etherscan.io/']
// }

export const networkParams = {
  polygon: {
    chainId: `0x${Number(maticNetworkId).toString(16).toUpperCase()}`,
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: [maticBlockExplorerUrl]
  },
  fantom: {
    chainId: `0x${Number(fantomNetworkId).toString(16).toUpperCase()}`,
    chainName: 'Fantom Opera Mainnet',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18
    },
    rpcUrls: ['https://rpc.ftm.tools/'],
    blockExplorerUrls: [fantomBlockExplorerUrl]
  },
  avalanche: {
    chainId: `0x${Number(avalancheNetworkId).toString(16).toUpperCase()}`,
    chainName: avalancheNetworkId === 43113 ? 'Avalanche Fuji Testnet': 'Avalanche Mainnet',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: [avalancheRpcUrl],
    blockExplorerUrls: [avalancheBlockExplorerUrl]
  }
}
export const networks = {
	[networkId]: {
		network: 'Ethereum',
		name: 'Ethereum',
    img: ETHnetwork,
		markets: [
			{
				name: 'Compound',
				img: MarketsCompound,
				contractAddress: JCompoundAddress,
			},
      {
        name: 'AAVE',
        img: AAVE,
        contractAddress: JEthAaveAddress
      },
		],
	},
	[maticNetworkId]: {
		network: 'Polygon',
		name: 'Polygon',
    img: PolygonLogo,
		markets: [{ name: 'AAVE', img: AAVE, contractAddress: JAaveAddress }],
	},
	[fantomNetworkId]: {
		network: 'Fantom',
		name: 'ftm',
    img: FANTOMLOGOLIGHT,
		markets: [
			{ name: 'Yearn', img: YearnIcon, contractAddress: JYearnAddress },
		],
	},
	[avalancheNetworkId]: {
		network: 'Avalanche',
		name: 'avax',
    img: AVAX,
		markets: [
			{ name: 'AAVE', img: AAVE, contractAddress: JAvalancheAddress },
			{ name: 'BENQI', img: BenqiTokenIcon, contractAddress: JBenqiAddress },
		],
	},
};
// Filters
export const ETH = 'ETH';
export const DAI = 'DAI';
export const SLICE = 'SLICE';
export const USDC = 'USDC';

export const blocksPerYear = 2372500;
export const factoryFees = 5;

export const generalParams = {
  limitCollRatioForWithdraw: 160
};

export const pairLogos = {
  SLICE: TrancheClaim,
  'SLICE/ETH LP': EthClaim,
  'SLICE/DAI LP': DaiClaim
};

export const actionTypes = {
  Cancel: {
    name: 'Cancel',
    confirmationText: 'Are you sure you want to cancel the loan request?'
  },
  Close: {
    name: 'Close',
    confirmationText: 'Are you sure you want to close the loan?'
  },
  Approve: {
    name: 'Approve',
    confirmationText: 'Are you sure you want to accept this loan?'
  },
  WithdrawInterest: {
    name: 'WithdrawInterest',
    confirmationText: 'Are you sure you want to withdraw interest?'
  },
  Foreclose: {
    name: 'Foreclose',
    confirmationText: 'Are you sure you want to foreclose this loan?'
  }
};

export const events = {
  COLLATERAL_RECEIVED: 'collateralreceived',
  LOAN_CREATED: 'loancreated',
  NEW_LOAN_STATUS: 'loanstatuschanged',
  APPROVE_LOAN: 'lenderaccepted',
  LOAN_CLOSING_BORROWER: 'loanclosingbyborrower',
  FORECLOSING: 'loanforeclosing',
  FORECLOSED: 'loanforeclosed',
  LOAN_CANCEL: 'loancancelled',
  INTEREST_WITHDRAWN: 'interestswithdrawed',
  REMOVE_COLLATERAL: 'withdrawcollateral',
  APPROVE_LOAN_FEES: 'approve_loan_fees',
  INITIATE_FORECLOSE_FEES: 'initiate_foreclose_fees',
  FORECLOSED_FEES: 'foreclosed_fees',
  EARLY_CLOSING_FEES: 'early_closing_fees',
  LOAN_CANCEL_FEES: 'loan_cancel_fees',
  INITIATE_FORECLOSE_REWARD: 'initiate_foreclose_reward',
  FORECLOSED_REWARD: 'foreclosed_reward',
  TRANCHE_ADDED: 'soldtoprotocol'
};

export const statuses = {
  Pending: {
    key: 'Pending',
    status: 0,
    color: '#936CE6',
    background: 'rgba(147, 108, 230, 0.24)'
  },
  Active: {
    key: 'Active',
    status: 1,
    color: '#2ECC71',
    background: '#DDFFEB'
  },
  Under_Collateralized: {
    key: 'Under Collateralized',
    status: 2,
    color: '#F2C94C',
    background: 'rgba(242, 201, 76, 0.24)'
  },
  At_Risk: {
    key: 'At Risk',
    status: 3,
    color: '#E67E22',
    background: 'rgba(230, 126, 34, 0.24)'
  },
  Foreclosing: {
    key: 'Foreclosing',
    status: 4,
    color: '#FF5D47',
    background: 'rgba(255, 93, 71, 0.24)'
  },
  Foreclosed: {
    key: 'Foreclosed',
    status: 5,
    color: '#C0392B',
    background: 'rgba(192, 57, 43, 0.24)'
  },
  Early_closing: {
    key: 'Early closing',
    status: 6,
    color: '#41A1FF',
    background: 'rgba(65, 161, 255, 0.24)'
  },
  Closing: {
    key: 'Closing',
    status: 7,
    color: '#0A66E1',
    background: 'rgba(10, 102, 225, 0.24)'
  },
  Closed: {
    key: 'Closed',
    status: 8,
    color: '#234566',
    background: 'rgba(35, 69, 102, 0.24)'
  },
  Cancelled: {
    key: 'Cancelled',
    status: 9,
    color: '#E70D52',
    background: 'rgba(231, 13, 82, 0.24)'
  }
};
export const ModeThemes = {
  light: {
    body: '#F9F9FE',
    navlinkTab: 'rgb(68, 65, 207)',
    HeaderTitle: '#393F56',
    HeaderSubtitle: '#9496B6',
    TableHead: 'rgba(36, 39, 50, 0.6)',
    SearchBarColor: "#393F56",
    InputBarColor: "#393F56",
    TableCard: '#FFFFFF',
    TableCardBorderColor: '#F0F0F7',
    footerBackground: '#F6F6FC',
    TableFilterTextColor: "#4441CF",
    TableFilterBorder: "#E9E9FC",
    TableFilterBackground: "#FFFFFF",
    TableFilterBorderActive: "#4441CF",
    TableFilterBackgroundActive: "rgba(68, 65, 207, 0.05)",
    footerLinks: '#AAA8E9',
    languageToggleBackground: 'rgba(68,65,207,0.1)',
    languageToggleText: '#4441CF',
    AddButtonText: '#4441CF',
    TableMoreBtnColor: "#ECECFA",
    TableMoreBtnTextColor: "#4441CF",
    activeStatus: '#DDFFEB',
    activeStatusText: '#2ECC71',
    DisabledBtn: "#BCBEC1",
    DisabledBtnText: "#666666",
    tableText: '#39295A',
    tableCardShadow: '0px 4px 4px rgb(189 189 189 / 7%)',
    dropDownBorder: '#F9F9FB',
    dropDownText: '#39295A',
    inputBackground: 'rgb(255, 255, 255)',
    inputDisabledBackground: 'rgba(207,207,229,0.2)',
    borderColor: '#EFEFEF',
    backgroundBorder: '#CCCCCD',
    titleSectionText: 'rgba(124, 133, 155, 0.8)',
    valueSectionText: '#393F56',
    titleColor: '#393F56',
    textColor: '#7C859B',
    ModalBackground: '#FFFFFF',
    TrancheRateTypeColor: "rgba(106, 103, 255, 0.9)",
    ModalText: '#4F4F4F',
    SelectedStaking: 'rgba(68, 65, 207, 0.05)',
    SelectedStakingText: '#39295A',
    SelectedStakingLink: '#776E8B',
    TableCardColor: '#FFFFFF',
    TableColor: '#FFFFFF',
    CardOpen :'#F9F9FE',
    TableBorder: '#E9E9FC',
    borderInputColor: '#EAEAEA',
    StakingInputText: '#838186',
    loadingSpinner: 'rgba(124,133,155,0.8)',
    Tooltip: '#2F2F2F',
    tooltipColor: "#FFFFFF",
    NavbarBorder: '#E9E9FC',
    NavbarBackground: '#FFFFFF',
    NavbarShadow: '0px 3.49091px 3.49091px rgba(189, 189, 189, 0.07)',
    TrancheMarketsTitle: '#393F56',
    TrancheModalLinkColor: '#4939D7',
    TrancheModalLinkBackground: '#E7E7F9',
    BorderStake: "#CFCFE5",
    TrancheBtnBackground: '#FFFFFF',
    TrancheBtnBackgroundCurrent: 'rgba(255, 255, 255, 0.5);',
    TrancheBtnBorder: '#E9E9FC',
    TrancheBtnColor: '#000000',
    inputText: "#838186",
    BoxColorText: "#393F56",
    EstimatedColor: "#000000",
    TableMoreBtnColor: "#ECECFA",
    TableMoreBtnTextColor: "#4441CF",
    boxText: "#776E8B",
    BoxColor: "rgba(68, 65, 207, 0.05)",
    TableHeadText: "rgba(79, 79, 79, 0.6)",
    TrancheBtnSpan: '#E9E9FC',
    ClaimHead: 'rgba(36, 39, 50, 0.6)',
    StakingMax: 'rgba(57,41,90,0.3)',
    StakeModalNavigationText: "#4441CF",
    HowTo: '#FFFFFF',
    HowToText: '#4441CF',
    HowToBorder: '0.872727px solid #E9E9FC',
    HowToShadow: '0px 3.49091px 3.49091px rgba(189, 189, 189, 0.07)',
    StakeModalNavigationBorder: "#E9E9FC",
    stakeModalBoxShadow: "0px 2.14578px 2.14578px rgba(189, 189, 189, 0.07)",
    stakeModalBorderCurrent: "0.536446px solid #E9E9FC",
    stakeModalBoxBackground: "#F9F9FE",
    stakeBoxBackground: "#FFFFFF",
    MigrateInput: "#F5F0FF",
    LoadingColorOne: '#eee',
    LoadingColorTwo: '#f7f7f7',
    docsLockupText: "#4441CF",
    docsLockupBackground: "rgba(68, 65, 207, 0.1)",
    cardShadow: '0 -1px 12px 0 rgb(0 0 0 / 10%), 0 0 12px 0 transparent',
    btnShadow: '0px 4px 4px rgb(189 189 189 / 30%)',
    MigrateProgressTextActive: "#39295A",
    MigrateProgressTextPending: "#9F9DE6",
    MigrateProgressLine: "#D4D4D4",
    MigrateStepBorder: "rgba(0, 0, 0, 0.2)",
    disabledBtnColor: "rgba(204,204,205,1)",
    MigrateStepTextPending: "rgba(0, 0, 0, 0.5)",
    MigrateStepText: "#FFFFFF",
    ModalTrancheTextColor: "#393F56",
    MigrateStepBackground: "#FFFFFF",
    MigrateContentTitle: "#393F56",
    ActionCardColor: "#E9E9FC",
    MigrateClaimCardBackground: "rgba(138, 94, 234, 0.05)",
    MigrateClaimCardTitle: "rgba(124, 133, 155, 0.8)",
    ModalTrancheNavbarBtnBorder: "0.872727px solid #E9E9FC",
    ModalTrancheNavbarBtn: "#FFFFFF",
    MigrateClaimCardValue: "#393F56",
    ModalTrancheNavbarBtnText: "#393F56",
    TableMoreColor: "#FFFFFF",
    TrancheRateFixedColor: "#6A67FF",
    TrancheRateVariableColor: "#764DE8",
    CircularBarBorder: "#E9E9FC",
    ExectutedColor: "#2BB063",
    TableInputBorder: "rgba(57, 63, 86, 0.16)",
    GovernanceTableFiltersMobileBtn: "#ECECFA",
    MenuDropdownBackground: "#ECECFA",
    ModalTrancheTextRowBorder: "#F0F0F6",
    ModalTrancheNavbarBtnShadow: "0px 3.49091px 3.49091px rgba(189, 189, 189, 0.07)",
    CongratsText: "rgba(57, 63, 86, 0.8)"
  },
  dark: {
    body: '#100F36',
    navlinkTab: '#FFFFFF',
    ModalTrancheNavbarBtn: "rgba(175, 155, 255, 0.15)",
    ModalTrancheNavbarBtnShadow: "",
    ModalTrancheTextRowBorder: "rgba(249, 249, 251, 0.1)",
    ModalTrancheNavbarBtnBorder: "",
    TrancheRateFixedColor: "#6A67FF",
    CircularBarBorder: "rgba(255, 255, 255, 0.1)",
    ExectutedColor: "#30BAC6",
    SearchBarColor: "#FFFFFF",
    GovernanceTableFiltersMobileBtn: "#292661",
    TableInputBorder: "rgba(255, 255, 255, 0.16)",  
    MenuDropdownBackground: "#292661",
    InputBarColor: "#FFFFFF",
    ActionCardColor: "#3A3162",
    TrancheRateVariableColor: "#764DE8",
    TableCardColor: '#221F46',
    TableColor: '#221F46',
    CardOpen :'#191735',
    TableBorder: 'rgba(255, 255, 255, 0.1)',
    ModalTrancheTextColor: "#FFFFFF",
    ModalTrancheNavbarBtnText: "#FFFFFF",
    TableMoreBtnColor: "#292661",
    TableMoreBtnTextColor: "#A09EFF",
    TableMoreColor: "#191640",
    HeaderTitle: '#FFFFFF',
    StakeModalNavigationText: "#C2C4DA",
    TableMoreBtnColor: "#292661",
    TableMoreBtnTextColor: "#A09EFF",
    docsLockupText: "#FFFFFF",
    disabledBtnColor: "rgba(204,204,205,0.15)",
    docsLockupBackground: "rgba(105,103,156,0.4)",
    HeaderSubtitle: '#C2C4DA',
    TableHeadText: "rgba(255, 255, 255, 0.6)",
    stakeModalBoxShadow: "",
    stakeBoxBackground: "rgba(255, 255, 255, 0.05)",
    tooltipColor: "#2A265B",
    stakeModalBorderCurrent: "",
    stakeModalBoxBackground: "rgba(175, 155, 255, 0.15)",
    BoxColor: "rgba(196, 149, 255, 0.15)",
    BoxColorText: "#CEC6FF",
    boxText: "#CEC6FF",
    MigrateInput: "rgba(255, 255, 255, 0.05)",
    TrancheRateTypeColor: "rgba(106, 103, 255, 0.4)",
    StakeModalNavigationBorder: "rgba(255, 255, 255, 0.1)",
    EstimatedColor: "rgba(255, 255, 255, 0.8)",
    inputText: "rgba(255, 255, 255, 0.8)",
    TableHead: 'rgba(255, 255, 255, 0.6)',
    TableCard: 'rgba(255, 255, 255, 0.07)',
    TableCardBorderColor: '#363661',
    footerBackground: '#07052F',
    TableFilterTextColor: "#A09EFF",
    TableFilterBorder: "rgba(255, 255, 255, 0.1)",
    TableFilterBackground: "#221F46",
    TableFilterBorderActive: "#4441CF",
    TableFilterBackgroundActive: "#292661",
    BorderStake: "rgba(255, 255, 255, 0.5)",
    footerLinks: 'rgba(255, 255, 255, 0.6)',
    languageToggleBackground: 'rgba(134, 132, 255, 0.25)',
    languageToggleText: '#FFFFFF',
    AddButtonText: '#DFD2FB',
    activeStatus: 'rgba(46, 204, 113, 0.9)',
    activeStatusText: '#FFFFFF',
    tableText: '#FFFFFF',
    tableCardShadow: '',
    dropDownBorder: '#363661',
    borderInputColor: 'rgba(255, 255, 255, 0.5)',
    dropDownText: '#FFFFFF',
    inputBackground: 'rgba(255, 255, 255, 0.5)',
    DisabledBtn: "rgba(204, 204, 205, 0.15)",
    DisabledBtnText: "#FFFFFF",
    inputDisabledBackground: 'transparent',
    borderColor: 'rgba(204, 204, 205, 0.15)',
    backgroundBorder: 'rgba(204, 204, 205, 0.15)',
    titleSectionText: 'rgba(255, 255, 255, 0.8);',
    valueSectionText: '#FFFFFF',
    titleColor: '#FFFFFF',
    textColor: '#C2C4DA',
    ModalBackground: '#221F46',
    ModalText: '#FFFFFF',
    SelectedStaking: 'rgba(151, 149, 255, 0.15)',
    SelectedStakingText: '#FFFFFF',
    SelectedStakingLink: '#CEC6FF',
    StakingInputText: 'rbga{255, 255, 255, 0.5}',
    loadingSpinner: 'rgba(255,255,255,0.3)',
    Tooltip: '#383765',
    NavbarBorder: 'rgba(255, 255, 255, 0.1)',
    NavbarBackground: 'rgba(175, 155, 255, 0.15)',
    NavbarShadow: 'none',
    TrancheMarketsTitle: '#FFFFFF',
    TrancheModalLinkColor: '#FFFFFF',
    TrancheModalLinkBackground: 'rgba(202, 197, 255, 0.13)',
    TrancheBtnBackground: '#1C1B40',
    TrancheBtnBackgroundCurrent: '#322E5D',
    TrancheBtnBorder: '#322E5D',
    TrancheBtnColor: '#FFFFFF',
    TrancheBtnSpan: '#363661',
    ClaimHead: 'rgba(255, 255, 255, 0.6)',
    StakingMax: 'rgb(57,41,90)',
    HowTo: 'rgba(175, 155, 255, 0.15)',
    HowToText: '#FFFFFF',
    HowToBorder: 'none',
    HowToShadow: 'none',
    LoadingColorOne: 'rgba(255,255,255,0.07)',
    LoadingColorTwo: '#363661',
    cardShadow: '0 -1px 12px 0 rgb(255 255 255 / 15%), 0 0 12px 0 transparent',
    btnShadow: '',
    MigrateProgressTextActive: "#FFFFFF",
    MigrateProgressTextPending: "rgba(255, 255, 255, 0.5)",
    MigrateProgressLine: "rgba(255, 255, 255, 0.5)",
    MigrateStepBorder: "rgba(255, 255, 255, 0.2)",
    MigrateStepTextPending: "rgba(255, 255, 255, 0.5)",
    MigrateStepText: "#FFFFFF",
    MigrateStepBackground: "#2A2254",
    MigrateContentTitle: "#FFFFFF",
    MigrateClaimCardBackground: "rgba(255, 255, 255, 0.04)",
    MigrateClaimCardTitle: "rgba(255,255,255,0.5)",
    MigrateClaimCardValue: "#FFFFFF",
    CongratsText: "rgba(255, 255, 255, 0.8)"
  }
};

export const PROPOSAL_STATUS = {
  ALL: 'ALL',
  REVIEW: 'REVIEW',
  ACTIVE: 'ACTIVE',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  QUEUED: 'QUEUED',
  EXECUTED: 'EXECUTED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED'
};


