import React from 'react';
import { Form, Field } from 'redux-form';
import {
  TableMoreRowContentRight,
  TableMoreRightSection,
  FormContent,
  TableMoreTitleWrapper,
  TableMoreTitle,
  BalanceLabel,
  MaxLabel,
} from '../../../Stake/Table/styles/TableComponents';
import { LoadingButton, LoadingButtonCircle } from '../../../Modals/styles/ModalsComponents';
import { BtnArrow } from 'assets';
import { roundNumber } from 'utils';
import { ModeThemes } from 'config';
import i18n from '../../../locale/i18n';
import TableMoreContentRightSection from './TableMoreContentRightSection';
import { InputField } from './InputComponent';


let TableMoreContentRight = ({
  openModal,
  setMaxAmount,
  handleInputChange,
  txOngoing,
  txOngoingData,
  formValues,
  trancheCard,
  isEth,
  number,
  buyerTokenBalance,
  trancheTokenBalance,
  theme,
  cryptoType,
  isDepositApproved,
  depositBalanceCheck,
  trancheToken,
  isWithdrawApproved,
  withdrawBalanceCheck,
  isDesktop
}) => {

  return (

          <TableMoreRowContentRight>
            <TableMoreContentRightSection openModal={openModal} isDesktop={isDesktop} formType="deposit" setMaxAmount={setMaxAmount} handleInputChange={handleInputChange} txOngoing={txOngoing} txOngoingData={txOngoingData} formValues={formValues} trancheCard={trancheCard} isEth={isEth} number={number} buyerTokenBalance={buyerTokenBalance} theme={theme} cryptoType={cryptoType} isDepositApproved={isDepositApproved} depositBalanceCheck={depositBalanceCheck} />
            <TableMoreContentRightSection openModal={openModal} isDesktop={isDesktop} withdraw={true} formType="withdraw" trancheTokenBalance={trancheTokenBalance} trancheToken={trancheToken} setMaxAmount={setMaxAmount} handleInputChange={handleInputChange} txOngoing={txOngoing} txOngoingData={txOngoingData} formValues={formValues} trancheCard={trancheCard} isEth={isEth} number={number} buyerTokenBalance={buyerTokenBalance} theme={theme} cryptoType={cryptoType} isWithdrawApproved={isWithdrawApproved} withdrawBalanceCheck={withdrawBalanceCheck} />
           </TableMoreRowContentRight>
     
  );
};

export default TableMoreContentRight;
