import React from 'react';
import { LoadingButton, LoadingButtonCircle } from '../../../Modals/styles/ModalsComponents';
import i18n from '../../../locale/i18n';

let TableMoreContentRightSectionBtns = ({
  deposit,
  txOngoing,
  txOngoingData,
  trancheCard,
  isDepositApproved,
  isWithdrawApproved,
  openModal,
  withdrawBalanceCheck,
  depositBalanceCheck,
  formValues 
}) => {

  return (
     <>
     {(deposit ? isDepositApproved : isWithdrawApproved) ? (
        txOngoing && txOngoingData.isDeposit === false && txOngoingData.trancheCardId === trancheCard.id ? (
          <button>
            <LoadingButton>
              {[...Array(4).keys()].map((idx) => {
                return <LoadingButtonCircle i={idx + 1}></LoadingButtonCircle>;
              })}
            </LoadingButton>
          </button>
        ) : (
          <button
            onClick={() => openModal('trancheConfirm', (deposit ? true : false))}
            disabled={ deposit ?
              (depositBalanceCheck === 'InputStylingError' || !formValues || +formValues.depositAmount <= 0 || isNaN(+formValues.depositAmount)) :
              (withdrawBalanceCheck === 'InputStylingError' || !formValues || +formValues.withdrawAmount <= 0 || isNaN(+formValues.withdrawAmount))
            }
          >
            <h2>{deposit ? i18n.t('tranche.trancheData.deposit') : i18n.t('tranche.trancheData.withdraw')}</h2>
          </button>
        )
      ) : txOngoing && txOngoingData.isDeposit === (deposit ? true : false) && txOngoingData.trancheCardId === trancheCard.id ? (
        <button>
          <LoadingButton>
            {[...Array(4).keys()].map((idx) => {
              return <LoadingButtonCircle i={idx + 1}></LoadingButtonCircle>;
            })}
          </LoadingButton>
        </button>
      ) : (
        <button onClick={() => openModal('trancheEnable', (deposit ? true : false))}>
          <h2>Approve</h2>
        </button>
      )}
     </>
  );
};

export default TableMoreContentRightSectionBtns;
