import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout } from 'app/components/Earning/Layout';
import Table from '../components/Earning/Table/Table';
import { PagesData } from 'config/constants';

function Earn({ ethereum: { address } }) {
  return (
    <Layout>
      <Table pageType={PagesData.tranche.pageType} />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  ethereum: state.ethereum
});

export default connect(mapStateToProps, null)(withRouter(Earn));
