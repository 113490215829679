import React, { useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { fromWei } from 'services/contractMethods';
import { setTokenBalances, setAllowanceAndBalance } from 'redux/actions/ethereum';
import { trancheCardToggle } from 'redux/actions/toggles';
import { checkServer } from 'redux/actions/checkServer';
import { roundNumber, safeDivide, searchTokenDecimals } from 'utils';
import { trancheIcons, ModeThemes, landingUrl, ETHorMaticCheck } from 'config';
import { ChevronTable, InfoIconTable, InfoIconTableLight, SliceTooltip, DefaultIcon } from 'assets';
import TableMoreRow from './TableMoreRow';
import { CSSTransition } from 'react-transition-group';

import {
  TableContentCard,
  TableContentCardWrapper,
  TableCardImg,
  AdustBtnWrapper,
  TableCardMore,
  TableCardMoreContent,
  TableContentCardWrapperMobile,
  TableContentCardMobile,
  TableMobileContent,
  TableMobileContentRow,
  TableMobileContentCol,
  TrancheRateType,
  TableAPYTooltipWrapper,
  TableAPYTooltip,
  TableAPYTooltipCol,
  TableCol,
  TableColContent,
  TableColText
} from '../../Stake/Table/styles/TableComponents';
import { etherScanUrl } from 'config';
import { maticBlockExplorerUrl } from 'config';
import { fantomBlockExplorerUrl } from 'config';
import { avalancheBlockExplorerUrl, JBenqiAddress } from 'config';

const TableCard = ({
  id,
  trancheCardToggle,
  tranche: {
    name,
    contractAddress,
    trancheId,
    buyerCoinAddress,
    trancheTokenAddress,
    type,
    trancheValueUSD,
    trancheValue,
    subscriptionUSD,
    cryptoTypePrice,
    subscription,
    apy,
    sliceAPY,
    netAPY,
    apyStatus,
    cryptoType,
    dividendType,
    protocolAPY,
    trancheToken,
    trancheRate,
    metaData: { underlyingDecimals },
    network
  },
  setTokenBalances,
  setAllowanceAndBalance,
  ethereum: { tokenBalance, balance, address },
  change,
  theme,
  isDesktop,
  noBorder,
  firstItem,
  lastItem,
  trancheCardStatus
}) => {
  
  const [tooltip, setTooltip] = useState(false);
  const [statusCard, setStatusCard] = useState({status: false, id: null});
  let buyerTokenBalance =
    cryptoType === 'ETH'
      ? balance && balance !== -1 && fromWei(balance)
      : searchTokenDecimals(cryptoType)
      ? tokenBalance[buyerCoinAddress] && safeDivide(tokenBalance[buyerCoinAddress], 10 ** searchTokenDecimals(cryptoType).decimals)
        : tokenBalance[ buyerCoinAddress ] && fromWei(tokenBalance[ buyerCoinAddress ]);
  let analyticsLink = `${ landingUrl }analytics/${ contractAddress }/${ trancheId }/${ type === 'TRANCHE_A' ? 0 : 1 }`;
  let explorerLink = (network === "ethereum" ? etherScanUrl : network === "polygon" ? maticBlockExplorerUrl : network === "ftm" ? fantomBlockExplorerUrl : avalancheBlockExplorerUrl) + 'token/' + trancheTokenAddress;
  let cryptoAvax = (network === "avax" && (cryptoType !== "AVAX" && cryptoType !== "QI"));
  let trancheTokenBalance = tokenBalance[ trancheTokenAddress ] && fromWei(tokenBalance[ trancheTokenAddress ]);
  const myDeposit = ((trancheTokenBalance || 0) * trancheRate || 0) * cryptoTypePrice || 0;
  trancheTokenBalance = roundNumber(trancheTokenBalance || 0);
  let tokenIcon = trancheIcons[trancheToken] ? trancheIcons[trancheToken].tokenIcon : DefaultIcon

  const cardToggle = () => {
    if ((trancheCardStatus.status) && (id === trancheCardStatus.id)) {
      trancheCardToggle({ status: false, id });
    } 
    else if (((trancheCardStatus.status) && (id !== trancheCardStatus.id)) || (!trancheCardStatus.status)){
      const tokens = [ trancheTokenAddress ];
      if (ETHorMaticCheck.indexOf(cryptoType) === -1){
        tokens.push(buyerCoinAddress);
      }
      setTimeout(() => {
        address && setAllowanceAndBalance(address, contractAddress, tokens);
      }, 500);
      change('tranche', 'depositAmount', '');
      change('tranche', 'withdrawAmount', '');
      trancheCardToggle({ status: true, id });
    }
  };


  const TableCardDesktop = () => {
    return (
      <TableContentCardWrapper
        color={ModeThemes[theme].TableCard}
        borderColor={ModeThemes[theme].TableCardBorderColor}
        shadow={ModeThemes[theme].tableCardShadow}
        cardShadow={ModeThemes[theme].cardShadow}
        cardToggle={trancheCardStatus.status && id === trancheCardStatus.id}
        border={ModeThemes[theme].TableBorder}
        tranche
        noBorder={noBorder}
      >
        <TableContentCard
          pointer={true}
          onClick={() => cardToggle()}
          border={trancheCardStatus.status && id === trancheCardStatus.id}
          color={ModeThemes[theme].borderColor}
        >
          <TableCol className='table-col' width="15%">
            <TableColContent display="flex" alignItems="center">
              <TableCardImg
                tranche={true}
                background={type === 'TRANCHE_A' ? '#68D2FF' : '#FF7A7F'}
              >
                <img src={trancheIcons[trancheToken] ? trancheIcons[trancheToken].assetIcon : DefaultIcon} alt='AssetIcon' />
              </TableCardImg>
              <TableColText color={ModeThemes[theme].tableText} margin="0 0 0 16px" fontWeight="600" fontSize="14px" textTransform="uppercase" letterSpacing="0.05em">{
                cryptoType && cryptoType 
            } { cryptoAvax &&
                <span>.e</span>
              }</TableColText>
            </TableColContent>
          </TableCol>

          <TableCol className='table-col' width="15%">
            <TableColContent display="flex" alignItems="center">
              <TrancheRateType
                TrancheRateColor={ModeThemes[theme].TableMoreBtnColor}
                TrancheRateTextColor={theme === 'dark' ? "#FFFFFF" : ModeThemes[theme].titleColor}
              >
                {type === 'TRANCHE_A' ? 'Fixed' : 'Variable'}
              </TrancheRateType>
            </TableColContent>
          </TableCol>


          <TableCol className='table-col' width="14%">
            <TableColContent className='content-3-col second-4-col-content' display="flex" justifyContent="center">
              <TableColText fontSize="17px" display="flex" textAlign="center" color={ModeThemes[theme].tableText}>{roundNumber(netAPY, 2)}%
                <TableAPYTooltipWrapper>
                  <img src={theme === "light" ? InfoIconTableLight : InfoIconTable} alt='info' onMouseOver={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)}/>
                  {
                    tooltip &&
                    <TableAPYTooltip titleColor={ModeThemes[theme].titleColor} tooltipColor={ModeThemes[theme].tooltipColor} theme={theme}>
                      <TableAPYTooltipCol titleColor={ModeThemes[theme].titleColor}>
                        <img src={trancheIcons[trancheToken] ? trancheIcons[trancheToken].assetIcon : DefaultIcon} alt='AssetIcon' />
                        <h2>{cryptoType && cryptoType}</h2>
                        <h2>{apy && roundNumber(apy, 2) !== 'NaN' ? roundNumber(apy, 2) : 0}%</h2>
                        <h2>{type === 'TRANCHE_A' ? 'Fixed' : 'Variable'}</h2>
                      </TableAPYTooltipCol>
                      <span>+</span>
                      <TableAPYTooltipCol titleColor={ModeThemes[theme].titleColor}>
                        <img src={SliceTooltip} alt='AssetIcon' />
                        <h2>SLICE</h2>
                        <h2>{sliceAPY && roundNumber(sliceAPY, 2) !== 'NaN' ? roundNumber(sliceAPY, 2) : 0}%</h2>
                        <h2>Variable</h2>
                      </TableAPYTooltipCol>
                    </TableAPYTooltip>
                  }
                  
                </TableAPYTooltipWrapper>
              </TableColText>
            </TableColContent>
          </TableCol>
          <TableCol className={'table-col table-fourth-col-return '} width="15%">
            <TableColContent className='content-3-col second-4-col-content'>
              <TableColText fontSize="17px" color={ModeThemes[theme].tableText} textAlign="center">${roundNumber(trancheValueUSD)}</TableColText>
              <TableColText fontSize="12px" color="#CCCCCD" textAlign="center" fontWeight="bold" letterSpacing="0.05em" textTransform="uppercase" lineHeight="15px">
                ({roundNumber(trancheValue)} {cryptoType})
              </TableColText>
            </TableColContent>
          </TableCol>
          
          <TableCol tranche={true} className={'table-col table-fifth-col-subscription'} width="15%">
            <TableColContent className='content-3-col second-4-col-content'>
              <TableColText fontSize="17px" color={ModeThemes[theme].tableText} textAlign="center">${roundNumber(myDeposit)}</TableColText>
              <TableColText fontSize="12px" color="#CCCCCD" textAlign="center" fontWeight="bold" letterSpacing="0.05em" textTransform="uppercase" lineHeight="15px">
                ({ trancheTokenBalance } {trancheToken})
              </TableColText>
            </TableColContent>
          </TableCol>
    
          <TableCol className='table-col' width="17%">
            <TableColContent color={ModeThemes[theme].tableText} display="flex" alignItems="center" justifyContent="center">
              <TrancheRateType
                TrancheRateColor={ModeThemes[theme].TableMoreBtnColor}
                TrancheRateTextColor={theme === 'dark' ? "#FFFFFF" : ModeThemes[theme].titleColor}
                protocol
              >
                <img src={trancheIcons[trancheToken] ? (theme === 'light' ? trancheIcons[trancheToken].protocolIconLight : trancheIcons[trancheToken].protocolIcon) : DefaultIcon} alt={trancheIcons[trancheToken] ? trancheIcons[trancheToken].protocolName : ""} />
                {trancheIcons[trancheToken] ? trancheIcons[trancheToken].protocolName : ""}
              </TrancheRateType>
            </TableColContent>
          </TableCol>
         
          <TableCol className='table-sixth-col table-col' width="4%">
            <AdustBtnWrapper className='adjust-btn-wrapper' chevron status={trancheCardStatus.status && id === trancheCardStatus.id}>
              <button className='flex'>
                <img src={ChevronTable} alt='ChevronTable' />
              </button>
            </AdustBtnWrapper>
          </TableCol>
        </TableContentCard>
        <CSSTransition
          in={trancheCardStatus.status && id === trancheCardStatus.id}
          timeout={300}
          classNames="tableMore"
          unmountOnExit
        >
        <TableCardMore
          className={'table-card-more ' + (trancheCardStatus.status && id === trancheCardStatus.id ? 'table-more-card-toggle' : '')}
          color={ModeThemes[theme].borderColor}
          border={trancheCardStatus.status && id === trancheCardStatus.id}
        >
          <TableCardMoreContent>
            <TableMoreRow
              name={name}
              type={type}
              network={network}
              trancheId={trancheId}
              trancheValue={trancheValue}
              apyStatus={apyStatus}
              apy={apy}
              underlyingDecimals={underlyingDecimals}
              sliceAPY={sliceAPY || 0}
              netAPY={netAPY}
              contractAddress={contractAddress}
              cryptoType={cryptoType}
              dividendType={dividendType}
              protocolAPY={protocolAPY}
              buyerTokenBalance={buyerTokenBalance}
              trancheToken={trancheToken}
              trancheRate={trancheRate}
              buyerCoinAddress={buyerCoinAddress}
              trancheTokenAddress={trancheTokenAddress}
              analyticsLink={analyticsLink}
              explorerLink={explorerLink}
              tokenIcon={tokenIcon}
            />
          </TableCardMoreContent>
        </TableCardMore>
        </CSSTransition>
      </TableContentCardWrapper>
    );
  };
  const TableCardMobile = () => {
    return (
      <TableContentCardWrapperMobile firstItem={firstItem} lastItem={lastItem} CardOpen={ModeThemes[theme].CardOpen} border={ModeThemes[theme].TableBorder} CardClosed={ModeThemes[theme].TableColor} noBorder={noBorder} cardToggle={trancheCardStatus.status && id === trancheCardStatus.id} tranche color={ModeThemes[theme].TableCard} borderColor={ModeThemes[theme].TableCardBorderColor} toggleCard={trancheCardStatus.status}>
        <TableContentCardMobile
          onClick={() => cardToggle()}
          className={trancheCardStatus ? 'table-card-toggle' : ''}
          tranche
          trancheMobile
        >
     
          <TableMobileContent trancheMobile>

            <TableMobileContentRow trancheMobile>
              <TableMobileContentCol color={ModeThemes[theme].tableText} trancheMobileRows width="50%">
                  <TableCardImg
                    tranche={true}
                    background={type === 'TRANCHE_A' ? '#68D2FF' : '#FF7A7F'}
                  >
                    <img src={trancheIcons[trancheToken] ? trancheIcons[trancheToken].assetIcon : DefaultIcon} alt='AssetIcon' />
                  </TableCardImg>
                  <TableColText fontSize="16px" fontWeight="600" letterSpacing="0.05em" textTransform="uppercase" font color={ModeThemes[theme].tableText}>{
                      cryptoType && cryptoType 
                  } { cryptoAvax &&
                      <span>.e</span>
                  }</TableColText>
                  <TrancheRateType
                    trancheMobile
                    TrancheRateColor={type === 'TRANCHE_A' ? ModeThemes[theme].TrancheRateFixedColor : ModeThemes[theme].TrancheRateVariableColor}
                    TrancheRateTextColor={theme === 'dark' ? "#FFFFFF" : (type === 'TRANCHE_A' ? ModeThemes[theme].TrancheRateFixedColor : ModeThemes[theme].TrancheRateVariableColor)}
                    mobile
                    color={ModeThemes[theme].tableText}
                  >
                    ({type === 'TRANCHE_A' ? 'Fixed' : 'Variable'})
                  </TrancheRateType>
              </TableMobileContentCol>

              <TableMobileContentCol color={ModeThemes[theme].tableText} trancheMobileRows width="50%" titleColor={ModeThemes[theme].tableText}>
                <h2>APY</h2>
                <h2>
                  {/* <img src={apyImage} alt='apyImage' /> */}
                  {roundNumber(netAPY, 2)}%
                </h2>
              </TableMobileContentCol>
            </TableMobileContentRow>


          </TableMobileContent>
        </TableContentCardMobile>
        <CSSTransition
          in={trancheCardStatus.status && id === trancheCardStatus.id}
          timeout={300}
          classNames="tableMore"
          unmountOnExit
        >
        <TableCardMore
          className={'table-card-more ' + (trancheCardStatus.status && id === trancheCardStatus.id ? 'table-more-card-toggle' : '')}
          color={ModeThemes[theme].backgroundBorder}
          border={trancheCardStatus.status && id === trancheCardStatus.id}
        >
          <TableCardMoreContent>
            <TableMoreRow
              name={name}
              type={type}
              network={network}
              trancheId={trancheId}
              trancheValue={trancheValue}
              apyStatus={apyStatus}
              apy={apy}
              underlyingDecimals={underlyingDecimals}
              sliceAPY={sliceAPY || 0}
              netAPY={netAPY}
              contractAddress={contractAddress}
              cryptoType={cryptoType}
              dividendType={dividendType}
              protocolAPY={protocolAPY}
              buyerTokenBalance={buyerTokenBalance}
              trancheToken={trancheToken}
              trancheRate={trancheRate}
              buyerCoinAddress={buyerCoinAddress}
              trancheTokenAddress={trancheTokenAddress}
              analyticsLink={analyticsLink}
              explorerLink={explorerLink}
              tokenIcon={tokenIcon}
              lastItem={lastItem}
            />
          </TableCardMoreContent>
        </TableCardMore>
        </CSSTransition>
      </TableContentCardWrapperMobile>
    );
  };
  return isDesktop ? TableCardDesktop() : TableCardMobile();
};

TableCard.propTypes = {
  ethereum: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  setTokenBalances: PropTypes.func.isRequired,
  setAllowanceAndBalance: PropTypes.func.isRequired,
  trancheCardToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ethereum: state.ethereum,
  form: state.form,
  trancheCard: state.data.trancheCard,
  theme: state.theme,
  trancheCardStatus: state.toggles.trancheCardStatus
});

export default connect(mapStateToProps, {
  setTokenBalances,
  setAllowanceAndBalance,
  checkServer,
  trancheCardToggle,
  change
})(TableCard);