import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from '../../locale/i18n';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { trancheMarketsToggle, setTxModalOpen, setTxModalStatus, setTxModalType, setTxModalLoading, selectMarket } from 'redux/actions/tableData';
import { AaveBtn, AAVEIconMobile, CompoundBtn, CompoundBtnBlack, CompoundIconMobile, AVAX, ETHLOGO, FANTOMLOGO, FANTOMLOGOLIGHT, PolygonLogo, PolygonLogoBlack, YEARNIconMobile, YEARNLOGO, YEARNLOGOLIGHT, BenqiLogoLight, BenqiLogo, BenqiIcon, BenqiIconLight, MarketsCompound, MarketsAAVE, selectDown } from 'assets';
import TrancheModal from '../../Modals/TrancheModal';
import {
  MarketsTabsWrapper,
  MarketsTabs,
  MarketTab,
  MarketsDropdownWrapper,
  MarketsDropdown,
  MarketDropdown,
  MarketsDropdownTab,
  MarketsDropdownTabText,
  MarketsDropdownAllIcons
} from './styles/HeaderComponents';
import { ModeThemes, networks } from 'config';
import { networkId, maticNetworkId, fantomNetworkId, avalancheNetworkId } from 'config/constants'
import { useOuterClick } from 'services/useOuterClick'


export const baseUrl = i18n.language === 'en' ? '' : '/' + i18n.language;

const HeaderTabs = ({ ethereum: { network }, data, setTxModalOpen, setTxModalStatus, setTxModalLoading, setTxModalType, theme, selectMarket }) => {
  const { market, isLoading } = data;
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
  const [marketsToggle, setMarketsToggle] = useState(false);
  const [marketSelect, setMarketSelect] = useState(undefined);
  
  const innerRef = useOuterClick(e => {
    setMarketsToggle(false);
  });
  const updateMedia = () => {
    setDesktop(window.innerWidth > 992);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      paritialVisibilityGutter: 30
    }
  };

  const closeModal = () => {
    setTxModalOpen(false);
    setTxModalStatus('initialState');
    setTxModalLoading(false);
  };  
  const marketToggle = (market) => {
    setMarketSelect(market);
    selectMarket(market);
    localStorage.setItem("marketSelected", JSON.stringify(market));
  };  
  useEffect(() => {
    let marketSelected = JSON.parse(localStorage.getItem("marketSelected"));
    setMarketSelect((networks[network] && marketSelected && networks[network].markets.some(i => i.name === marketSelected.name)) ? marketSelected : undefined);
    selectMarket((networks[network] && marketSelected && networks[network].markets.some(i => i.name === marketSelected.name)) ? marketSelected : {name: 'all'});
  }, [network, selectMarket]);

  

  return (
    <MarketsTabsWrapper color={ModeThemes[theme].TrancheMarketsTitle} className='TrancheMarkets'>
      <div>
        <h2>Markets</h2>
        <MarketsDropdownWrapper>
          
          <MarketsDropdownTab onClick={() => setMarketsToggle(!marketsToggle)} background={ModeThemes[theme].MenuDropdownBackground} backgroundColorMobile={ModeThemes[theme].TableColor} ref={innerRef}>
            {(market && market.name === 'all' && (network && networks[network] && (networks[network].markets.length > 1) || (!networks[network] && !marketSelect))) ?
              <MarketsDropdownTabText color={ModeThemes[theme].titleColor}>
                { networks[network] ?
                  <MarketsDropdownAllIcons>
                    {(networks[network].markets[0]) && <img src={networks[network].markets[0].img} alt="icon" />}
                    {networks[network].markets[1] && <img src={networks[network].markets[1].img} alt="icon" />}
                    {networks[network].markets[2] && <img src={networks[network].markets[2].img} alt="icon" />}
                  </MarketsDropdownAllIcons> :
                  <MarketsDropdownAllIcons>
                    <img src={networks[networkId].markets[0].img} alt="icon" />
                    <img src={networks[networkId].markets[1].img} alt="icon" />
                  </MarketsDropdownAllIcons>
                }
                <h2>All Markets</h2>
              </MarketsDropdownTabText> : 
              ( networks[network] ?
                <MarketsDropdownTabText color={ModeThemes[theme].titleColor}>
                  <img src={marketSelect ? marketSelect.img : network && networks[network].markets[0].img} alt="icon" />
                  <h2>{marketSelect ? marketSelect.name : network && networks[network].markets[0].name}</h2>
                </MarketsDropdownTabText> : 
                <MarketsDropdownTabText color={ModeThemes[theme].titleColor}>
                  <img src={marketSelect ? marketSelect.img : network && networks[networkId].markets[0].img} alt="icon" />
                  <h2>{marketSelect ? marketSelect.name : network && networks[networkId].markets[0].name}</h2>
                </MarketsDropdownTabText> 
              )            
            }
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.69251 1.16759L9.6923 1.16783L6.02026 5.04709L2.32086 1.16627L2.32091 1.16623L2.31831 1.16367L2.23765 1.24559C2.31831 1.16367 2.31828 1.16364 2.31824 1.1636L2.31816 1.16352L2.31796 1.16333L2.31745 1.16283L2.31592 1.16135L2.3109 1.15662C2.3067 1.1527 2.3008 1.14732 2.29331 1.14075C2.27835 1.12765 2.25692 1.10974 2.22993 1.08947C2.17629 1.04917 2.09896 0.998195 2.00536 0.957266C1.81766 0.87519 1.55123 0.829311 1.28906 1.00969C1.03753 1.18275 0.933858 1.43884 0.899597 1.65179C0.882431 1.75848 0.882055 1.85743 0.89016 1.9354C0.8942 1.97428 0.900545 2.00985 0.908844 2.03974C0.912981 2.05463 0.918061 2.06978 0.924402 2.08399C0.930024 2.09658 0.939832 2.11583 0.956271 2.13314L5.35749 6.76685L5.36166 6.77211C5.36686 6.77854 5.37424 6.78739 5.38368 6.79808C5.40253 6.81942 5.42986 6.84833 5.46486 6.88004C5.53419 6.94284 5.63717 7.0198 5.76715 7.06816C5.89881 7.11714 6.05811 7.13655 6.23361 7.08245C6.40722 7.02893 6.58617 6.90675 6.76535 6.69163L10.8544 2.31985L10.8544 2.3198C11.049 2.11153 11.1546 1.81453 11.1012 1.51985C11.0652 1.32059 10.9553 1.11187 10.6939 0.998883L10.6939 0.99887C10.3894 0.867349 10.1385 0.90537 9.96283 0.981248C9.87646 1.01856 9.80975 1.06431 9.76431 1.10102C9.74152 1.11942 9.72385 1.13575 9.71148 1.14791C9.70529 1.154 9.7004 1.15907 9.69685 1.16287L9.69251 1.16759Z" fill={ModeThemes[theme].titleColor} stroke={ModeThemes[theme].titleColor} stroke-width="0.229927"/>
            </svg>
          </MarketsDropdownTab>

          { marketsToggle &&
            <MarketsDropdown background={ModeThemes[theme].MenuDropdownBackground} backgroundColorMobile={ModeThemes[theme].TableColor}>
              {network && (networks[network] ? networks[network].markets.length > 1 : networks[networkId].markets.length > 1) &&
                <MarketDropdown onClick={() => marketToggle({name: 'all'})} background={ModeThemes[theme].MenuDropdownBackground}>
                  <MarketsDropdownTabText color={ModeThemes[theme].titleColor}>
                    { networks[network] ?
                      <MarketsDropdownAllIcons>
                        {(networks[network].markets[0]) && <img src={networks[network].markets[0].img} alt="icon" />}
                        {networks[network].markets[1] && <img src={networks[network].markets[1].img} alt="icon" />}
                        {networks[network].markets[2] && <img src={networks[network].markets[2].img} alt="icon" />}
                      </MarketsDropdownAllIcons> :
                      <MarketsDropdownAllIcons>
                        <img src={networks[networkId].markets[0].img} alt="icon" />
                        <img src={networks[networkId].markets[1].img} alt="icon" />
                      </MarketsDropdownAllIcons>
                    }

                    <h2>All Markets</h2>
                  </MarketsDropdownTabText>
                </MarketDropdown>
              }
              {
                networks[networks[network] ? network : networkId].markets.map((market, i) => {
                  return(<MarketDropdown key={i} onClick={() => marketToggle(market)} background={ModeThemes[theme].MenuDropdownBackground}>
                    <MarketsDropdownTabText color={ModeThemes[theme].titleColor}>
                      <img src={market.img} alt="icon" />
                      <h2>{market.name}</h2>
                    </MarketsDropdownTabText>
                  </MarketDropdown>)
                })
              }
            </MarketsDropdown>
            // <MarketsDropdown>
            //   <MarketDropdown>
            //     <MarketsDropdownTabText>
            //       <img src={MarketsAAVE} alt="icon" />
            //       <img src={MarketsCompound} alt="icon" />
            //       <h2>All Markets</h2>
            //     </MarketsDropdownTabText>
            //   </MarketDropdown>
            //   <MarketDropdown>
            //     <MarketsDropdownTabText>
            //       <img src={MarketsAAVE} alt="icon" />
            //       <h2>AAVE</h2>
            //     </MarketsDropdownTabText>
            //   </MarketDropdown>
            //   <MarketDropdown>
            //     <MarketsDropdownTabText>
            //       <img src={MarketsCompound} alt="icon" />
            //       <h2>Compound</h2>
            //     </MarketsDropdownTabText>
            //   </MarketDropdown>
            // </MarketsDropdown>
          }
          
        </MarketsDropdownWrapper>
      </div>

      <TrancheModal closeModal={() => closeModal()} />
    </MarketsTabsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
    theme: state.theme,
    ethereum: state.ethereum
  };
};

export default connect(mapStateToProps, { trancheMarketsToggle, setTxModalOpen, setTxModalStatus, setTxModalLoading, setTxModalType, selectMarket })(HeaderTabs);