import React from 'react';
import { Form, Field } from 'redux-form';
import ReactLoading from 'react-loading';
import {
  TableMoreRightSection,
  FormContent,
  TableMoreTitleWrapper,
  BalanceLabel,
  MaxLabel,
  TableMoreTitle,
  WrapBtnForm,
  MobileMoreFormBtns,
  MobileMoreFormBtn
} from '../../../Stake/Table/styles/TableComponents';
import { roundNumber } from 'utils';
import { ModeThemes } from 'config';
import i18n from '../../../locale/i18n';
import { InputField } from './InputComponent';
import TableMoreContentRightSectionBtns from './TableMoreContentRightSectionBtns';



let TableMoreContentRightSection = ({
  openModal,
  setMaxAmount,
  handleInputChange,
  txOngoing,
  txOngoingData,
  formValues,
  trancheCard,
  isEth,
  number,
  buyerTokenBalance,
  theme,
  cryptoType,
  isDepositApproved,
  depositBalanceCheck,
  isWithdrawApproved,
  withdrawBalanceCheck,
  formType,
  withdraw,
  trancheTokenBalance,
  trancheToken,
  setFormType,
  isDesktop
}) => {
  let deposit = formType === 'deposit';

  return (
    <TableMoreRightSection
      withdraw={withdraw}
      color={ModeThemes[theme].dropDownBorder}
      disabledBackground={ModeThemes[theme].inputDisabledBackground}
      btn={ModeThemes[theme].backgroundBorder}
      textColor={ModeThemes[theme].titleColor}
      border={ModeThemes[theme].TableBorder}
      disabled={deposit ? !isDepositApproved : !isWithdrawApproved}
      approved={deposit ? !isDepositApproved : !isWithdrawApproved}
      disabledColor={ModeThemes[theme].DisabledBtn}
      disabledTextColor={ModeThemes[theme].DisabledBtnText}
    >
      {txOngoing && !isDesktop && (
        <div>
          <ReactLoading type={'spin'} color={ModeThemes[theme].loadingSpinner} />
        </div>
      )}
      { isDesktop ?
        <TableMoreTitleWrapper color={ModeThemes[theme].dropDownText} theme={theme}>
          <TableMoreTitle textwithBtn={cryptoType === 'WFTM'} theme={theme}>
            <h2>{formType === 'deposit' ? i18n.t('tranche.trancheData.deposit') : i18n.t('tranche.trancheData.withdraw')}
              {deposit && cryptoType === 'WFTM' && <button onClick={() => openModal('trancheWFTM', true)}>Wrap</button>}
            </h2>
            <BalanceLabel textColor={ModeThemes[theme].titleColor}>
              {i18n.t('tranche.trancheData.balance')}: {deposit ? (buyerTokenBalance ? roundNumber(buyerTokenBalance) : '0') : (trancheTokenBalance ? roundNumber(trancheTokenBalance) : '0')}
              <span>{deposit ? cryptoType : trancheToken}</span>
            </BalanceLabel>
          </TableMoreTitle>
        </TableMoreTitleWrapper> :
        <TableMoreTitleWrapper color={ModeThemes[theme].dropDownText} theme={theme} textwithBtn={cryptoType === 'WFTM'}>
          <MobileMoreFormBtns color={ModeThemes[theme].dropDownText}>
            <MobileMoreFormBtn current={formType === 'deposit'} onClick={() => setFormType('deposit')} color={ModeThemes[theme].dropDownText}>
              {i18n.t('tranche.trancheData.deposit')}
            </MobileMoreFormBtn>
            <MobileMoreFormBtn
              current={formType === 'withdraw'}
              onClick={() => setFormType('withdraw')}
              color={ModeThemes[theme].dropDownText}
            >
              {i18n.t('tranche.trancheData.withdraw')}
            </MobileMoreFormBtn>
          </MobileMoreFormBtns>
          {cryptoType === 'WFTM' && <WrapBtnForm onClick={() => openModal('trancheWFTM', true)} theme={theme}>Wrap</WrapBtnForm>}
        </TableMoreTitleWrapper>
      }
      {
        !isDesktop && <BalanceLabel textColor={ModeThemes[theme].titleColor}>
          {i18n.t('tranche.trancheData.balance')}: {deposit ? (buyerTokenBalance ? roundNumber(buyerTokenBalance) : '0') : (trancheTokenBalance ? roundNumber(trancheTokenBalance) : '0')}
          <span>{deposit ? cryptoType : trancheToken}</span>
        </BalanceLabel>
      }

      <Form onSubmit={(e) => e.preventDefault()}>
        <FormContent theme={theme} border={ModeThemes[theme].TableInputBorder} color={ModeThemes[theme].dropDownText} background={ModeThemes[theme].inputBackground}>
          <Field
            component={InputField}
            validate={[number]}
            onChange={(e, newValue) => handleInputChange(newValue, (deposit ? true : false))}
            disabled={deposit ? !isDepositApproved : !isWithdrawApproved}
            className={deposit ? depositBalanceCheck : withdrawBalanceCheck}
            name={deposit ? 'depositAmount' : 'withdrawAmount'}
            type='number'
            step='0.001'
          />
          {deposit ?
          (!isEth && <MaxLabel textColor={ModeThemes[theme].titleColor} onClick={isDepositApproved ? (e) => setMaxAmount(e, true) : undefined}>{i18n.t('tranche.trancheData.max')}</MaxLabel>)
          :
          <MaxLabel textColor={ModeThemes[theme].titleColor} onClick={isWithdrawApproved ? (e) => setMaxAmount(e, false) : undefined}>{i18n.t('tranche.trancheData.max')}</MaxLabel>}
        </FormContent>
      
        <TableMoreContentRightSectionBtns deposit={deposit} openModal={openModal} txOngoing={txOngoing} isDepositApproved={isDepositApproved} isWithdrawApproved={isWithdrawApproved} trancheCard={trancheCard} txOngoingData={txOngoingData} formValues={formValues} depositBalanceCheck={depositBalanceCheck} withdrawBalanceCheck={withdrawBalanceCheck} />

      </Form>
    </TableMoreRightSection>
  );
};

export default TableMoreContentRightSection;
