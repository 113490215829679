import Onboard from 'bnc-onboard';
import Notify from 'bnc-notify';
import store from 'redux/store';
import { networkId, alchemyHttpUrl as rpcUrl, infuraKey, blocknativeKey as dappId, networkParams } from 'config';
import { selectMarket } from 'redux/actions/tableData';

let onboard = undefined;
let notify = undefined;

export function initOnboard(subscriptions) {
  if (!onboard) {
    
    const state = store.getState();
    const { theme } = state;
    onboard = Onboard({
      subscriptions,
      hideBranding: true,
      networkId,
      darkMode: theme === 'dark' ? true : theme === 'light' ? false : null,
      walletSelect: {
        wallets: [
          { walletName: 'metamask', preferred: true },
          { walletName: 'walletConnect', infuraKey, preferred: true },
          { walletName: 'coinbase', preferred: true },
          { walletName: 'status', preferred: true },
          { walletName: 'trust', rpcUrl, preferred: true },
          { walletName: 'torus', preferred: true }
        ]
      },
      walletCheck: [
        { checkName: 'derivationPath' },
        { checkName: 'connect' },
        { checkName: 'accounts' },
        { checkName: 'network' },
        { checkName: 'balance' }
      ]
    });
  }
  return onboard;
}

export function initNotify() {
  if (!notify) {
    notify = Notify({
      dappId,
      networkId,
      darkMode: true
    });
  }
  return notify;
}

export function switchNetwork (network) {
  try
  {
    const state = store.getState();
    const { wallet } = state.ethereum;
    selectMarket({name: 'all'});
    localStorage.setItem("marketSelected", null)
    if (wallet) {
      if (network === 'kovan' || network === 'mainnet') {
        wallet.provider
          .request({
            id: network === 'mainnet' ? 1 : 42,
            jsonrpc: 2.0,
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: network === 'mainnet' ? '0x1' : '0x2a'
              }
            ]
          })
          .catch((error) => {
            console.log(error);
          });
        return;
      }
      wallet.provider
        .request({
          method: 'wallet_addEthereumChain',
          params: [ networkParams[ network ] ]
        }).then(() => {
          const state = initOnboard().getState();
          const chainId = +wallet.provider.chainId;
          const { appNetworkId, network } = state;
          if (appNetworkId === chainId && appNetworkId !== network)
          {
            console.log(appNetworkId, chainId, network);
            onboard.config({ networkId: network });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // wallet.provider
    //   .request({
    //     method: 'wallet_switchEthereumChain',
    //     params: [{[Object.keys(networkParams[network])[0]]: networkParams[network].chainId}]
    //   })
    //   .catch((error) => {
    //     if (error.code === 4902) {
    //       wallet.provider
    //       .request({
    //         method: 'wallet_addEthereumChain',
    //         params: [networkParams[network]]
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //     }
    //   });
  } catch (error) {
    console.log(error);
  }
}


export const addTokenToWallet = async (buyerCoinAddress, cryptoType, underlyingDecimals, tokenIcon) => {
  const state = store.getState();
  const { wallet } = state.ethereum;
  if(wallet){
    try {
      const token = await wallet.provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', 
          options: {
            address: buyerCoinAddress, 
            symbol: cryptoType,
            decimals: underlyingDecimals,
            image: window.location.origin + tokenIcon
          },
        },
      });
      if(token) return true;
      else console.log("Token was not added!")
    } catch (error) {
      console.log(error);
    }
  }
}