import {
  TRANCHE_CARD_TOGGLE
} from '../actions/constants';

const initialState = {
  trancheCardStatus: { status: false, id: null },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TRANCHE_CARD_TOGGLE:
      return { ...state, trancheCardStatus: payload };
    default:
      return state;
  }
}
