import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, getFormValues, change } from 'redux-form';
import { number } from 'utils/validations';
import {
  TableMoreRowWrapper,
  TableMoreRowContent,
  TableMoreRowContentLeft,
  TableMoreRowContentRight,
  TableMoreRightSection,
  FormContent,
  TableMoreTitleWrapper,
  TableMoreLeftSection,
  TableMoreLeftSectionContent,
  TableMoreLeftTopSection,
  TableMoreLeftBottomSection,
  TooltipWrapper,
  TableMoreBtns,
  TableMoreBtn,
  TableMoreTitle,
  TableMoreLeftBottomSectionHeader,
  TableMoreMobileIcons,
  APYMobileWrapper,
  BalanceLabel,
  MaxLabel,
  WrapBtnForm
} from '../../Stake/Table/styles/TableComponents';
import { LoadingButton, LoadingButtonCircle } from '../../Modals/styles/ModalsComponents';
import { BtnArrow, LinkArrow, LinkArrowWhite, MetaMaskIcon, SliceTooltip } from 'assets';
import { setTxModalOpen, setTxModalType, setTxModalData, setTxModalStatus, setTxModalLoading } from 'redux/actions/tableData';
import { setAddress, setNetwork, setBalance, setWalletAndWeb3 } from 'redux/actions/ethereum';
import { fromWei, initOnboard, addTokenToWallet, switchNetwork } from 'services';
import { roundNumber, isGreaterThan, isEqualTo, isLessThan, readyToTransact } from 'utils';
import { ModeThemes, ETHorMaticCheck } from 'config';
import i18n from '../../locale/i18n';
import { NetworkWarning, NetworkWarningBtn } from '../Header/styles/HeaderComponents';
import { networks } from 'config';
import { networkId } from 'config';
import TableMoreRowBtns from './TableComponents/TableMoreRowBtns';
import { InputField } from './TableComponents/InputComponent';
import TableMoreContentRight from './TableComponents/TableMoreContentRight';
import TableMoreContentRightSection from './TableComponents/TableMoreContentRightSection';



let TableMoreRow = ({
  name,
  type,
  trancheId,
  apyStatus,
  apy,
  sliceAPY,
  netAPY,
  contractAddress,
  cryptoType,
  dividendType,
  underlyingDecimals,
  protocolAPY,
  buyerTokenBalance,
  trancheToken,
  trancheRate,
  buyerCoinAddress,
  trancheTokenAddress,
  trancheValue,
  ethereum: { balance, tokenBalance, trancheAllowance, txOngoing, wallet, network },
  data: { txOngoingData },
  setAddress,
  setNetwork,
  setBalance,
  setWalletAndWeb3,
  setTxModalOpen,
  setTxModalType,
  setTxModalStatus,
  setTxModalData,
  setTxModalLoading,
  change,
  formValues,
  theme,
  analyticsLink,
  explorerLink,
  tokenIcon,
  lastItem,
  trancheCardStatus
}) => {
  const [isEth, setIsEth] = useState(false);
  const [isDepositApproved, setDepositApproved] = useState(false);
  const [isWithdrawApproved, setWithdrawApproved] = useState(false);
  const [depositBalanceCheck, setDepositBalanceCheck] = useState('');
  const [withdrawBalanceCheck, setWithdrawBalanceCheck] = useState('');
  const [formType, setFormType] = useState('deposit');
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
  const [TooltipToggle, setTooltipToggle] = useState('');
  let trancheTokenBalance = tokenBalance[trancheTokenAddress] && fromWei(tokenBalance[trancheTokenAddress]);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 992);
  };

  const tooltipToggle = (val) => {
    setTooltipToggle(val);
  };

  const onboard = initOnboard({
    address: setAddress,
    network: setNetwork,
    balance: setBalance,
    wallet: setWalletAndWeb3
  });
  const openModal = async (txType, isDeposit) => {
    const ready = await readyToTransact(wallet, onboard);
    if (!ready) return;
    setTxModalType(txType);
    setTxModalOpen(true);
    setTxModalData({
      name: name.split('-').length > 1 ? name.split('-')[1].trim() : name.split('-')[0].trim(),
      contractAddress,
      trancheId,
      trancheCard: trancheCardStatus.id,
      trancheType: type,
      apyStatus,
      cryptoType,
      trancheValue,
      trancheToken,
      dividendType,
      apy,
      protocolAPY,
      sliceAPY,
      netAPY,
      isDeposit,
      isDepositApproved,
      isWithdrawApproved,
      buyerCoinAddress,
      trancheTokenAddress,
      buyerTokenBalance,
      FTMBalance: cryptoType === 'WFTM' ? balance : '0'
    });
  };


  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (trancheAllowance[contractAddress]) {
      if (ETHorMaticCheck.indexOf(cryptoType) !== -1) {
        setIsEth(true);
        setDepositApproved(true);
        setWithdrawApproved(trancheAllowance[contractAddress][trancheTokenAddress]);
        change('withdrawIsApproved', trancheAllowance[contractAddress][trancheTokenAddress]);
      } else {
        setIsEth(false);
        setDepositApproved(trancheAllowance[contractAddress][buyerCoinAddress]);
        setWithdrawApproved(trancheAllowance[contractAddress][trancheTokenAddress]);
        change('depositIsApproved', trancheAllowance[contractAddress][buyerCoinAddress]);
        change('withdrawIsApproved', trancheAllowance[contractAddress][trancheTokenAddress]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoType, buyerCoinAddress, trancheTokenAddress, trancheAllowance, setDepositApproved, setWithdrawApproved]);

  const setMaxAmount = useCallback(
    (e, type) => {
      // e.preventDefault();
      console.log(buyerTokenBalance, trancheTokenBalance);
      if (type) {
        change('depositAmount', buyerTokenBalance);
        isEqualTo(buyerTokenBalance, 0) ? setDepositBalanceCheck('InputStylingError') : setDepositBalanceCheck('');
      } else {
        change('withdrawAmount', trancheTokenBalance);
        isEqualTo(trancheTokenBalance, 0) ? setWithdrawBalanceCheck('InputStylingError') : setWithdrawBalanceCheck('');
      }
    },
    [buyerTokenBalance, trancheTokenBalance, change]
  );

  const addingTokenToWallet = async () => {
    await addTokenToWallet(trancheTokenAddress, trancheToken, 18, tokenIcon);
  }


  const handleInputChange = (newValue, type) => {
    if (type) {
      isGreaterThan(newValue, buyerTokenBalance || 0) || isLessThan(newValue, 0)
        ? setDepositBalanceCheck('InputStylingError')
        : setDepositBalanceCheck('');
    } else {
      isGreaterThan(newValue, trancheTokenBalance || 0) || isLessThan(newValue, 0)
        ? setWithdrawBalanceCheck('InputStylingError')
        : setWithdrawBalanceCheck('');
    }
  };

  const switchToEthereum = () =>  {
    networkId === 1 ? switchNetwork('mainnet') : switchNetwork('kovan');
  }

  return (
    <TableMoreRowWrapper>
      <TableMoreRowContent lastItem={lastItem} CardOpen={ModeThemes[theme].CardOpen} border={ModeThemes[theme].TableBorder} TableMoreColor={ModeThemes[theme].TableMoreColor}>
        <TableMoreRowContentLeft>
          <TableMoreLeftBottomSection titleColor={ModeThemes[theme].titleColor} value={ModeThemes[theme].textColor}>
          <TableMoreLeftBottomSectionHeader>
            <h2>{type === 'TRANCHE_A' ? i18n.t('tranche.trancheData.fixedRate') : i18n.t('tranche.trancheData.variableRate')}</h2>
          </TableMoreLeftBottomSectionHeader>

            <p>
              {type === 'TRANCHE_A'
                ? `${name} ${i18n.t('tranche.trancheData.isTheSenior')} ${dividendType}  ${i18n.t('tranche.trancheData.token')}. ${i18n.t(
                    'tranche.trancheData.thisTrancheFixed'
                  )} ${roundNumber(apy, 2)}%, ${i18n.t('tranche.trancheData.inAddition')}`
                : `${name} ${i18n.t('tranche.trancheData.isTheJunior')}  ${dividendType}  ${i18n.t('tranche.trancheData.token')}. ${i18n.t(
                    'tranche.trancheData.thisTrancheVariable'
                  )} ${roundNumber(apy, 2)}%, ${i18n.t('tranche.trancheData.inAddition')}`}
            </p>
            {isDesktop &&
              <TableMoreRowBtns WrapperMargin="20px 0 0 0" FirstBtnMargin="0 16px 0 0" SecondBtnMargin="0 16px 0 0" ThirdBtnMargin="0" wallet={wallet} theme={theme}
                analyticsLink={analyticsLink}
                explorerLink={explorerLink}
                addingTokenToWallet={addingTokenToWallet}
                isDesktop={isDesktop}
              />
            }
          </TableMoreLeftBottomSection>
        </TableMoreRowContentLeft>
        {
          !isDesktop && 
          <APYMobileWrapper color={ModeThemes[theme].tableText} border={ModeThemes[theme].TableBorder}>
            <h2>{apy && roundNumber(apy, 2) !== 'NaN' ? roundNumber(apy, 2) : 0}%<span>{type === 'TRANCHE_A' ? 'Fixed' : 'Variable'}</span></h2>
            <h2>+</h2>
            <h2><img src={SliceTooltip} alt='AssetIcon' /> {sliceAPY && roundNumber(sliceAPY, 2) !== 'NaN' ? roundNumber(sliceAPY, 2) : 0}% <span>Bonus</span></h2>
          </APYMobileWrapper>
        }
        {isDesktop ? (
          <TableMoreContentRight 
            openModal={openModal} 
            setMaxAmount={setMaxAmount} 
            handleInputChange={handleInputChange} 
            txOngoing={txOngoing} 
            txOngoingData={txOngoingData} 
            formValues={formValues} 
            trancheCard={trancheCardStatus} 
            isEth={isEth} 
            number={number} 
            buyerTokenBalance={buyerTokenBalance} 
            theme={theme} 
            cryptoType={cryptoType} 
            isDepositApproved={isDepositApproved} 
            depositBalanceCheck={depositBalanceCheck} 
            isWithdrawApproved={isWithdrawApproved} 
            withdrawBalanceCheck={withdrawBalanceCheck}
            trancheTokenBalance={trancheTokenBalance}
            trancheToken={trancheToken}
            isDesktop={isDesktop}
          />
        ) : (
          <TableMoreRowContentRight>          

            <TableMoreContentRightSection
              openModal={openModal} 
              setMaxAmount={setMaxAmount} 
              handleInputChange={handleInputChange} 
              txOngoing={txOngoing} 
              txOngoingData={txOngoingData} 
              formValues={formValues} 
              trancheCard={trancheCardStatus} 
              isEth={isEth} 
              number={number} 
              buyerTokenBalance={buyerTokenBalance} 
              theme={theme} 
              cryptoType={cryptoType} 
              isDepositApproved={isDepositApproved} 
              depositBalanceCheck={depositBalanceCheck} 
              isWithdrawApproved={isWithdrawApproved} 
              withdrawBalanceCheck={withdrawBalanceCheck}
              trancheTokenBalance={trancheTokenBalance}
              trancheToken={trancheToken}
              isDesktop={isDesktop}
              setFormType={setFormType}
            />            

            { !isDesktop &&
              <TableMoreRowBtns WrapperMargin="20px 0 0 0" FirstBtnPadding="0 10px" FirstBtnMargin="0 17px 0 0" SecondBtnMargin="0 16px 0 0" ThirdBtnMargin="0" 
                BorderRadius="8px"
                FirstBtnWidth="65%"
                SecondBtnWidth="16%"
                ThirdBtnWidth="16%"  
                wallet={wallet} theme={theme}
                analyticsLink={analyticsLink}
                explorerLink={explorerLink}
                addingTokenToWallet={addingTokenToWallet}
                isDesktop={isDesktop}
              />
            }
          </TableMoreRowContentRight>
        )}
        { !isDesktop && !networks[network] &&
          <NetworkWarning color={ModeThemes[theme].titleColor} fontSize="16px" fontWeight="bold" margin="16px auto 24px auto" padding="24px" table>
            <h2>
              You’re currently connected to a different network, switch to the Mainnet network to deposit into this market.
            </h2>
            <NetworkWarningBtn>
              change to mainnet
            </NetworkWarningBtn>
          </NetworkWarning>
        }
      </TableMoreRowContent>
        { isDesktop && !networks[network] &&
          <NetworkWarning color={ModeThemes[theme].titleColor} fontSize="14px" fontWeight="bold" margin="16px auto 0 auto" padding="15px 20px" table>
            <h2>
              You’re currently connected to a different network, switch to the Mainnet network to deposit into this market.
            </h2>
            <NetworkWarningBtn onClick={() => switchToEthereum()}>
              change to mainnet
            </NetworkWarningBtn>
          </NetworkWarning>
        }
    </TableMoreRowWrapper>
  );
};

TableMoreRow = reduxForm({
  form: 'tranche',
  initialValues: { depositAmount: '', withdrawAmount: '' }
})(TableMoreRow);

const mapStateToProps = (state) => ({
  ethereum: state.ethereum,
  data: state.data,
  formValues: getFormValues('tranche')(state),
  theme: state.theme,
  trancheCardStatus: state.toggles.trancheCardStatus
});

export default TableMoreRow = connect(mapStateToProps, {
  change,
  setTxModalOpen,
  setTxModalType,
  setTxModalStatus,
  setTxModalData,
  setTxModalLoading,
  setAddress,
  setNetwork,
  setBalance,
  setWalletAndWeb3
})(TableMoreRow);
