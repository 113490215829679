import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { apiUri } from 'config/constants';
import {
  fetchTableData,
  changeFilter,
  paginationOffset,
  paginationCurrent,
  changeSorting,
  changeOwnAllFilter,
  ownAllToggle,
  selectMarket
} from 'redux/actions/tableData';
import {
  ModeThemes,
  networks,
  networkId
} from 'config/constants';
import ReactLoading from 'react-loading';
import { changePath } from 'redux/actions/TogglePath';
import TableHead from './TableHead';
import TableCard from './TableCard';
import {
  TableWrapper,
  TableContentCard,
  TableContentMobile,
  CallToActionTradeWrapper,
  CallToActionTradeBtns,
  CallToActionTradeBtn,
  CallToActionTradetext,
  LoadingContent,
  TableContentWrapper
} from '../../Stake/Table/styles/TableComponents';
import { EmptyBox } from 'assets';
import HeaderTabs from '../Header/HeaderTabs';
import store from 'redux/store';

const { tranchesList: tranchesListUrl } = apiUri;

const style = {
  pageItem: {
    fontFamily: 'Roboto, sans-serif',
    background: 'transparent',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    letterSpacing: '.02em',
    textRransform: 'uppercase',
    color: '#BEBEBE',
    cursor: 'pointer',
    padding: '7px 12px'
  },
  pageItemActive: {
    color: '#DADADA',
    backgroundColor: '#FAF8FF',
    borderColor: '#FAF8FF',
    borderRadius: '7px'
  }
};
const Table = ({
  HandleNewLoan,
  fetchTableData,
  changeOwnAllFilter,
  path,
  data,
  changePath,
  paginationOffset,
  paginationCurrent,
  ownAllToggle,
  theme
}) => {
  const { pathname } = useLocation();
  let localAddress = window.localStorage.getItem('address');
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
  const { skip, limit, sort, isLoading, tradeType, market } = data;
  let parsedPath = pathname.split('/');
  let currentPath = parsedPath[parsedPath.length - 1];
  let tranchesLength = data && data.tranchesList.length;

  const updateMedia = () => {
    setDesktop(window.innerWidth > 992);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const trancheListing = useCallback(
    _.debounce(
      async () => {
        const state = store.getState();
        const { ethereum: { network } } = state;
        if (sort) {
          await fetchTableData(
            {
              sort,
              skip,
              limit,
              filter: {
                address: localAddress ? localAddress : undefined,
                type: networks[network] && (networks[network] ? networks[network].name.toLowerCase() : networks[networkId].name.toLocaleLowerCase()), 
                contractAddress: networks[network] && (networks[network].markets.some(i => i.name === market.name)) ? market.contractAddress : undefined
              }
            },
            tranchesListUrl
          );
        } else {
          await fetchTableData(
            {
              skip,
              limit,
              filter: {
                address: localAddress ? localAddress : undefined,
                type: networks[network] && (networks[network] ? networks[network].name.toLowerCase() : networks[networkId].name.toLocaleLowerCase()), 
                contractAddress: networks[network] && (networks[network].markets.some(i => i.name === market.name)) ? market.contractAddress : undefined
              }
            },
            tranchesListUrl
          );
        }
      },
      3000,
      { leading: true }
    ),
    [fetchTableData, skip, limit, sort, localAddress, market]
  );

  useEffect(() => {
    changePath(currentPath);
    changeOwnAllFilter('all');
    ownAllToggle('allTranches');
  }, [changePath, pathname, currentPath, changeOwnAllFilter, ownAllToggle]);

  useEffect(() => {
    path === 'tranche' && trancheListing();
  }, [path, trancheListing]);


  return (
    <div className='container content-container'>
      <div>
        <TableWrapper mobile>
          <HeaderTabs />
          <TableContentMobile className='table-content' TableColor={ModeThemes[theme].TableColor} border={ModeThemes[theme].TableBorder}>
            {isLoading ? (
              <div>
                <TableContentCard>
                  <ReactLoading className='TableMoreLoading' type={'bubbles'} color='rgba(56,56,56,0.3)' />
                </TableContentCard>
              </div>
            ) : (
              data && data.tranchesList.map((tranche, i) => <TableCard key={i} id={i} firstItem={i === 0} lastItem={i === tranchesLength - 1} tranche={tranche} noBorder={i === tranchesLength - 1}  path={path} isDesktop={isDesktop}/>)
            )}
          </TableContentMobile>
        </TableWrapper>
        <TableWrapper desktop>
          <div className='table-container'>
            <HeaderTabs />
            <TableContentWrapper TableColor={ModeThemes[theme].TableColor}>
              <TableHead color={ModeThemes[theme].TableHead}/>
              <div className='table-content'>
                {isLoading ? (
                  <div>
                    {[...Array(5)].map((i, idx) => (
                      <TableContentCard key={idx} color={ModeThemes[theme].TableHead}>
                        <div className='loadingCard flex'>
                          <div className='loadingFirstCol'>
                            <div className='loadingFirslColContent flex'>
                              <LoadingContent className='loadingAvatar loadingContent ' colorOne={ModeThemes[theme].LoadingColorOne} colorTwo={ModeThemes[theme].LoadingColorTwo}></LoadingContent>
                              <LoadingContent className='loadingText loadingContentWrapper loadingContent' colorOne={ModeThemes[theme].LoadingColorOne} colorTwo={ModeThemes[theme].LoadingColorTwo}></LoadingContent>
                            </div>
                          </div>
                          <div className='loadingSecondCol'>
                            <LoadingContent className='loadingContentCol loadingContentWrapper loadingContent' colorOne={ModeThemes[theme].LoadingColorOne} colorTwo={ModeThemes[theme].LoadingColorTwo}></LoadingContent>
                          </div>
                          <div className='loadingFifthCol'>
                            <LoadingContent className='loadingFifthColContent loadingContentWrapper loadingContent' colorOne={ModeThemes[theme].LoadingColorOne} colorTwo={ModeThemes[theme].LoadingColorTwo}></LoadingContent>
                          </div>
                          <div className='loadingSixthCol'>
                            <LoadingContent className='loadingSixthColContent loadingContentWrapper loadingContent' colorOne={ModeThemes[theme].LoadingColorOne} colorTwo={ModeThemes[theme].LoadingColorTwo}></LoadingContent>
                          </div>
                        </div>
                      </TableContentCard>
                    ))}
                  </div>
                ) : !isLoading && tradeType === 'myTranches' && data.tranchesList.length === 0 ? (
                  // </div> : (!isLoading && loans.tranchesList.length === 0 && tradeType === 'sell') ?

                  <TableContentCard pointer={false}>
                    <CallToActionTradeWrapper>
                      <img src={EmptyBox} alt='EmptyBox' />

                      <CallToActionTradetext>
                        <h2>You don’t have any loans, assets or instruments to sell.</h2>
                        <h2>Buy a tranche or provide a loan to get started!</h2>
                      </CallToActionTradetext>

                      <CallToActionTradeBtns>
                        <CallToActionTradeBtn type='loans'>
                          BROWSE <span>LOANS</span>
                        </CallToActionTradeBtn>
                        <CallToActionTradeBtn>
                          BROWSE <span>TRANCHES</span>
                        </CallToActionTradeBtn>
                      </CallToActionTradeBtns>
                    </CallToActionTradeWrapper>
                  </TableContentCard>
                ) : (
                  data && data.tranchesList.map((tranche, i) => <TableCard key={i} noBorder={i === tranchesLength - 1} id={i} tranche={tranche} path={path} isDesktop={isDesktop} />)
                )}
              </div>
            </TableContentWrapper>
          </div>
        </TableWrapper>

      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
    path: state.path,
    theme: state.theme,
    ethereum: state.ethereum
  };
};

export default connect(mapStateToProps, {
  fetchTableData,
  changePath,
  changeFilter,
  paginationOffset,
  paginationCurrent,
  changeSorting,
  changeOwnAllFilter,
  ownAllToggle,
  selectMarket
})(Table);
