import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { setTxModalOpen, setTxModalType, selectMarket } from 'redux/actions/tableData';
import { setAddress, setNetwork, setBalance, setWalletAndWeb3 } from 'redux/actions/ethereum';
import { initOnboard } from 'services/blocknative';
import { addrShortener, roundNumber, toBigNumber } from 'utils/helperFunctions';
import {
  WalletBtn,
  WalletBtnIcon,
  WalletBtnText, 
  NavBarRightWrapper,
  NetworkSwitchWrapper,
  NavNetworksList,
  NavNetwork,
  NavbarMobileWrapper,
  NavbarIconWrapper,
  NavbarMobileMenu,
  NavbarMobileMenuNavLinks,
  NavbarMobileMenuLinks,
  NetworkWarning
} from './styles/HeaderComponents';
import { ModeThemes, PagesData, SLICEAddress, networkId, networks } from 'config/constants';

import Wallet from 'assets/images/svg/wallet.svg';
import i18n from '../../locale/i18n';
import { AVAX, ETHnetwork, FANTOMLOGOLIGHT, PolygonLogo, TrancheIconNav, TrancheStake, WalletIcon } from 'assets';
import { fromWei, toBN } from 'services';
import { useOuterClick } from 'services/useOuterClick';
import { switchNetwork } from 'services';
export const baseUrl = i18n.language === 'en' ? '' : '/'+i18n.language;

const ConnectWallet = ({
  setAddress,
  setNetwork,
  setBalance,
  setWalletAndWeb3,
  theme,
  ethereum: { address, balance, tokenBalance, unclaimedSIRRewards, network },
  setTxModalOpen,
  setTxModalType,
  selectMarket
}) => {
  const [totalSliceBalance, setTotalSliceBalance] = useState(0);
  const [unclaimedSlice, setUnclaimedSlice] = useState(0);
  const [totalSlice, setTotalSlice] = useState(0);
  const [networksToggle, setNetworksToggle] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
  const [mobileNavMenu, setMobileNavMenu] = useState(false);
  const [networkSwitch, setNetworkSwitch] = useState('Mainnet');
  
  const updateMedia = () => {
    setDesktop(window.innerWidth > 992);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });


  const innerRef = useOuterClick(e => {
    setNetworksToggle(false);
  });
  const innerRefMenu = useOuterClick(e => {
    setMobileNavMenu(false);
  });

  useEffect(() => {
    setTotalSliceBalance(fromWei(tokenBalance[SLICEAddress]));
  }, [tokenBalance]);
  useEffect(() => {
    setUnclaimedSlice(fromWei(toBN(toBigNumber(+unclaimedSIRRewards || 0))));
  }, [ unclaimedSIRRewards ]);
  useEffect(() => {
    setTotalSlice(roundNumber(+totalSliceBalance + +unclaimedSlice));
  }, [totalSliceBalance, unclaimedSlice]);

  const { pathname } = useLocation();
  let parsedPath = pathname.split('/');
  const [path, setPath] = useState(parsedPath[parsedPath.length - 1] || 'borrow');

  const onboard = initOnboard({
    address: setAddress,
    network: setNetwork,
    balance: setBalance,
    wallet: setWalletAndWeb3
  });

  onboard.config({ networkId: network });

  const openModal = () => {
    setTxModalOpen(true);
    setTxModalType('trancheRewards');
  };

  useEffect(() => {
    const previouslySelectedWallet = window.localStorage.getItem('selectedWallet');

    if (previouslySelectedWallet && onboard) {
      onboard.walletSelect(previouslySelectedWallet);
    }
  }, [onboard]);

  const parsePath = useCallback(() => {
    setPath(parsedPath[parsedPath.length - 1]);
  }, [parsedPath]);
  useEffect(() => {
    parsePath();
  }, [pathname, parsePath]);

  const handleConnect = async () => {
    await onboard.walletSelect();
    await onboard.walletCheck();
  };

  const setNetworkSwitchToggle = (networkValue) => {
    if(networkValue === "ethereum") networkId === 1 ? switchNetwork('mainnet') : switchNetwork('kovan');
    else switchNetwork(networkValue);
  }

  return (
    <NavBarRightWrapper>
      {
        !isDesktop &&
          (balance < 0 ? (
            <WalletBtn wallet background='#4441CF' mobileColor="transparent" onClick={handleConnect} onKeyUp={handleConnect} color={ModeThemes[theme].TableColor} theme={theme}> 
              <WalletBtnIcon>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.75 9H13.0643C12.4763 9 12 9.47625 12 10.0642V10.9358C12 11.5238 12.4763 12 13.0643 12H15.75" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.25 15.75H3.75C2.92125 15.75 2.25 15.0788 2.25 14.25V6.75C2.25 5.92125 2.92125 5.25 3.75 5.25H14.25C15.0788 5.25 15.75 5.92125 15.75 6.75V14.25C15.75 15.0788 15.0788 15.75 14.25 15.75Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5 5.24999L8.03025 2.78024C7.737 2.48699 7.26225 2.48699 6.96975 2.78024L4.5 5.24999" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 3.74999L9.96975 2.78024C10.263 2.48699 10.7377 2.48699 11.0302 2.78024L13.5 5.24999" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </WalletBtnIcon>
              <WalletBtnText wallet icon={false} color={PagesData[path] ? PagesData[path].color : PagesData['stake'].color}>
                <h2>{i18n.t('connect')}</h2>
              </WalletBtnText>
            </WalletBtn>
          ) : (
            <WalletBtn wallet background='#4441CF' mobileColor="transparent" onClick={handleConnect} onKeyUp={handleConnect} color={ModeThemes[theme].TableColor} theme={theme}>
              <WalletBtnIcon>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.75 9H13.0643C12.4763 9 12 9.47625 12 10.0642V10.9358C12 11.5238 12.4763 12 13.0643 12H15.75" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.25 15.75H3.75C2.92125 15.75 2.25 15.0788 2.25 14.25V6.75C2.25 5.92125 2.92125 5.25 3.75 5.25H14.25C15.0788 5.25 15.75 5.92125 15.75 6.75V14.25C15.75 15.0788 15.0788 15.75 14.25 15.75Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5 5.24999L8.03025 2.78024C7.737 2.48699 7.26225 2.48699 6.96975 2.78024L4.5 5.24999" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 3.74999L9.96975 2.78024C10.263 2.48699 10.7377 2.48699 11.0302 2.78024L13.5 5.24999" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </WalletBtnIcon>
              <WalletBtnText wallet color={PagesData[path] ? PagesData[path].color : PagesData['stake'].color}>
                <h2>{addrShortener(address)}</h2>
              </WalletBtnText>
            </WalletBtn>
          ))
      }

      {
        path === "tranche" && address &&
        <NetworkSwitchWrapper>
          <WalletBtn
            background={ModeThemes[theme].MenuDropdownBackground}
            shadow={ModeThemes[theme].ModalTrancheNavbarBtnShadow}
            border={ModeThemes[theme].ModalTrancheNavbarBtnBorder}
            tranche
            network
            onClick={() => setNetworksToggle(!networksToggle)}
            ref={innerRef}
            color={ModeThemes[theme].TableColor}
            theme={theme}
          >
            <WalletBtnText tranche network icon={false} color={ModeThemes[theme].titleColor} notAvailable={!networks[network]}>
              <h2>{networks[network] ? networks[network].network : "..."}</h2>
              <img src={networks[network] ? networks[network].img : networks[networkId].img} alt="icon"/>
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.69251 1.16759L9.6923 1.16783L6.02026 5.04709L2.32086 1.16627L2.32091 1.16623L2.31831 1.16367L2.23765 1.24559C2.31831 1.16367 2.31828 1.16364 2.31824 1.1636L2.31816 1.16352L2.31796 1.16333L2.31745 1.16283L2.31592 1.16135L2.3109 1.15662C2.3067 1.1527 2.3008 1.14732 2.29331 1.14075C2.27835 1.12765 2.25692 1.10974 2.22993 1.08947C2.17629 1.04917 2.09896 0.998195 2.00536 0.957266C1.81766 0.87519 1.55123 0.829311 1.28906 1.00969C1.03753 1.18275 0.933858 1.43884 0.899597 1.65179C0.882431 1.75848 0.882055 1.85743 0.89016 1.9354C0.8942 1.97428 0.900545 2.00985 0.908844 2.03974C0.912981 2.05463 0.918061 2.06978 0.924402 2.08399C0.930024 2.09658 0.939832 2.11583 0.956271 2.13314L5.35749 6.76685L5.36166 6.77211C5.36686 6.77854 5.37424 6.78739 5.38368 6.79808C5.40253 6.81942 5.42986 6.84833 5.46486 6.88004C5.53419 6.94284 5.63717 7.0198 5.76715 7.06816C5.89881 7.11714 6.05811 7.13655 6.23361 7.08245C6.40722 7.02893 6.58617 6.90675 6.76535 6.69163L10.8544 2.31985L10.8544 2.3198C11.049 2.11153 11.1546 1.81453 11.1012 1.51985C11.0652 1.32059 10.9553 1.11187 10.6939 0.998883L10.6939 0.99887C10.3894 0.867349 10.1385 0.90537 9.96283 0.981248C9.87646 1.01856 9.80975 1.06431 9.76431 1.10102C9.74152 1.11942 9.72385 1.13575 9.71148 1.14791C9.70529 1.154 9.7004 1.15907 9.69685 1.16287L9.69251 1.16759Z" fill={ModeThemes[theme].titleColor} stroke={ModeThemes[theme].titleColor} stroke-width="0.229927"/>
              </svg>
            </WalletBtnText>
          </WalletBtn>
          {
            networksToggle &&
            <NavNetworksList background={ModeThemes[theme].MenuDropdownBackground} color={ModeThemes[theme].TableColor}>
              <NavNetwork margin onClick={() => setNetworkSwitchToggle('ethereum')} color={ModeThemes[theme].titleColor}>
                <img src={ETHnetwork} alt="icon"/>
                <h2>Ethereum</h2>
              </NavNetwork>
              <NavNetwork margin onClick={() => setNetworkSwitchToggle('polygon')} color={ModeThemes[theme].titleColor}>
                <img src={PolygonLogo} alt="icon"/>
                <h2>Polygon</h2>
              </NavNetwork>
              <NavNetwork margin onClick={() => setNetworkSwitchToggle('fantom')} color={ModeThemes[theme].titleColor}>
                <img src={FANTOMLOGOLIGHT} alt="icon"/>
                <h2>Fantom</h2>
              </NavNetwork>
              <NavNetwork onClick={() => setNetworkSwitchToggle('avalanche')} color={ModeThemes[theme].titleColor}>
                <img src={AVAX} alt="icon"/>
                <h2>Avalanche</h2>
              </NavNetwork>
              {  !networks[network] &&
                <NetworkWarning color={ModeThemes[theme].titleColor} fontSize="12px" fontWeight="normal" margin="5px 16px 12px 16px" padding="12px">
                <h2>
                  You’re currently connected to a different network, switch to the Mainnet network to deposit into this market.
                </h2>
              </NetworkWarning>
              }
            </NavNetworksList>
          }
        </NetworkSwitchWrapper>
      }

      {
        isDesktop &&
          (balance < 0 ? (
            <WalletBtn wallet background='#4441CF' mobileColor="transparent" onClick={handleConnect} onKeyUp={handleConnect} color={ModeThemes[theme].TableColor}> 
              <WalletBtnIcon tranche>
                <img src={WalletIcon} alt='wallet' />
              </WalletBtnIcon>
              <WalletBtnText wallet icon={false} color={PagesData[path] ? PagesData[path].color : PagesData['stake'].color}>
                <h2>{i18n.t('connect')}</h2>
              </WalletBtnText>
            </WalletBtn>
          ) : (
            <WalletBtn wallet background='#4441CF' mobileColor="transparent" onClick={handleConnect} onKeyUp={handleConnect} color={ModeThemes[theme].TableColor}>
              <WalletBtnIcon tranche>
                <img src={WalletIcon} alt='' />
              </WalletBtnIcon>
              <WalletBtnText wallet color={PagesData[path] ? PagesData[path].color : PagesData['stake'].color}>
                <h2>{addrShortener(address)}</h2>
              </WalletBtnText>
            </WalletBtn>
          ))
      }



      {
        <NavbarMobileWrapper ref={innerRefMenu}>
          <NavbarIconWrapper onClick={() => setMobileNavMenu(!mobileNavMenu)} color={ModeThemes[theme].TableColor} textColor={ModeThemes[theme].titleColor} theme={theme}>
            <span></span>
            <span></span>
            <span></span>
          </NavbarIconWrapper>
          {
            mobileNavMenu &&
            !isDesktop &&  <NavbarMobileMenu color={ModeThemes[theme].MenuDropdownBackground}>
              <NavbarMobileMenuNavLinks textColor={ModeThemes[theme].titleColor}>
                  <NavLink
                    to={baseUrl + '/tranche'}
                    exact
                  >
                  
                  <h2>Tranche</h2>
                  <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_54_1904)">
                    <path d="M9.99999 15.6268C9.99999 15.8367 8.69648 16 8.21086 16C5.06709 16 1.99999 14.3907 1.99999 11.0554V11H6.09201C6.14822 12.3049 7.12451 12.5714 8.38977 12.5714C8.90095 12.5714 9.79552 12.5481 9.99999 12.4548V15.6268Z" fill={ModeThemes[theme].titleColor}/>
                    <path d="M9.33546 5.5H6.08945L6.08933 6H1.99988L1.99999 0.233236L6.08945 0V2.93878H9.59105L9.33546 5.5Z" fill={ModeThemes[theme].titleColor}/>
                    <path d="M1.99999 7.4087H6.06266V9.52547H1.99999V7.4087Z" fill={ModeThemes[theme].titleColor}/>
                    </g>
                    <defs>
                    <filter id="filter0_d_54_1904" x="0.916254" y="0" width="10.1674" height="18.1672" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1.08362"/>
                    <feGaussianBlur stdDeviation="0.541812"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.451087 0 0 0 0 0.451087 0 0 0 0 0.451087 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_54_1904"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_54_1904" result="shape"/>
                    </filter>
                    </defs>
                  </svg>
                </NavLink>
                <NavLink
                  to={baseUrl + '/stake'}
                  exact
                >
                  <h2>Staking</h2>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 13L7 10L10 7L13 10L10 13Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17.6001 17.6006C17.7704 17.4504 17.9067 17.2657 18.0001 17.0588C18.0935 16.8519 18.1418 16.6276 18.1418 16.4006C18.1418 16.1736 18.0935 15.9491 18.0001 15.7422C17.9067 15.5353 17.7704 15.3506 17.6001 15.2004C17.2701 14.9053 16.8429 14.7422 16.4002 14.7422C15.9574 14.7422 15.5302 14.9053 15.2002 15.2004C15.027 15.3543 14.8871 15.542 14.7891 15.752C14.6911 15.9619 14.6369 16.1896 14.6301 16.4211C14.6233 16.6527 14.6639 16.8833 14.7494 17.0986C14.8349 17.3139 14.9635 17.5095 15.1273 17.6733C15.2911 17.8372 15.4867 17.9658 15.702 18.0513C15.9173 18.1368 16.148 18.1772 16.3795 18.1704C16.6111 18.1636 16.8388 18.1097 17.0487 18.0117C17.2586 17.9137 17.4462 17.7738 17.6001 17.6006Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.0999 2.6001C13.5983 1.56827 11.8217 1.01079 9.99988 1L8.3999 1.19995" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.4001 1.90039C15.9571 1.92439 15.5385 2.11105 15.2247 2.4248C14.911 2.73856 14.7242 3.15727 14.7002 3.60034C14.7002 4.05121 14.8793 4.48368 15.1981 4.80249C15.5169 5.1213 15.9493 5.30029 16.4001 5.30029C16.851 5.30029 17.2834 5.1213 17.6022 4.80249C17.921 4.48368 18.1001 4.05121 18.1001 3.60034C18.0761 3.15727 17.8893 2.73856 17.5756 2.4248C17.2618 2.11105 16.8432 1.92439 16.4001 1.90039" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.59998 4.90039C1.56814 6.4019 1.01079 8.17841 1 10.0002C1.01121 10.5391 1.07831 11.0753 1.20007 11.6003" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1.8999 3.60034C1.8999 4.05121 2.07902 4.48368 2.39783 4.80249C2.71664 5.1213 3.14899 5.30029 3.59985 5.30029C3.82691 5.31499 4.05454 5.28116 4.26746 5.20093C4.48037 5.12069 4.67371 4.99585 4.83459 4.83496C4.99548 4.67407 5.1202 4.48074 5.20044 4.26782C5.28067 4.05491 5.31462 3.8274 5.29993 3.60034C5.29993 3.37709 5.25597 3.15596 5.17053 2.94971C5.0851 2.74345 4.95986 2.55605 4.802 2.39819C4.64414 2.24033 4.45674 2.11522 4.25049 2.02979C4.04423 1.94435 3.8231 1.90039 3.59985 1.90039C3.15678 1.92439 2.7382 2.11105 2.42444 2.4248C2.11068 2.73856 1.9239 3.15727 1.8999 3.60034" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17.3999 15.1003C18.7087 13.1283 19.2429 10.7423 18.8999 8.40039" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.1002 16.4004C18.1002 16.0641 18.0005 15.7353 17.8137 15.4558C17.6269 15.1762 17.3614 14.9582 17.0507 14.8296C16.7401 14.7009 16.3983 14.6673 16.0685 14.7329C15.7388 14.7985 15.4359 14.9605 15.1982 15.1982C14.9604 15.436 14.7984 15.7388 14.7328 16.0686C14.6673 16.3983 14.701 16.7401 14.8296 17.0507C14.9583 17.3614 15.1762 17.6269 15.4557 17.8137C15.7353 18.0005 16.064 18.1003 16.4002 18.1003C16.8433 18.0763 17.2619 17.8894 17.5756 17.5756C17.8894 17.2619 18.0762 16.8434 18.1002 16.4004Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 17.4004C6.46506 18.43 8.20936 18.988 10 19.0002H11.6" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.60017 18.1003C4.05104 18.1003 4.48351 17.9211 4.80232 17.6023C5.12113 17.2835 5.30024 16.8513 5.30024 16.4004C5.30024 15.9495 5.12113 15.5171 4.80232 15.1982C4.48351 14.8794 4.05104 14.7002 3.60017 14.7002C3.38137 14.6998 3.16488 14.7442 2.96394 14.8308C2.763 14.9174 2.58192 15.0443 2.43196 15.2036C2.282 15.3629 2.16637 15.5515 2.09211 15.7573C2.01785 15.9631 1.98652 16.182 2.00019 16.4004C1.99944 16.8345 2.16474 17.2523 2.46223 17.5684C2.75972 17.8844 3.16686 18.0748 3.60017 18.1003Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </NavLink>
                <a
                  href="https://snapshot.org/#/tranche.eth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>Vote</h2>
                  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 14V5.59961" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19 5.59961V14" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 8.7998V14" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 8.7998V14" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 8.7998V14" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 1L2 6" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 1L20 6" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1.80005 17H20.1" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 20H21" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
              </NavbarMobileMenuNavLinks>
              <NavbarMobileMenuLinks textColor={ModeThemes[theme].titleColor}>
                <a href="/"><h2>Bridge</h2>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1.69995C11.9388 1.25587 12.9616 1.01728 14 1C15.8565 1 17.637 1.73754 18.9498 3.05029C20.2625 4.36305 21 6.14348 21 8C21 9.85652 20.2625 11.637 18.9498 12.9497C17.637 14.2625 15.8565 15 14 15C12.9561 15.0317 11.922 14.7905 11 14.3" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.942 7.96009L7.97217 4.99023L4.97404 7.98836L7.9439 10.9582L10.942 7.96009Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
                <a href="/"><h2>Analytics</h2>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.6001 4.90039L14.5001 10.0002C14.3132 10.1835 14.0619 10.2861 13.8002 10.2861C13.5384 10.2861 13.287 10.1835 13.1001 10.0002L10.9001 7.90039C10.7132 7.71716 10.4619 7.6145 10.2002 7.6145C9.93844 7.6145 9.68705 7.71716 9.50012 7.90039L3.6001 13.8003" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.6 18.9004H0.5V1.2002" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </a>
                <a href="/"><h2>Docs</h2>
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 16.9993C8.9573 16.1277 7.68342 15.5785 6.33386 15.4187C4.98431 15.2589 3.61742 15.495 2.39999 16.0989C2.24909 16.1648 2.08424 16.1926 1.9201 16.1795C1.75597 16.1663 1.59762 16.1128 1.45911 16.0237C1.3206 15.9347 1.20619 15.8131 1.1261 15.6692C1.04601 15.5253 1.00271 15.3636 1 15.199V3.8992C1.02412 3.29689 1.274 2.7256 1.69998 2.2991C2.92341 1.3644 4.45086 0.91825 5.98505 1.04764C7.51923 1.17703 8.9504 1.87289 10 2.9993C11.02 1.85931 12.4369 1.15187 13.9611 1.02176C15.4852 0.891649 17.0015 1.3485 18.2 2.2991C18.4429 2.49045 18.6406 2.73297 18.7789 3.00955C18.9172 3.28614 18.9927 3.59006 19 3.8992V15.199C18.9812 15.3672 18.9238 15.5287 18.8324 15.6712C18.7409 15.8136 18.6179 15.9332 18.4727 16.0203C18.3276 16.1074 18.1643 16.16 17.9956 16.1736C17.8269 16.1873 17.6573 16.1615 17.5 16.0989C16.2977 15.5035 14.948 15.2716 13.6159 15.4314C12.2838 15.5913 11.0273 16.1364 10 16.9993V16.9993Z" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 17V3" stroke={ModeThemes[theme].titleColor} stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </a>
              </NavbarMobileMenuLinks>
            </NavbarMobileMenu>
          }
          
        </NavbarMobileWrapper>
      }
      
    </NavBarRightWrapper>
  );
};

ConnectWallet.propTypes = {
  setAddress: PropTypes.func.isRequired,
  setNetwork: PropTypes.func.isRequired,
  setBalance: PropTypes.func.isRequired,
  setWalletAndWeb3: PropTypes.func.isRequired,
  setTxModalOpen: PropTypes.func.isRequired,
  setTxModalType: PropTypes.func.isRequired,
  ethereum: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  ethereum: state.ethereum,
  theme: state.theme,
});

export default connect(mapStateToProps, {
  setAddress,
  setNetwork,
  setBalance,
  setWalletAndWeb3,
  setTxModalOpen,
  setTxModalType,
  selectMarket
})(ConnectWallet);