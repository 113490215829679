import styled from "styled-components";

const WalletBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  height: 52px;
  background: ${(props) => props.background} !important;
  border-radius: 8px;
  padding: 0 14px;
  border: none;
  cursor: pointer;
  ${({ icon }) =>
    !icon &&
    `
        justify-content: center;
    `}
  &:focus {
    outline: none;
  }
  @media (max-width: 992px) {
    margin: 5px 0;
    width: 129px;
    height: 33px !important;
  }
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
  ${({ tranche, ModalBoxShadow, shadow, border}) => tranche && `
    // max-width: 149px;
    height: 52px;
    margin-right: 20px;
    background: ${ModalBoxShadow} !important;
    box-shadow: ${shadow};
    border: ${border};
    h2{
      font-size: 9px;
    }
    @media (max-width: 992px) {
      margin: 5px 3px;
      width: auto;
      padding: 9px;
      height: 33px !important;
      h2{
        display: none;
      }
    }
  `}

  ${({ network, color, theme }) => network && `
    width: auto;
    @media (max-width: 992px) {
      h2{
        display: block;
      }
      width: 46px;
      height: 24px !important;
      border-radius: 4px !important;
      padding: 5px 9px;
      justify-content: space-between;
      background: ${color} !important;
      border: none;
      box-shadow: ${theme === 'light' ? "0px 11px 20px rgba(183, 183, 183, 0.2)" : ""};
      margin-left: 16px;
    }
  `}
  ${({ wallet, color, theme }) => wallet && `
    width: auto !important;
    @media (max-width: 992px) {
      width: 24px !important;
      height: 24px !important;
      background: ${color} !important;
      border-radius: 4px !important;
      border: none;
      outline: none;
      padding: 0;
      box-shadow: ${theme === 'light' ? "0px 11px 20px rgba(183, 183, 183, 0.2)" : ""};
    }
  `}


`;

const WalletBtnIcon = styled.div`
  display: flex;
  align-items: center;
  ${({ tranche }) => tranche && `
    img{
      width: 24px;
    }
  `}
  @media (max-width: 1200px) {
    img{
      width: 20px;
    }
  }
`;

const WalletBtnText = styled.div`
  & > h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;    
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 12px;
    @media (max-width: 1200px) {
    }
    ${({ tranche, color }) => tranche && `
      color: ${color};
    `}
    ${({ notAvailable }) => notAvailable && `
      height: 30px;
    `}
  }
  ${({ network, color }) => network && `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h2{
      font-family: "Inter", sans-serif;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: ${color};
    }
    svg{
      margin-left: 12px;
    }
    img{
      height: 20px;
      margin-right: 12px;
      display: none;
      @media (max-width: 1200px) {
        display: block;
      }
      @media (max-width: 992px) {
        height: 14px;
        margin-right: 0;
      }
    }
  `}
  ${({ wallet }) => wallet && `
    @media (max-width: 1200px) {
      display: none;
    }
  `}
  ${({ network }) => network && `
    @media (max-width: 1200px) {
      h2{
        display: none;
      }
      svg{
        margin-left: 0;
      }
    }
  `}
`;

const NavBarRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 28%;
  @media (max-width: 992px) {
    flex-direction: row;
    width: 100%;
  }
`;

const MarketsTabsWrapper = styled.div`
  & > div{
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: baseline;
    }
  }
  & > div > h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: ${props => props.color};
    @media (max-width: 767px){
      font-size: 20px;
    }
  }
`;

const MarketsTabs = styled.div`
  display: flex;
  margin: 30px 0 20px 0;
  @media (max-width: 992px) {
    margin: 15px 0px 15px 0;
    justify-content: space-between;
  }
`;

const MarketTab = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  cursor: pointer;
  transition: 300ms;
  outline: none;
  padding: 0 29px;
  position: relative;
  margin-right: 14px;
  border-radius: 8px;
  height: 51px;
  width: 235px;
  background: ${props => props.current ? props.backgroundActive : props.background};
  border: 1px solid ${props => props.border};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14.14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${props => props.color};
  img{
    margin-right:5px;
  }
  img:first-child{
    width: ${props => props.market === "aavePolygon" || props.market === "avalanche" || props.market === "benqi" ? "60px" : "100%"};
  }
  & > span{
    margin: 0 8px;

    height: 70%;
    background: ${props => props.span};
    display: block;
    margin: 0 16px;
    padding: 0.5px;
    @media (max-width: 992px) {
      height: 100%;
    }
  }
  ${({ theme }) => theme === "light" && `
      opacity: 0.5;
      :hover{
        opacity: 0.8;
      }
  `} 
  ${({ mobile }) => mobile && `
      width: 90%;
      padding: 13px !important;
      img:first-child{
        width: auto;
        height: 100%;
      }
      img:last-child{
        width: auto;
      }
      & > span{
        margin: 0 15px;
      }
  `} 
  ${({ current, theme, btnShadow }) => (current && theme === "light") && `
      box-shadow: ${btnShadow} !important;
      opacity: 1;
  `} 
  :hover{
    opacity: ${props => props.current ? "" : "0.7"};
  }
  @media (max-width: 992px) {
    padding: 12px;
    height: 45px;
    width: 19%;
    margin: 0;
    & > span{
      margin: 0 5px;
    }
    img{
      height: 14px;
      margin-right: 0;
    }
    h2{
      font-size: 12px;
    }
    // img:first-child{
    //   height: 24px;
    //   margin: 0;
    // }
  }
`;

const BridgeTokensWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4441CF;
  border: 2px solid #4441CF;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 26px;
  margin: 0 0 25px 0;
  z-index: 1000;
  p{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
    width: 75%;
  }
  button{
    background: #FFFFFF;
    padding: 12px 40px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;    
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    outline: none;
    color: #4441CF;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    width: 100%;
    padding: 15px;
    p{
      font-size: 12px;
      text-align: center;
      width: 100%;
    }
    button{
      margin: 12px 0 0 0;
      font-size: 10px;
    }
  }
`;

const HowToLink = styled.a`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 10.9635px;
  text-align: center;
  letter-spacing: 0.05em;
  border: ${props => props.border};
  box-shadow: ${props => props.shadow};
  text-transform: uppercase;
  color: ${props => props.color};
  background: ${props => props.background};
  border-radius: 110.967px;
  padding: 11px 22px;
  margin-left: 20px;
  transition: 200ms;
  :hover{
    color: ${props => props.color}; 
    opacity: 0.7;
  }
  @media (max-width: 992px){
    padding: 6px 20px;
    font-size: 8px;
  }
`;

const HeaderWrapper = styled.div`
  border-radius: 13px;
  padding: 32px 0 0 0;
  display: flex;
  justify-content: center;
  background: transparent;
  position: relative;
  flex-direction: column;
  @media (max-width: 767px){
    min-height: 100px;
    padding: 0 20px;
    z-index: -1;
  }
`;

const HeaderContent = styled.div`
  margin: 0 0px; //change
  @media (max-width: 992px) {
    margin: 39px 0px;
  }
  ${({ path }) =>
    (path === "privacy" || path === "terms") &&
    `
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 992px){
            transform: translate(-50%, 50%);
        }
    `}
    ${({ path }) =>
    (path !== "stake" && path !== "tranche") &&
    `
      @media (max-width: 767px){
        display: none;
      }
    `}
`;

const HeaderSubtitle = styled.div`
  & > h2 {
    margin-top: 9px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
   

    color: ${props => props.color};
    @media (max-width: 767px){
      font-size: 12px;
      margin-top: 0;
    }

    ${({ fontSize }) =>
      fontSize &&
      `
            font-size: 9px;
        `}
  }
`;

const NavbarLinks = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  border-radius: 8px;
  border: ${props => props.theme === "light" ? "1px solid " + props.color : "none"};
  background: ${props => props.theme === "light" ? "": "#221F46"};
  ${({ tabs }) =>
  tabs &&
  `
    width: auto;
  `}
  & > a {
    font-family: 'Inter', sans-serif;
    height: 52px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.navlinkTab};
    opacity: 0.5;
    padding: 17px 37px;
    border-radius: 8px;
    :hover{
      opacity: 1;
    }
  }
  @media (max-width: 992px) {
    margin: 12px 0;
    a{
      font-size: 12px;
      padding: 9px 25px;
    }
  }
`;

const NavbarWrapper = styled.div`
  height: 112px;
  position: relative;
  border-bottom: 2px solid rgba(233, 233, 252, 0.1);
  @media (max-width: 992px) {
    height: auto;
    padding: 15px 0;
    border-bottom: none;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

const NavBarMobile = styled.div`
  width: 100%;
  background: #F9F9FE;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition-property: height, opacity, padding;
  transition-duration: 300ms, 100ms, 300ms;
  transition-timing-function: ease;
  ${({ rates }) => rates && `
    left: -100px;
    transition: 300ms;
    background: #F1F1F1;
    z-index: 2000;
  `}
`;

const NavBarMobileContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  h2:nth-child(2){
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #686565;
    margin: 12px auto;
  }
  ${({ first }) => first && `
    div{
      margin: 25px auto;
      display: flex;
      flex-direction: column;
      a{
        font-style: normal;
        font-weight: normal;
        margin: 14px auto;
        font-size: 28px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #282828;
        opacity: 0.5;
      }
    }
  `}
  button{
    border: none;
    outline: none;
    position: absolute;
    left: 20px;
    top: 20px;
  }
`;

const MobileNavbarIconWrapper = styled.div`
  display: none;
  align-items: center;
  @media (max-width: 992px) {
    display: flex;
  }
`;

const RatesWrapper = styled.div`
  position: relative;
`;

const RatesBoxWrapper = styled.div`
  position: absolute;
  top: -88px;
  right: -220px;
  width: 298px;
  height: auto;
  background: #F9F9FE;
  border: 1px solid #efefef;
  box-sizing: border-box;
  @media (max-width: 992px) {
    left: -29px;
    transform: translateX(-50%);
  }
  ${({ mobile }) =>mobile && ` 
    left: 50% !important;
    top: 0;
    div{

    }
  `}
`;
const NetworkSwitchWrapper = styled.div`
  position: relative;
`;

const NavNetworksList = styled.div`
  position: absolute;
  width: 248px;
  background: ${props => props.background};
  border-radius: 12px;
  margin-top: 12px;
  z-index: 2;
  @media (max-width: 1200px) {
    right: 0;
  }
  @media (max-width: 992px) {
    right: 0;
    width: 200px;
    background: ${props => props.color};
  }
`;
const NavNetwork = styled.button`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  border: none;
  outline: none;
  padding: 24px 0;
  background: transparent;
  cursor: pointer;
  padding: 5px 0;
  box-sizing: content-box;
  h2{
    font-family: "Inter", sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: ${props => props.color};
    margin-left: 12px;
  }
  img{
    padding-left: 16px;
    width: 24px;
    height: 24px;
  }
  
`;

const NetworkWarning = styled.div`
  background: rgba(235, 91, 91, 0.15);
  border: 1px solid #EB5B5B;
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${props => props.padding};;
  margin: ${props => props.margin};
  h2{
    font-family: "Inter", sans-serif;
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    line-height: 16px;
    letter-spacing: 0.03m;
    color: ${props => props.color};
  }
  ${({ table }) => table && `
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 992px) {
      flex-direction: column;
      margin: 19px 15px 17px 15px;  
      padding: 16px;
      h2{
        font-size: 14px;
        line-height: 20px;
      }
    }
  `}
`;
const NetworkWarningBtn = styled.button`
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 191px;
  height: 38px;
  background: #4441CF;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;
  @media (max-width: 992px) {
    width: 100%;
    margin: 16px 0 0 0;
  }
`;

const MarketsDropdownWrapper = styled.div`
  position: relative;
  margin-left: 24px;
  @media (max-width: 992px) {
    margin-left: 0;
    margin: 16px 0;
    width: 100%;
  }
`;

const MarketsDropdownTab = styled.div`
  height: 54px;
  width: 194px;
  background: ${props => props.background};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 992px) {
    width: 100%;
    background: ${props => props.backgroundColorMobile};
  }
`;

const MarketsDropdownAllIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    height: 24px;
  }
  img:nth-child(1){
    z-index: 1;
  }
  img:nth-child(2){
    z-index: 0;
    margin-left: -8px;
  }
  img:nth-child(3){
    z-index: -1;
    margin-left: -8px;
  }
`

const MarketsDropdownTabText = styled.div`
  display: flex;
  align-items: center;
  h2{
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: ${props => props.color};
    margin-left: 12px;
  }
  img{
    height: 24px;
  }
`;
const MarketsDropdown = styled.div`
  position: absolute;
  z-index: 2;
  margin-top: 14px;
  border-radius: 8px;
  background: ${props => props.background};
  width: 194px;
  @media (max-width: 992px) {
    width: 100%;
    background: ${props => props.backgroundColorMobile};
  }
`;
const MarketDropdown = styled.button`
  height: 54px;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
`;
const NavbarMobileWrapper = styled.div`
  position: relative;
  margin-left: 16px;
`;
const NavbarIconWrapper = styled.button`
  width: 52px;
  height: 52px;
  background: ${props => props.color};
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${props => props.theme === 'light' ? "0px 11px 20px rgba(183, 183, 183, 0.2)" : ""};
  span{
    height: 4px;
    width: 4px;
    background: ${props => props.textColor};
    display: block;
    border-radius: 50%;
  }
  @media (max-width: 992px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    padding: 5px;
    span{
      height: 2px;
      width: 2px;
    }
  }
`;
const NavbarMobileMenu = styled.div`
  width: 169px;
  min-height: 272px;
  background: ${props => props.color};
  border-radius: 16px;
  position: absolute;
  z-index: 1;
  right: 0;
  margin-top: 16px;
`;
const NavbarMobileMenuNavLinks = styled.div`
  border-bottom: 1px solid ${props => props.textColor};
  a{
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2{
      font-family: "Inter", sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: ${props => props.textColor};
    }
  }
`
const NavbarMobileMenuLinks = styled.div`
  a{
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2{
      font-family: "Inter", sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: ${props => props.textColor};
    }
  }
`



export {
  WalletBtn,
  WalletBtnIcon,
  WalletBtnText, 
  NavBarRightWrapper,
  MarketsTabsWrapper,
  MarketsTabs,
  MarketTab,
  BridgeTokensWrapper,
  HowToLink,
  HeaderWrapper,
  HeaderContent,
  HeaderSubtitle,
  NavbarLinks,
  NavbarWrapper,
  NavbarContainer,
  NavBarMobile,
  NavBarMobileContent,
  MobileNavbarIconWrapper,
  RatesWrapper,
  RatesBoxWrapper,
  NetworkSwitchWrapper,
  NavNetworksList,
  NavNetwork,
  NetworkWarning,
  NetworkWarningBtn,
  MarketsDropdownWrapper,
  MarketsDropdownTab,
  MarketsDropdown,
  MarketsDropdownTabText,
  MarketsDropdownAllIcons,
  MarketDropdown,
  NavbarMobileWrapper,
  NavbarMobileMenu,
  NavbarIconWrapper,
  NavbarMobileMenuNavLinks,
  NavbarMobileMenuLinks
};
