import React from 'react';
import {
  TableMoreBtns,
  TableMoreBtn,
} from '../../../Stake/Table/styles/TableComponents';
import { MetaMaskIcon } from 'assets';
import { ModeThemes } from 'config';
let TableMoreRowBtns = ({
  WrapperMargin,
  FirstBtnMargin,
  SecondBtnMargin,
  ThirdBtnMargin,
  FirstBtnPadding,
  SecondBtnPadding,
  ThirdBtnPadding,
  wallet,
  theme,
  analyticsLink,
  explorerLink,
  addingTokenToWallet,
  BorderRadius,
  FirstBtnWidth,
  SecondBtnWidth,
  ThirdBtnWidth,
  isDesktop
}) => {
  return (
    <TableMoreBtns margin={WrapperMargin}>
      <TableMoreBtn as="a" href={analyticsLink} target='_blank' rel='noopener noreferrer' borderRadius={BorderRadius} width={FirstBtnWidth} btnColor="#4441CF" textColor="#FFFFFF" margin={FirstBtnMargin} padding={FirstBtnPadding} type="button">View Market Analytics</TableMoreBtn>
      { wallet &&
        <TableMoreBtn btnColor={ModeThemes[theme].TableMoreBtnColor} textColor={ModeThemes[theme].TableMoreBtnTextColor} borderRadius={BorderRadius} width={SecondBtnWidth} margin={SecondBtnMargin} padding={SecondBtnPadding}  type="button" onClick={addingTokenToWallet}><img src={MetaMaskIcon} alt="MetamaskIcon"/>
        {
          isDesktop && "Add to wallet"
        } 
        </TableMoreBtn>
      }
      <TableMoreBtn as="a" href={explorerLink} target='_blank' rel='noopener noreferrer' btnColor={ModeThemes[theme].TableMoreBtnColor} borderRadius={BorderRadius} width={ThirdBtnWidth} textColor={ModeThemes[theme].TableMoreBtnTextColor} margin={ThirdBtnMargin} padding={ThirdBtnPadding} type="button">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.59802 0V1.39981H12.1177L5.60849 7.90891L6.58837 8.88878L13.0976 2.37967V4.89933H14.4974V0H9.59802Z" fill={ModeThemes[theme].TableMoreBtnTextColor}/>
            <path d="M10.2989 12.6004H1.89984V4.20157H7.49918V2.80176H0.5V14.0002H11.6987V7.00119H10.2989V12.6004Z" fill={ModeThemes[theme].TableMoreBtnTextColor}/>
        </svg>
        {
          isDesktop && <h2>View in Explorer</h2>
        } 
        </TableMoreBtn>
    </TableMoreBtns>
  );
};

export default TableMoreRowBtns;
