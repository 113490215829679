import {
  TRANCHE_CARD_TOGGLE
} from './constants';

export const trancheCardToggle = (obj) => (dispatch) => {
  dispatch({
    type: TRANCHE_CARD_TOGGLE,
    payload: obj
  });
};











