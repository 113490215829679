import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setAddress,
  setNetwork,
  setBalance,
  setWalletAndWeb3,
  setTokenBalance,
  addNotification,
  setNotificationCount
} from 'redux/actions/ethereum';
import { checkServer } from 'redux/actions/checkServer';
import { addrShortener, roundNumber, readyToTransact, formatTime } from 'utils';
import { statuses, ModeThemes, LiquidityIcons, etherScanUrl } from 'config';
import { LinkArrow, TrancheStake } from 'assets';
import StakingModal from '../../Modals/StakingModal';
import {
  TableContentCard,
  TableContentCardWrapper,
  StatusTextWrapper,
  TableCardImg,
  TableContentCardWrapperMobile,
  TableContentCardMobile,
  StakeBtn,
  StakeBtns,
  TableMobileContent,
  TableMobileContentRow,
  TableMobileContentCol,
  StakeBtnSlice,
  TableColContent,
  TableCol,
  TableColText
} from './styles/TableComponents';
import { initOnboard } from 'services/blocknative';

const TableCard = ({
  staking: { contractAddress, staked, reward, type, poolName, apy, subscription, duration, durationIndex, remainingCap },
  setTokenBalance,
  ethereum: { address, wallet },
  summaryData: { slice, lpList },
  theme,
  isDesktop,
  title
  // checkServer
}) => {
  const [ModalIsOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [tokenAddress, setTokenAddress] = useState(null);

  const onboard = initOnboard({
    address: setAddress,
    network: setNetwork,
    balance: setBalance,
    wallet: setWalletAndWeb3
  });

  useEffect(() => {
    const setEpochTime = async () => {
      // const result = await epochTimeRemaining(StakingAddresses[StakingAddresses.length - 1]);
    };

    setEpochTime();
  }, [type]);

  useEffect(() => {
    if (type === 'SLICE') {
      setTokenAddress(slice.address);
    } else if (type === 'SLICE/ETH LP') {
      setTokenAddress(lpList && lpList[0].address);
    } else if (type === 'SLICE/DAI LP') {
      setTokenAddress(lpList && lpList[1].address);
    }
  }, [type, slice, lpList, contractAddress]);

  let moreCardToggle = false;

  const searchObj = (val) => {
    return Object.fromEntries(Object.entries(statuses).filter(([key, value]) => value.status === val));
  };

  const openModal = async (type) => {
    const ready = await readyToTransact(wallet, onboard);
    if (!ready) return;
    address = !address ? onboard.getState().address : address;
    setTokenBalance(tokenAddress, address);
    setModalType(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  const TableCardDesktop = () => {
    return (
      <TableContentCardWrapper
        color={ModeThemes[theme].TableCard}
        borderColor={ModeThemes[theme].TableCardBorderColor}
        shadow={ModeThemes[theme].tableCardShadow}
        cardShadow={ModeThemes[theme].cardShadow}
      >
        <TableContentCard
          pointer={true}
          className={moreCardToggle ? 'table-card-toggle' : ''}
          color={ModeThemes[theme].borderColor}
        >
          <TableCol className='table-col' width={title === "SLICE Staking Pools" ? "19%" : "20%"}>
            <TableColContent display="flex" alignItems="center">
              <TableCardImg tranche={true}>
                <img src={TrancheStake} alt='Tranche' />
                {title === 'Liquidity Provider Pools' && <img src={LiquidityIcons[type && type]} alt='Tranche' />}
              </TableCardImg>
              <TableColContent display="flex" flexDirection="column" margin="0 0 0 12px">
              {title === 'SLICE Staking Pools' && duration ?
                <TableColText color={ModeThemes[theme].tableText} fontSize="14px" fontWeight="600" letterSpacing="0.05em" textTransform="uppercase">
                   {poolName && poolName}</TableColText> : <TableColText color={ModeThemes[theme].tableText} fontSize="14px" fontWeight="600" letterSpacing="0.05em" textTransform="uppercase">{type && type}</TableColText>}
                <TableColText color="#838186" fontSize="9px" fontWeight="400" letterSpacing="0.12em" textTransform="uppercase" imgMargin="2px" imgHeight="12px" display="flex" alignItems="center">
                  {addrShortener(contractAddress)}
                  <a href={etherScanUrl + 'address/' + contractAddress} target='_blank' rel='noopener noreferrer'>
                    <img src={LinkArrow} alt='' />
                  </a>
                </TableColText>
              </TableColContent>
            </TableColContent>
          </TableCol>

          <TableCol className='table-col' width={title === 'SLICE Staking Pools' ? "10%" : "15%"}>
            <TableColContent width="90%" margin="0 auto">
              {title === 'SLICE Staking Pools' && duration && (
                <StatusTextWrapper
                  className='status-text-wrapper t-a-c'
                  color={ModeThemes[theme].activeStatusText}
                  backgroundColor={ModeThemes[theme].activeStatus}
                  table='stake'
                  docsLockupText={ModeThemes[theme].docsLockupText}
                  docsLockupBackground={ModeThemes[theme].docsLockupBackground}
                >
                  {/* {isActive ? i18n.t('stake.table.statuses.active') : ''} */}
                  {formatTime(duration)}
                </StatusTextWrapper>
              )}
              {title === 'SLICE Staking Pools' && !duration && (
                <StatusTextWrapper
                  className='status-text-wrapper t-a-c'
                  color={ModeThemes[theme].activeStatusText}
                  backgroundColor={ModeThemes[theme].activeStatus}
                  table='stake'
                  docsLockupText={ModeThemes[theme].docsLockupText}
                  docsLockupBackground={ModeThemes[theme].docsLockupBackground}
                >
                  CLOSED
                </StatusTextWrapper>
              )}
            </TableColContent>
          </TableCol>

          <TableCol className={'table-col table-fourth-col-return '} width="15%">
            <TableColContent className='content-3-col second-4-col-content'>
              <TableColText fontSize="17px" textAlign="center" color={ModeThemes[theme].tableText}>{roundNumber(staked)}</TableColText>
              <TableColText color="#CCCCCD" lineHeight="15px" fontWeight="bold" letterSpacing="0.05em" fontSize="12px">{''}</TableColText>
            </TableColContent>
          </TableCol>
          <TableCol width="16%" className={'table-col table-fifth-col-subscription'}>
            <TableColContent className='content-3-col second-4-col-content'>
              {title === 'SLICE Staking Pools' && duration && <TableColText fontSize="17px" textAlign="center" display="flex" flexDirection="column" color={ModeThemes[theme].tableText}>{roundNumber(remainingCap)} SLICE</TableColText>}
              {title === 'SLICE Staking Pools' && !duration && <TableColText fontSize="17px" textAlign="center" display="flex" flexDirection="column" color={ModeThemes[theme].tableText}>N/A</TableColText>}
              {title === 'Liquidity Provider Pools' && <TableColText fontSize="17px" textAlign="center" display="flex" flexDirection="column" color={ModeThemes[theme].tableText}>{roundNumber(reward)}</TableColText>}
              <h2>{''}</h2>
            </TableColContent>
          </TableCol>

          <TableCol className='table-col' width="10%">
            <TableColContent className='content-3-col second-4-col-content' display="flex" alignItems="center" justifyContent="center">
              <TableColText fontSize="17px" textAlign="center" display="flex" flexDirection="column" color={ModeThemes[theme].tableText}>{roundNumber(apy, 2)}%</TableColText>
              <TableColText fontSize="17px" textAlign="center" display="flex" flexDirection="column" color={ModeThemes[theme].tableText}>{''}</TableColText>
            </TableColContent>
          </TableCol>

          <TableCol
            onClick={(e) => e.stopPropagation()}
            className='table-sixth-col table-col'
            color={ModeThemes[theme].tableText}
            width="12%"
          >
            <TableColContent className='content-3-col second-4-col-content' display="flex" alignItems="center" justifyContent="center">
              <TableColText fontSize="17px" textAlign="center" display="flex" flexDirection="column" color={ModeThemes[theme].tableText}>{roundNumber(subscription)}</TableColText>
              <TableColText fontSize="17px" textAlign="center" display="flex" flexDirection="column" color={ModeThemes[theme].tableText}>{''}</TableColText>
            </TableColContent>
          </TableCol>

          {title === 'SLICE Staking Pools' && duration ? (
            <TableCol
              onClick={(e) => e.stopPropagation()}
              className='table-sixth-col table-col'
              width={title === 'SLICE Staking Pools' ? "15%" : "12%"}
            >
              <StakeBtnSlice onClick={() => openModal('staking')} disabled={remainingCap <= 0} disabledBtnColor={ModeThemes[theme].disabledBtnColor}>
                {remainingCap === 0 ? 'Capped' : 'Stake'}
              </StakeBtnSlice>
            </TableCol>
          ) : title === 'SLICE Staking Pools' && !duration ? (
            <TableCol
              onClick={(e) => e.stopPropagation()}
              className='table-sixth-col table-col'
              width={title === 'SLICE Staking Pools' ? "15%" : "12%"}
            >
              <StakeBtnSlice
                onClick={() => openModal('withdrawTokens')}
                withdraw
                disabled={true}
                disabledBtnColor={ModeThemes[theme].disabledBtnColor}
              >
                disabled
              </StakeBtnSlice>
            </TableCol>
          ) : (
            <TableCol onClick={(e) => e.stopPropagation()} className='table-sixth-col table-col' width={title === 'SLICE Staking Pools' ? "15%" : "12%"}>
              <StakeBtn background='#6E41CF' onClick={() => openModal('liqWithdraw')} disabled={false}>
                -
              </StakeBtn>
              <StakeBtn background='#4441CF' onClick={() => openModal('liqStake')} disabled={false}>
                +
              </StakeBtn>
            </TableCol>
          )}
        </TableContentCard>

        <StakingModal
          // State Values
          modalIsOpen={ModalIsOpen}
          type={type}
          modalType={modalType}
          contractAddress={contractAddress}
          tokenAddress={tokenAddress}
          title={title}
          reward={reward}
          remainingCap={remainingCap}
          apy={apy}
          duration={duration}
          durationIndex={durationIndex}
          // Functions
          closeModal={() => closeModal()}
        />
      </TableContentCardWrapper>
    );
  };
  const TableCardMobile = () => {
    return (
      <TableContentCardWrapperMobile stakeMobile tranche color={ModeThemes[theme].TableCard} borderColor={ModeThemes[theme].TableCardBorderColor}>
        <TableContentCardMobile color={Object.values(searchObj(1))[0].background} tranche stakeMobile>
          <TableMobileContent stakeMobile>
            <TableMobileContentRow>

              <TableColContent
                 display="flex" alignItems="center" justifyContent="space-between" width="100%"
              >
                <TableColContent display="flex" alignItems="center" margin="0" width="100%">
                  <TableCardImg
                    stakeMobile
                    tranche={true}
                    background={type === 'TRANCHE_A' ? '#68D2FF' : '#FF7A7F'}
                  >
                    <img src={TrancheStake} alt='Tranche' />
                    {title === 'Liquidity Provider Pools' && <img src={LiquidityIcons[type && type]} alt='Tranche' />}
                  </TableCardImg>
                  <TableColContent>
                    <TableColText color={ModeThemes[theme].tableText} fontSize="14px" fontWeight="600" letterSpacing="0.05em" textTransform="uppercase">
                      {title === 'SLICE Staking Pools' && duration ? poolName && poolName : type && type}
                    </TableColText>
                    <TableColText color="#838186" fontSize="9px" fontWeight="400" letterSpacing="0.12em" textTransform="uppercase" imgMargin="2px" imgHeight="12px" display="flex" alignItems="center">
                      {addrShortener(contractAddress)}
                    </TableColText>
                  </TableColContent>
                </TableColContent>

                  {title === 'SLICE Staking Pools' && duration ? (
                      <StakeBtns>
                        <StakeBtnSlice
                          onClick={() => openModal('staking')}
                          disabled={remainingCap === 0}
                          disabledBtnColor={ModeThemes[theme].disabledBtnColor}
                          stakeMobile
                        >
                          {remainingCap === 0 ? 'Capped' : 'Stake'}
                        </StakeBtnSlice>
                      </StakeBtns>
                    ) : title === 'SLICE Staking Pools' && !duration ? (
                      <StakeBtns>
                        <StakeBtnSlice
                          onClick={() => openModal('withdrawTokens')}
                          withdraw
                          disabled={true}
                          stakeMobile
                          disabledBtnColor={ModeThemes[theme].disabledBtnColor}
                        >
                          disabled
                        </StakeBtnSlice>
                      </StakeBtns>
                    ) : (
                      <StakeBtns>
                        <StakeBtn background='#6E41CF' onClick={() => openModal('liqWithdraw')} disabled={false}>
                          -
                        </StakeBtn>
                        <StakeBtn background='#4441CF' onClick={() => openModal('liqStake')} disabled={false}>
                          +
                        </StakeBtn>
                      </StakeBtns>
                    )}
              </TableColContent>
            </TableMobileContentRow>

            <TableMobileContentRow>
              <TableMobileContentCol color={ModeThemes[theme].tableText} stake stakeMobile width="30">
                <h2>APY</h2>
                <h2>{roundNumber(apy, false)}%</h2>
              </TableMobileContentCol>
              <TableMobileContentCol color={ModeThemes[theme].tableText} stake stakeMobile width="30">
                <h2>Your Stake</h2>
                <h2>{roundNumber(subscription)}</h2>
              </TableMobileContentCol>
              <TableMobileContentCol color={ModeThemes[theme].tableText} stake stakeMobile width="40">
                <h2>{title === 'SLICE Staking Pools' ? 'Remaining Capacity' : 'EPOCH Rewards'}</h2>
                {title === 'SLICE Staking Pools' && duration && <h2>{roundNumber(remainingCap)}</h2>}
                {title === 'SLICE Staking Pools' && !duration && <h2>N/A</h2>}
                {title === 'Liquidity Provider Pools' && <h2>{roundNumber(reward)}</h2>}
              </TableMobileContentCol>
            </TableMobileContentRow>
          </TableMobileContent>
        </TableContentCardMobile>
        <StakingModal
          modalIsOpen={ModalIsOpen}
          type={type}
          modalType={modalType}
          contractAddress={contractAddress}
          tokenAddress={tokenAddress}
          title={title}
          reward={reward}
          remainingCap={remainingCap}
          apy={apy}
          duration={duration}
          durationIndex={durationIndex}
          // Functions
          closeModal={() => closeModal()}
        />
      </TableContentCardWrapperMobile>
    );
  };

  return isDesktop ? TableCardDesktop() : TableCardMobile();
};

TableCard.propTypes = {
  ethereum: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
  setNetwork: PropTypes.func.isRequired,
  setBalance: PropTypes.func.isRequired,
  setWalletAndWeb3: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  ethereum: state.ethereum,
  form: state.form,
  trade: state.trade,
  theme: state.theme,
  summaryData: state.summaryData
});

export default connect(mapStateToProps, {
  setAddress,
  setNetwork,
  setBalance,
  setWalletAndWeb3,
  setTokenBalance,
  addNotification,
  setNotificationCount,
  checkServer
})(TableCard);
